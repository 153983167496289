import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SaveParams, useWidth } from 'utils';
import { Svg } from 'assets';

export const MenuControlPanel = ({ menu }) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const width = useWidth();
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        setHeaderHeight(document.querySelector('.topbar-container')?.offsetHeight);
    }, []);

    const handleClickMenuItem = (e,item) => {
        e.stopPropagation()
        SaveParams(history, {...info, menu: item?.key });
    };

    const handleClickSubMenuItem = (e, item) => {
        e.stopPropagation();
        SaveParams(history, {...info, subMenu: item?.key });
    };


    return (
        <div
            className='menu-control-panel-wrapper'
            style={{ top: headerHeight }}
        >
            <div className='menu-control-panel-box'>
                {/*{width > 767 &&*/}
                {/*  <UserInput*/}
                {/*    inputType="text"*/}
                {/*    inputName="search"*/}
                {/*    inputPlaceholder="Search here"*/}
                {/*    inputBoxClassName="search-input-box"*/}
                {/*    inputClassName="search-input-wrapper"*/}
                {/*    inputIcon={<Icons.SearchIcon />}*/}
                {/*  />*/}
                {/*}*/}

                {width > 767 &&
                    <div className='menu-help-center-header'
                         onClick={() => history.push('/help-center')}
                    >
                        <div className='icon-help-text'>
                            <Svg.BookHelpSvg className='menu-help-center-header-icon' />
                            <p className='text-help'>
                                Help
                            </p>
                        </div>
                        <p className='text-description'>
                            Articles for Control Panel
                        </p>
                    </div>
                }

                <div className='menu-items-wrapper'>
                    {menu?.map((item, index) => (
                        <div
                            className='menu-items-box'
                            key={index}
                        >
                            <div
                                className={`menu-items ${(info?.menu === item?.key) ? 'active-menu-item' : ''}`}
                                onClick={(e) => {
                                    handleClickMenuItem(e,item);
                                }}
                            >

                                <p className={`menu-item_text `}>
                                    {item.title}
                                </p>

                                <Svg.ArrowRight2Svg
                                    className={`menu-item_svg ${!item?.subMenu?.length || !info?.menu ? 'menu-item_svg_noRotate' : ''}`}
                                />
                            </div>

                            {(info?.menu === item?.key && !!item?.subMenu?.length) &&
                                <div className='menu-sub-items-wrapper'>
                                    {item?.subMenu?.map((item, index) => (
                                        <p
                                            className={`menu-sub-items-text ${info?.subMenu === item?.key ? 'active-sub-menu-item' : ''}`}
                                            key={index}
                                            onClick={(e) => handleClickSubMenuItem(e, item)}
                                        >
                                            {item?.title}
                                        </p>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

