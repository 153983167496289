import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomDelByNote,
    CustomDelete,
    CustomizedSwitch, Loader,
    ModalContentWrapper, NoItemsYet,
    Notes, SimpleModal,
    SimpleTabs, SimpleTooltip,
    ToolInfo,
} from 'components';
import { billStatuses, getDisplayFromFullType, SERVICE } from '../../../constants';
import { Details } from './details';
import { APPT_ENUMS, FindLoad, Images, PermissionList, RolePermission, useModal } from 'utils';
import { apptDetailList, apptDetailTabsLabels, serviceApptDetailList } from './constants';
import { ScheduleLinks } from './scheduleLinks';
import { MoteDrawerModal } from './apptNotes';
import { appointmentActions, clientActions, noteActions, systemActions } from 'store';
import { apptEnums } from '../../constants';
import { HistoryTab } from '../../../../history';
import { SignatureUploader } from '../../common/signatureUploader';
import { scheduleCommonStyle } from '../../common/styles';
import { ApptActions } from './apptActions';
import { ApptFiles } from './apptFiles';

const CANCEL_ACTION_TYPE = 'SET_APPOINTMENT_STATUS_CANCEL';
const SET_APPOINTMENT_STATUS = 'SET_APPOINTMENT_STATUS';
const RENDER_COMPLETE_ACTION_TYPE = 'SET_APPOINTMENT_STATUS_COMPLETE';
const REMOVE_APPT_FROM_BILL = 'REMOVE_APPT_FROM_BILL';
const ACTION_TYPE = 'GET_APPOINTMENT_BY_ID';
const UN_RENDER_ACTION = 'UN_RENDER_APPT';
export const ScheduleDetails = ({ currentId, getAfterSuccess }) => {
    const { item } = useSelector((state) => ({
        item: state.appointment.appointmentById,
    }));
    const apptType = getDisplayFromFullType(item?.type);
    const _isServiceAppmt = item?.type === SERVICE;
    const user = useSelector((state) => state.auth.userInfo);
    const notes = useSelector((state) => state.note.notes);
    const loadNotes = FindLoad('GET_GLOBAL_NOTES');
    const userInfo = user || JSON.parse(localStorage.getItem('wellUserInfo'));
    const checkCancel = item?.staff?.id === userInfo?.id ? true : RolePermission([PermissionList.APPT_CANCEL?.code]);
    const checkRenderComplete = item?.staff?.id === userInfo?.id ? true : RolePermission([PermissionList.APPT_RENDER_COMPLETE?.code]);
    const dispatch = useDispatch();
    const [modalIsOpen, setModalIsOpen] = React.useState(null);
    const classes = scheduleCommonStyle();
    const [activeTab, setActiveTab] = useState('Details');
    const { open } = useModal();
    const loader = FindLoad(ACTION_TYPE);
    const detailList = _isServiceAppmt ? serviceApptDetailList(item) : apptDetailList(item)

    const afterSuccess = () => {
        if (getAfterSuccess) {
            getAfterSuccess();
        }
    };

    useEffect(() => {
        dispatch(appointmentActions.getAppointmentById(currentId));
        dispatch(systemActions.getPlaces());
        dispatch(clientActions.getClients());
        getNotes();
    }, []);

    const getNotes = (loading) => {
        const load = loading === 'noLoad' ? 'noLoad' : 'load';
        dispatch(noteActions.getGlobalNotes(currentId, 'Appt', {}, load));
    };


    const apptTabContent = [
        {
            tabComponent: <Details item={item} />,
            tab: 'Details',
        },
        {
            tabComponent: <ScheduleLinks item={item} />,
            tab: 'Links',
        },
        {
            tabComponent: <MoteDrawerModal item={item} getNotes={getNotes} />,
            tab: 'Notes',
        },
        RolePermission([PermissionList.APPT_FILE_READ?.code, PermissionList.APPT_FILE_MANAGE?.code]) &&
        {
            tabComponent: <ApptFiles item={item} />,
            tab: 'Files',
        },
        RolePermission([PermissionList.APPT_HISTORY_READ?.code]) &&
        {
            tabComponent: <HistoryTab notYet={'notShow'} currentId={item?.id} onModel={'Appt'} margin={'0'}
                                      height={'auto'} />,
            tab: 'History',
        },
    ];


    const unRenderAppt = () => {
        open(
            <CustomDelete
                info={'Un-render Appointment'}
                text={'Are you sure you want to un-render this appointment? Billing  or processed timesheets will not be affected. Active timesheets will be deleted.'}
                innerText={'Un-Render'}
                color={'blue'}
                handleDel={() => dispatch(appointmentActions.unRenderAppt(item?.id))}
                handleClose={() => close()}
                actionType={UN_RENDER_ACTION}
                afterSuccess={afterSuccess}
            />,
        );
    };

    const removeFromBill = () => {
        open(
            <CustomDelByNote
                info="Remove Appointment from Claim"
                text="Are you sure you want to remove this appointment from the claim?"
                innerText={'Remove'}
                inputLabel={'Reason for removing*'}
                handleDel={(comment) => dispatch(appointmentActions.removeApptFromBill(item?.id, comment))}
                actionType={REMOVE_APPT_FROM_BILL}
                successType={REMOVE_APPT_FROM_BILL}
                afterSuccess={afterSuccess}
            />,
        );
    };

    function handleStatusChange(type, eventType) {
        if (eventType === SERVICE) {
            if (item?.requireSignature && !item?.signature?._id) {
                setModalIsOpen(true);
            } else if (item?.requireNote && !notes?.length) {
                setActiveTab('Notes');
            } else {
                open(
                    <CustomDelete
                        text={`Are you sure you want to render this appointment?`}
                        info={`Render This Appointment?`}
                        innerText={`Render Appointment`}
                        handleDel={() => dispatch(appointmentActions.setAppointmentStatus(item?.id, type, ''))}
                        handleClose={() => close()}
                        actionType={RENDER_COMPLETE_ACTION_TYPE}
                        color={'blue'}
                        afterSuccess={afterSuccess}
                    />,
                );
            }
        } else {
            open(
                <CustomDelete
                    text={`Are you sure you want to complete this appointment?`}
                    info={`Complete This Appointment?`}
                    innerText={`Complete Appointment`}
                    handleDel={() => dispatch(appointmentActions.setAppointmentStatus(item?.id, type, ''))}
                    handleClose={() => close()}
                    actionType={RENDER_COMPLETE_ACTION_TYPE}
                    color={'blue'}
                    afterSuccess={afterSuccess}
                />,
            );
        }
    }

    if (loader?.length) {
        return <div className="appt-detail-modal"><Loader /></div>;
    }

    return (
        <div className="appt-detail-modal">
            {item?.id ?
                <>
                    <div>
                        <div>
                            <div className="flex-align-center" style={{ gap: 8 }}>
                                <p className="appt-detail-title">{apptType}</p>

                                <div className="flex-align-center" style={{ gap: 8 }}>
                                    {item?.incompleteUnitFlag &&
                                        <div style={{ width: 20 }}>
                                            <SimpleTooltip
                                                title={
                                                    <p>{'This appointment has incomplete units.'}</p>}
                                                placement="bottom"
                                                arrow
                                            >
                                                <img src={Images.flag} alt="flag" />
                                            </SimpleTooltip>
                                        </div>
                                    }

                                    {item?.removedFromClaimFlag &&
                                        <div style={{ width: 20 }}>
                                            <SimpleTooltip
                                                title={<p>{'Appointment was removed from claim'}</p>}
                                                placement="bottom"
                                                arrow
                                            >
                                                <img src={Images.blueFlag} alt="flag" />
                                            </SimpleTooltip>
                                        </div>
                                    }
                                </div>
                            </div>

                            {item?.id &&
                                <ApptActions
                                    item={item}
                                    setModalIsOpen={() => setModalIsOpen(true)}
                                    setActiveTab={setActiveTab}
                                    afterSuccess={afterSuccess}
                                />
                            }

                            <div className="space-between-flex-start" style={{ margin: '0 0 24px 0' }}>
                                <div>
                                    {detailList?.map((i, j) => i?.value && (
                                        <div key={j} className="appt-detail-box">
                                            <p className="appt-detail-modal-title">{i?.title}</p>
                                            <p className="appt-detail-modal-value">{i?.value}</p>
                                        </div>
                                    ))}
                                </div>

                                {item?.type === APPT_ENUMS.SERVICE &&
                                    <div className="inature-drawwer-box">
                                        {RolePermission([PermissionList.APPT_SIGNATURE_TOGGLE_MANAGE?.code]) &&
                                            <div
                                                className="flex-align-center"
                                                style={{ gap: 8, opacity: '0.7' }}
                                            >
                                                {item?.requireSignature &&
                                                    <ToolInfo
                                                        text={'Require a signature to render this appointment.'} />
                                                }
                                                <p>Require Signature</p>
                                                <CustomizedSwitch
                                                    disabled={true}
                                                    checked={item?.requireSignature}
                                                />
                                            </div>
                                        }
                                        {RolePermission([PermissionList.APPT_NOTE_TOGGLE_MANAGE?.code]) &&
                                            <div
                                                className="flex-align-center"
                                                style={{ marginBottom: 0, gap: 8, opacity: '0.7' }}
                                            >
                                                {item?.requireNote &&
                                                    <ToolInfo text={'Require notes to render this appointment.'} />
                                                }
                                                <p>Require Notes</p>
                                                <CustomizedSwitch
                                                    disabled={true}
                                                    checked={item?.requireNote}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <SimpleTabs
                            getTab={(e) => setActiveTab(e)}
                            tab={activeTab}
                            defaultTab={'Details'}
                            tabsLabels={apptDetailTabsLabels}
                            tabsContent={apptTabContent}
                            small={true}
                        />
                    </div>

                    <div className="flex-end">
                        {item?.status === apptEnums.PENDING ? (
                                <div className="flex-align-center" style={{ gap: 24 }}>
                                    {checkCancel &&
                                        <button
                                            style={{height: 44}}
                                            className="inactivate-btb"
                                                onClick={() => open(
                                                    <CustomDelByNote
                                                        info="Cancel Appointment?"
                                                        text="Are you sure you want to cancel this appointment? This action cannot be undone."
                                                        required={false}
                                                        handleDel={(comment) => {
                                                            dispatch(appointmentActions.setAppointmentStatus(item?.id, 'cancel', comment));
                                                        }}
                                                        innerText={'Yes'}
                                                        inputLabel={'Comment'}
                                                        color={'blue'}
                                                        actionType={CANCEL_ACTION_TYPE}
                                                        successType={SET_APPOINTMENT_STATUS}
                                                        afterSuccess={afterSuccess}
                                                    />,
                                                )}
                                        >
                                            Cancel Appointment
                                        </button>
                                    }
                                    {checkRenderComplete &&
                                        <button className="render-btn-box"
                                                disabled={!!loadNotes?.length}
                                                onClick={() => handleStatusChange(_isServiceAppmt ? 'render' : 'complete', item?.type)}
                                        >
                                            {_isServiceAppmt ? 'Render Appointment' : 'Complete Appointment'}
                                        </button>
                                    }
                                </div>
                            )
                            :
                            _isServiceAppmt && (
                                <div>
                                    {RolePermission([PermissionList.APPT_UNRENDER?.code]) &&
                                        item?.billStatus === billStatuses?.NOT_BILLED && item?.status !== apptEnums.CANCELLED &&
                                        <button style={{ width: 250 }} className="render-btn-box" onClick={unRenderAppt}>
                                            {'Un-render Appointment'}
                                        </button>
                                    }

                                    {RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) && item?.billStatus === billStatuses?.BILLED &&
                                        item?.status !== apptEnums.CANCELLED &&
                                        <button style={{ width: 250 }} className="render-btn-box" onClick={removeFromBill}>
                                            {'Remove from Claim'}
                                        </button>
                                    }
                                </div>
                            )
                        }
                    </div>


                    <SimpleModal
                        handleOpenClose={() => setModalIsOpen((prevState) => !prevState)}
                        openDefault={modalIsOpen}
                        content={
                            <ModalContentWrapper
                                wrapperClassName={classes.signatureModalWrapperStyle}
                                onClose={() => setModalIsOpen(false)}
                                titleContent={'Upload Signature'}
                                subtitleContent={
                                    'To complete a service appointment, please upload a signature.'
                                }
                            >
                                <SignatureUploader
                                    handleClose={() => {
                                        setModalIsOpen(null)
                                        afterSuccess()
                                    }}
                                    appt={item}
                                />
                            </ModalContentWrapper>
                        }
                        secondary={true}
                    />
                </>
                :
                <NoItemsYet
                    containerStyles={{position:'relative'}}
                    height={'400px'}
                    text={'Appointment was not found'}
                    image={Images.noAppt}
                />
            }
        </div>

    )
        ;
};