import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { HtmlTooltip } from '../tabs/fullTableCore/htmlTool';
import { SlicedText } from '../messages';
import { NumericFormat } from 'react-number-format';
import { formatDuration, PermissionList, RolePermission } from '../../utils/hooks';
import { SimpleTooltip } from '../tooltip/tooltip';
import { Images, useSecondaryModal } from '../../utils';
import { ScheduleDetails } from '../../fragments/schedule/core/modals';


export const TextRow = ({ name, textWidth, color, wrapperStyle }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';
    const text = (name ? name : '...') + '';
    return (
        <div ref={curr} className="text-style"
             style={{ ...wrapperStyle }}>{RenderText(width, text, textWidth, color)}</div>
    );
};

const hasDecimal = (number) => {
    return number.toString().includes('.');
};
export const FixDecimalToTree = ({ number }) => {
    if (number) {
        const num = hasDecimal(number) ? number.toFixed(3) : number;
        if (num) {
            return num;
        } else {
            return 'N/A';
        }
    } else {
        return 'N/A';
    }
};
export const LinkRow = ({ name, textWidth, color, href, checkPermission }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';
    const text = (name ? name : '...') + '';

    const handleOpen = (e) => {
        if (name && name !== 'N/A' && href) {
            e.preventDefault();
            e.stopPropagation();
            window.open(href);
        }
    };

    const activeStyles = {
        color: checkPermission ? '#347AF0' : '#4B5C68',
        textDecoration: checkPermission ? 'underline' : 'none',
        width: 'fit-content',
        cursor: checkPermission ? 'pointer' : 'default',
    };

    return (
        <div ref={curr} className="text-style">
            <p onClick={(e) => handleOpen(e)} style={activeStyles}>{RenderText(width, text, textWidth, color)}</p>
        </div>
    );
};


export const ApptDetailRow = ({ name, textWidth, color, id, showWidth, afterSuccess }) => {
    const [currentWidth, setCurrentWidth] = useState(null);
    const { openSecondary } = useSecondaryModal();


    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';
    const text = (name ? name : '...') + '';

    const handleOpen = () => {
        openSecondary(<ScheduleDetails currentId={id} getAfterSuccess={afterSuccess} />);
    };

    const activeStyles = {
        color: '#347AF0',
        textDecoration: 'underline',
        width: 'fit-content',
        cursor: 'pointer',
    };

    return (
        <div ref={curr} className="text-style" style={{ width: showWidth ? showWidth : 'fit-content' }}>
            <p onClick={(e) => handleOpen(e)} style={activeStyles}>{text}</p>
        </div>
    );
};

export const IconAndTitle = ({ icon, title, textWidth, imgClass, label }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';

    return (
        <div ref={curr} className={`info-and-image ${label}`}>
            <img style={{ margin: '0 8px 0 0' }} className={imgClass ? `${imgClass}` : ''} src={icon} alt="Icon" />
            <div style={{ fontWeight: 'bold' }} className="full-width">{RenderText(width, title, textWidth)}</div>
        </div>
    );
};
export const IconAndImage = ({ item, title, textWidth, avatar }) => {
    const [currentWidth, setCurrentWidth] = useState(null);
    const startName = item?.firstName ? item?.firstName?.slice(0, 1) : '';
    const lastName = item?.lastName ? item?.lastName?.slice(0, 1) : '';

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';

    return (
        <div ref={curr} className="info-and-avatar">
            <div className="avatar-box">
                {avatar ?
                    <img src={avatar} alt={'avatar'} />
                    :
                    `${startName}${lastName}`
                }
            </div>
            <div style={{ fontWeight: 'bold' }} className="full-width">{RenderText(width, title, textWidth)}</div>
        </div>
    );
};

export const PriceRow = ({ info, textWidth }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;
    const price = (info ? info.toFixed(2) : 0) + '';

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);

        return <div ref={curr} className="text-style">
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </p>
                    }
                    placement="top-start"
                >
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} prefix={'$'} />...
                    </p>
                </HtmlTooltip>
                :
                <p>{
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }</p>
            }
        </div>;
    } else {
        return '...';
    }
};


export const UnitsRow = ({ info, textWidth, renderTime }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;

    const price = (info ? info.toFixed(2) : 0) + '';

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);
        return <div ref={curr} className="text-style">
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                        </p>
                    }
                    placement="top-start"
                >
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} />...
                    </p>
                </HtmlTooltip>
                :
                <p>{
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                }</p>
            }
        </div>;
    } else {
        return '...';
    }
};

const convertHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours.toString().padStart(2, '0')}h${remainingMinutes.toString().padStart(2, '0')}m`;
};

export const UnitsRowByHour = ({ info, textWidth, size }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;
    const price = (info ? info.toFixed(2) : 0) + '';
    let hour = size * info;
    const hoursCoverted = size ? `${formatDuration(convertHours(hour))}` : '';

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);
        return <div ref={curr} className="text-style">
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                            {hour !== 0 && <p>{hoursCoverted}</p>}
                        </p>
                    }
                    placement="top-start"
                >
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} />...
                    </p>
                </HtmlTooltip>
                :
                <p>
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                    {hour !== 0 && <p>{hoursCoverted}</p>}
                </p>
            }
        </div>;
    } else {
        return '...';
    }
};


export const DateRow = ({ date }) => {
    return (
        date ? moment.utc(date).format('MM/DD/YYYY') : 'N/A'
    );
};


export const DateRowStartEnd = ({ start, end }) => {
    return (
        `${moment.utc(start).format('MM/DD/YYYY')}-${moment.utc(end).format('MM/DD/YYYY')}`
    );
};


export const IdRow = ({ id, size }) => {
    return (
        <SlicedText type={'id'} size={size ? size : 10} data={id} />
    );
};

export const DisplayIdRow = ({ id }) => {
    return (
        <p style={{ whiteSpace: 'nowrap' }}>{id ? id : '...'}</p>
    );
};


export const DisplayIdAnFlagRow = ({ id, title, flag }) => {
    return (
        <div className="flex-align-center" style={{ gap: 8 }}>
            <p>{id ? id : '...'}</p>
            {flag &&
                <SimpleTooltip
                    title={
                        <p>{title ? title : 'One or more appointments in this claim have incomplete units. Please review appointment(s) before submitting claim.'}</p>}
                    placement="bottom"
                    arrow
                >
                    <img src={Images.flag} alt="flag" />
                </SimpleTooltip>
            }
        </div>

    );
};

export const ApptFlags = ({ id, updateFlag, incomplete, removed, link, singleAppt, fromAppt }) => {
    return (
        <div className="flex-align-center" style={{ gap: 8 }}>
            {link ?
                <a href="/schedule" style={{ color: '#347AF0', whiteSpace: 'nowrap' }}>
                    {id}
                </a>
                :
                <p style={{ whiteSpace: 'nowrap', width: 'fit-content' }}>{id ? id : ''}</p>
            }
            {!fromAppt &&
                <div style={{ width: 20 }}>
                    {updateFlag &&
                        <SimpleTooltip
                            title={
                                <p>{
                                    singleAppt ? 'This appointment has been updated.' : 'One or more appointments in this claim have been updated. Please review appointment(s) before submitting claim.'
                                }
                                </p>
                            }
                            placement="bottom"
                            arrow
                        >
                            <img src={Images.yellowFlag} alt="flag" />
                        </SimpleTooltip>
                    }
                </div>
            }
            <div style={{ width: 20 }}>
                {incomplete &&
                    <SimpleTooltip
                        title={
                            <p>{singleAppt ? 'This appointment has incomplete units.' : 'One or more appointments in this claim have incomplete units. Please review appointment(s) before submitting claim.'}</p>}
                        placement="bottom"
                        arrow
                    >
                        <img src={Images.flag} alt="flag" />
                    </SimpleTooltip>
                }
            </div>
            <div style={{ width: 20 }}>
                {removed &&
                    <SimpleTooltip
                        title={<p>{'Appointment was removed from claim'}</p>}
                        placement="bottom"
                        arrow
                    >
                        <img src={Images.blueFlag} alt="flag" />
                    </SimpleTooltip>
                }
            </div>
        </div>
    );
};


export const BatchFlagRow = ({ id, flag, notShowFlag, notShowResolve, resolve, handleResolve }) => {
    return (
        <div className="flex-align-center" style={{ gap: 8 }}>
            <p>{id ? id : 'N/A'}</p>
            <div style={{ width: 20 }}>
                {resolve &&
                    <div className="resolved-btn-box">
                        <img src={Images.greenMark} alt="greenMark" />
                        <p>Resolved</p>
                    </div>
                }
                {flag && !resolve &&
                    <div className="flex-align-center" style={{ gap: '8px' }}>
                        {!notShowFlag &&
                            <SimpleTooltip
                                title={<p>{
                                    'This appointment has incomplete units.'}
                                </p>}
                                placement="bottom"
                                arrow
                            >
                                <img src={Images.yellowFlag} alt="flag" />
                            </SimpleTooltip>
                        }
                        {!notShowResolve &&
                            <button onClick={handleResolve} className="resolve-btn-box">
                                Resolve
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export const PhoneRow = ({ phone }) => {
    const converted = phone ? '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 6) : 'N/A';
    return (
        converted
    );
};

export const TypeRow = ({ text }) => {
    if (text && text !== undefined) {
        return (
            text?.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                return g1.toUpperCase() + g2.toLowerCase();
            })
        );
    } else {
        return '...';
    }
};

const RenderText = (width, text, textWidth, color) => {
    if (text) {
        const count = Math.ceil(width / (textWidth ? textWidth : 9));
        const slicedText = '' + text?.slice(0, count);
        return <>{
            text?.length > count ?
                <HtmlTooltip
                    title={<p className="show-tool-text-style">{text}</p>}
                    placement="top-start"
                >
                    <p
                        style={{ width: width, wordWrap: 'break-word' }}
                        // style={color ? { color: color } : {}}
                        className="show-text-style">
                        {`${slicedText}...`}
                    </p>
                </HtmlTooltip>
                :
                <p style={color ? { color: color } : {}}>{text}</p>
        }
        </>;
    } else {
        return '...';
    }
};


export const PriceConvertor = ({ price }) => {
    const convertedPrice = price ? price.toFixed(2) : 0;
    return <NumericFormat value={convertedPrice ? convertedPrice : '0'} displayType={'text'} thousandSeparator={true}
                          prefix={'$'} />;

};

export const StatusRow = ({ status, noCircle }) => {

    const renderCircle =
        status === 'ACTIVE' ? '#6FD231' :
            status === 'FBA' ? '#ADD8E6' :
                status === 'FBS' ? '#ADD8E6' :
                    status === 'ON_HOLD' ? '#FFCA33' :
                        status === 'TERMINATED' ? '#FE7070' :
                            status === 'WAIT_LIST' ? '#FE7B43' :
                                status === 'GRADUATED' ? '#51439E' :
                                    status === 'INACTIVE' ? '#98A2B3' :
                                        '';

    const renderText = status === 'ACTIVE' ? 'Active' :
        status === 'FBA' ? 'FBA' :
            status === 'FBS' ? 'FBA' :
                status === 'ON_HOLD' ? 'On Hold' :
                    status === 'TERMINATED' ? 'Terminated' :
                        status === 'WAIT_LIST' ? 'Wait List' :
                            status === 'GRADUATED' ? 'Graduated' :
                                status === 'INACTIVE' ? 'Inactive' :
                                    '';

    return (
        <div className="flex-align-center">
            {!noCircle && <div className={`circle-box`} style={{ background: renderCircle }} />}
            <p className="status-circle-text">{renderText}</p>
        </div>
    );
};

export const renderCptCodeAndModifiers = (cptCode, chargeRateModifier, posModifier) => {
    if (cptCode) {
        return `${cptCode}${(chargeRateModifier || posModifier) ? ` (${chargeRateModifier ? `${chargeRateModifier}${posModifier ? ', ' : ''}` : ''}${posModifier && `${posModifier}`})` : ''}`;
    } else {
        return 'N/A';
    }
};

export const AgingRow = ({ count }) => {
    if (count < 30) {
        return (
            <div className="green-zone-row">
                {count}
            </div>
        );
    } else if (count > 30 && count < 60) {
        return (
            <div className="yellow-zone-row">
                {count}
            </div>
        );
    } else if (count > 60) {
        return (
            <div className="red-zone-row">
                {count}
            </div>
        );
    } else {
        return 'N/A';
    }
};

export const AgingRowBackground = (count) => {
    if (count < 30) {
        return '#ECFDF3';
    } else if (count > 30 && count < 60) {
        return '#FFF6ED';
    } else if (count > 60) {
        return '#FEF3F2';
    } else {
        return '';
    }
};