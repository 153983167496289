import { ApptDetailRow, DateRow, DisplayIdRow, LinkRow, PriceRow, TextRow, TypeRow } from 'components';
import { Colors, Images, manageType, PermissionList, RolePermission, states } from 'utils';

const { showDashIfEmpty } = hooksForTable;
import {
    hooksForTable,
    makeCapitalize,
} from 'utils';
import { HtmlTooltip } from '../../../../components/tabs/fullTableCore/htmlTool';
import React, { useState } from 'react';
import { residencyEnumTypes } from '../../createStaff/core/constants';

export const staffTabsLabels = (checkTimesheetTab) => [
    RolePermission([PermissionList.STAFF_READ.code, PermissionList.STAFF_CREATE.code]) &&
    { label: 'General' },
    RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code, PermissionList.STAFF_PAYCODE_READ?.code]) &&
    { label: 'Paycodes' },
    checkTimesheetTab &&
    { label: 'Timesheet' },
    RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code, PermissionList.STAFF_CREDENTIAL_READ?.code]) &&
    { label: 'Credentials' },
    RolePermission([PermissionList.STAFF_ROLES_MANAGE?.code]) &&
    { label: 'Access' },
    RolePermission([PermissionList.STAFF_SERVICE_MANAGE?.code, PermissionList.STAFF_SERVICE_READ?.code]) &&
    { label: 'Services' },
    RolePermission([PermissionList.STAFF_NOTE_MANAGE?.code, PermissionList.STAFF_NOTE_READ?.code]) &&
    { label: 'Notes' },
    RolePermission([PermissionList.STAFF_HISTORY_READ?.code]) &&
    { label: 'History' },
    RolePermission([PermissionList.STAFF_FILE_MANAGE?.code, PermissionList.STAFF_FILE_READ?.code]) &&
    { label: 'Files' },
];

export const staffHeaderTitles = [
    { title: 'Date', sortable: true },
    { title: 'Creator Name', sortable: true },
    { title: 'Subject', sortable: false },
    { title: 'Action', sortable: false },
];

export function getStaffGeneralInfo(info) {

    return [
        { title: 'Secondary Email', value: info?.secondaryEmail },
        {
            title: 'Secondary Phone Number',
            value: info?.secondaryPhone ? '(' + info?.secondaryPhone.substr(0, 3) + ') ' + info?.secondaryPhone.substr(3, 3) + '-' + info?.secondaryPhone.substr(6, 6) : '',
        },
        {
            title: 'Address',
            value: `${info?.address?.formattedAddress} ${info?.address?.unitNumber ? ` / ${info?.address?.unitNumber}` : ''}`,
        },
        {
            title: 'Gender & Date of Birth',
            value: (info?.gender || info?.birthday) && `${info?.gender ? `${info?.gender},` : ''} ${info?.birthday ? hooksForTable.handleCreatedAtDate(info?.birthday) : ''}`,
        },
        {
            title: 'Languages Spoken',
            value: info?.languages?.length ? info?.languages?.map((i) => `${i}`).join(', ') : '',
        },


    ].filter((item) => !!item.value);
}

export function getStaffEmploymentInfo(info, pastEmployments) {
    if (info) {
        return [
            { title: 'Title', value: info?.title?.name },
            {
                title: 'Supervisor',
                value: info?.supervisor ? `${info?.supervisor?.firstName} ${info?.supervisor?.lastName ? info?.supervisor?.lastName : ''}` : '',
            },
            { title: 'Start Date', value: hooksForTable.handleCreatedAtDate(info?.startDate) },
            { title: 'Department', value: info?.department?.name },
            { title: 'Employment Type', value: manageType(info?.type) },
            { title: 'Past Employments', value: info?.address?.zip },
            { title: 'Past Employments', value: 'view history' },
        ].filter((item) => !!item.value);
    } else {
        return pastEmployments?.length > 0 ?
            [
                { title: 'Past Employments', value: 'view history' },
            ] :
            [];
    }

}

export function getStaffOtherDetails(info) {
    const checkTb = info?.credentials?.find((i) => i?.type === 'TB');
    const checkDoj = info?.credentials?.find((i) => i?.type === 'DOJ');
    const residency = residencyEnumTypes?.find((i) => i?.id === info?.residency);
    const issuingState = states?.find((i) => i?.id === info?.license?.state);

    const [show, setShow] = useState(false);


    return [
        {
            title: 'Driver License',
            value: info?.license?.driverLicense ? `${info?.license?.driverLicense},
             ${info?.license?.state ? ` ${issuingState?.name}, ` : ''}
              ${info?.license?.expireDate ? `EXP:${hooksForTable.handleCreatedAtDate(info?.license?.expireDate)}` : ''}` : '',
        },
        { title: 'NPI Number', value: info?.npi },
        { title: 'Residency Status', value: residency?.label },
        RolePermission([PermissionList.STAFF_SSN_VIEW?.code]) &&
        {
            title: 'SSN Number',
            value: info?.ssn ?
                <div className="space-between" style={{ alignItems: 'center', marginTop: '-3px' }}>
                    <p style={{ width: 85 }}>{show ? info?.ssn : '**********'}</p>
                    <button onClick={() => setShow(!show)} className="reset-btn" style={{ padding: 0 }}>
                        <img src={show ? Images.showEye : Images.hideEye} alt="icon"
                             style={{ margin: '3px 0 -3px 0' }} />
                    </button>
                </div>
                : null,
        },
        info?.credentials?.length ?
            {
                title: 'TB', value: 'a', type: 'multiple',
                list: [
                    {
                        title: 'TB',
                        value: <div className="flex-align-center">
                            <img src={checkTb?.checked ? Images.checkCredential : Images.uncheckCredential} alt="icon"
                                 style={{ marginRight: '4px' }} />
                            <p>{checkTb?.checked ? 'checked' : 'not checked'}</p>
                        </div>,
                    },
                    {
                        title: 'Start Date',
                        value: checkTb?.startDate ? hooksForTable.handleCreatedAtDate(checkTb?.startDate) : 'Not Set',
                    },
                    {
                        title: 'Experation Date',
                        value: checkTb?.expireDate ? hooksForTable.handleCreatedAtDate(checkTb?.expireDate) : 'Not Set',
                    },
                ],
            } : {
                title: 'DOJ', value: null,
            },
        info?.credentials?.length ?
            {
                title: 'DOJ', value: 'a', type: 'multiple',
                list: [
                    {
                        title: 'DOJ',
                        value: <div className="flex-align-center">
                            <img src={checkDoj?.checked ? Images.checkCredential : Images.uncheckCredential} alt="icon"
                                 style={{ marginRight: '4px' }} />
                            <p>{checkDoj?.checked ? 'checked' : 'not checked'}</p>
                        </div>,
                    },
                    {
                        title: 'Selected Date',
                        value: checkDoj?.startDate ? hooksForTable.handleCreatedAtDate(checkDoj?.startDate) : 'Not Set',
                    },
                ],
            } :
            {
                title: 'DOJ', value: null,
            },

    ].filter((item) => !!item?.value);
}

export const editButtonStyle = {
    height: '36px',
    paddingInline: '24px',
};

export const filterBtnStyle = {
    width: '93px',
    height: '36px',
};

// Credential Table config
export const FBI = 'DOJ/FBI';
export const TB = 'TB';

export const credentialBody = [
    {
        rowText: (item) => {
            if (item?.credential?.name === FBI || item?.credential?.name === TB) {
                return <HtmlTooltip
                    title={
                        <p>
                            Default Credential
                        </p>
                    }
                    placement="top-start"
                >
                    <p className="credential-default-name">
                        <img src={Images.starVector} alt="icon" />
                        <TextRow name={item?.credential?.name} />
                    </p>
                </HtmlTooltip>;
            } else {
                return <TextRow name={item?.credential?.name} />;
            }
        }, width: '20%',
    },
    { rowText: (item) => <TypeRow text={item?.credential?.type} />, width: '15%' },
    { rowText: (item) => <DateRow date={item?.receiveDate ? item?.receiveDate : ''} />, width: '15%' },
    {
        rowText: (item) =>
            item?.expirationDate ? (
                <DateRow date={item?.expirationDate} />
            ) : (
                <span style={{ color: Colors.TextLightGray }}>Non-Expiring</span>
            ), width: '15%',
    },
    { rowText: (item) => <TextRow name={item?.licenseNumber} />, width: '20%' },
    RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code]) &&
    {
        button: (cb, item, it) => (<div style={{ display: 'flex', alignItems: 'center' }}>

                <img
                    style={{ cursor: 'pointer', marginRight: '16px' }}
                    src={Images.edit}
                    alt="edit"
                    onClick={() => cb('editCredential')}
                />
                {(item?.credential?.name !== FBI && item?.credential?.name !== TB) &&
                    <img
                        style={{ cursor: 'pointer' }}
                        src={Images.remove}
                        alt="remove"
                        onClick={() => cb('deleteCredential')}
                    />
                }
            </div>
        ),
        notClickable: true, width: '15%',
    },
];

export const ACTION_TYPE = 'GET_CREDENTIAL';

// Timesheet Table config
export const timesheetHead = [
    { name: '', title: 'ID', custom: false, noSearch: true },
    { name: '', title: 'Appt ID', custom: false, noSearch: true },
    { name: 'timesheetDate', title: 'Date', iconType: 'date', noSearch: true },
    { name: '', title: 'CPT Code', custom: false, noSearch: true },
    {
        name: 'type', title: 'Type', disabled: true,
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Hourly', id: 'HOURLY' },
            { name: 'Salary', id: 'SALARY' },
            { name: 'Fixed', id: 'FIXED' },
            { name: 'Mileage', id: 'MILEAGE' },
        ], noSearch: true,
    },
    { name: '', title: 'Paycode', custom: false, noSearch: true },
    { name: '', title: 'Rate', custom: false, noSearch: true },
    { name: '', title: 'Qty', custom: false, noSearch: true },
    { name: '', title: 'Total AMT', custom: false, noSearch: true },
    {
        name: 'status', title: 'Status', width: '100px',
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Active', id: 'ACTIVE' },
            { name: 'Processed', id: 'PROCESSED' },
        ],
        noSearch: true,
    },
    RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) &&
    { name: '', title: ' ', custom: false, noSearch: true, width: '50px' },
];

export const timesheetBody = (afterSuccess) => [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} />, notClickable: true },
    { rowText: (item) => item?.apptDisplayId ? <ApptDetailRow name={item?.apptDisplayId} id={item?.apptId} showWidth={'100%'} afterSuccess={afterSuccess} /> : 'N/A', notClickable: true },
    // { rowText: (item) => <DisplayIdRow id={item?.apptDisplayId ? item?.apptDisplayId : 'N/A'} />, notClickable: true },
    { rowText: (item) => <DateRow date={item?.timesheetDate} />, notClickable: true },
    { rowText: (item) => <TextRow name={item?.cptCode ? item?.cptCode : 'N/A'} />, notClickable: true },
    { rowText: (item) => <TypeRow text={item?.type ? item?.type : 'N/A'} />, notClickable: true },
    { rowText: (item) => <TextRow name={item?.payCode ? item?.payCode : 'N/A'} />, notClickable: true },
    { rowText: (item) => <PriceRow info={item?.amount} />, notClickable: true },
    { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 'N/A'} />, notClickable: true },
    { rowText: (item) => <PriceRow info={item?.totalAmount} />, notClickable: true },
    { rowText: (item) => <TypeRow text={item?.status} />, notClickable: true },
    RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) &&
    {
        button: (cb, item) => item?.apptDisplayId ? ' ' :
            <button className="reset-btn" onClick={() => cb('edit')}>
                <img src={Images.edit} alt={'icon'} />
            </button>,
    },
];


export const timesheetDetailHead = [
    { name: 'rateType', title: 'Rate Type', custom: false, noSearch: true },
    { name: 'rate', title: 'Rate', custom: false, noSearch: true },
    { name: 'hours', title: 'Hours', custom: false, noSearch: true },
    { name: 'amount', title: 'Amount', custom: false, noSearch: true },

];

const renderRate = (item) => {
    const rate =
        !!item?.amount && !!item?.hours
            ? item.amount / item.hours
            : item?.payCode?.rate;
    return rate;
};
export const timesheetDetailBody = [
    {
        rowText: (item) => <TypeRow
            text={item?.payCode?.payCodeTypeId?.type ? showDashIfEmpty(makeCapitalize(item?.payCode?.payCodeTypeId?.type)) : 'Fixed'} />,
    },
    { rowText: (item) => <PriceRow info={renderRate(item)} /> },
    {
        rowText: (item) => <TextRow
            name={item.regularHours === 'NaNhNaNm' ? 'N/A' : item.regularHours || item?.hours} />,
    },
    { rowText: (item) => <PriceRow info={item?.totalAmount} /> },
];

export const TIMESHEET_ACTION_TYPE = 'GET_TIMESHEET';


export const EMPLOYMENT_TYPES = [
    { name: 'Full', id: 'FULL' },
    { name: 'Part', id: 'PART' },
    { name: 'Intern', id: 'INTERN' },
    { name: 'Contractor', id: 'CONTRACTOR' },
];


export const staffStatus = {
    PENDING: 1,
    ACTIVE: 2,
    INACTIVE: 3,
};

export const STAFF_STATUSES = [
    { label: 'Active', id: 'ACTIVE' },
    { label: 'Inactive', id: 'INACTIVE' },
];


export const staffPaycodeNotYet = {
    title: 'No Paycodes Yet',
    subTitle: 'Add paycodes to get started.',
    image: Images.noNote,
};

export const staffTimesheetNotYet = {
    title: 'No Timesheets Available',
    subTitle: 'Start by adding timesheets to track work hours.',
    image: Images.noHistory,
};

export const staffCredentialNotYet = {
    title: 'No Credentials Added',
    subTitle: 'Add credentials to track this staff member’s qualifications.',
    image: Images.noClientYet,
};

export const staffAccessNotYet = {
    title: 'No Roles Assigned',
    subTitle: 'Assign roles to define this staff member’s access and permissions.',
    image: Images.noAccess,
};

export const staffServiceNotYet = {
    title: 'No Services Assigned',
    subTitle: 'Assign services to define what this clinician can provide.',
    image: Images.noNote,
};
