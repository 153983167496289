import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CheckboxesTags, CreateChancel, DateInput, SelectTypeAutocomplete, ValidationInput } from 'components';
import { ErrorText, Images, useModal } from 'utils';
import { CsvService } from 'store';
import { useHistory } from 'react-router-dom';


export const DownloadPayrollCsv = ({ status }) => {
    const staff = useSelector((state) => state.admins.adminsList);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const [load, setLoad] = useState(false);
    const { close } = useModal();
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (info) {
            const params = {};
            info?.staffs?.length ? params.staffs = info.staffs : delete params.staffs;
            info?.firstDate ? params.startDate = info.firstDate : delete params.startDate;
            info?.lastDate ? params.endDate = info.lastDate : delete params.endDate;
            setInputs(params)
        }
    }, [info]);

    const handleChangeStaffs = (list) => {
        setInputs((prevState) => ({ ...prevState, ['staffs']: list }));
    }
    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleExport = async () => {
        // if (inputs.staff && inputs.startDate && inputs.endDate) {
            setLoad(true);
            let response = '';

            try {
                const params = {
                    status: status,
                }
                inputs.startDate ? params.startDate = moment.utc(inputs.startDate).format('YYYY-MM-DD') : '';
                inputs.endDate ? params.endDate = moment.utc(inputs.endDate).format('YYYY-MM-DD') : '';
                inputs?.staffs?.length ? params.staffs = inputs.staffs?.map((i) => i?.id) : delete params.staffs;
                response = await CsvService.activePassivePayrollCsv(params);

                const type = status === 'ACTIVE' ? 'activePayroll' : 'processedPayroll';
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${type}.csv`);
                document.body.appendChild(link);
                link.click();
                setLoad(false);
                close();
            } catch (e) {
                setLoad(false);
                close();
            }
    };

    return (
        <div className="download-payroll-csv-modal">
            <p className="download-modal-title">Download Payroll CSV</p>
            <p className="download-modal-desc">
                Select the date range and staff for the payroll
                data you wish to download. The fields will be
                pre-filled based on your current filters.
            </p>
            <div className="download-modal-body">
                <CheckboxesTags
                    name="staffs"
                    handleChange={handleChangeStaffs}
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    value={inputs?.staffs ? inputs?.staffs : []}
                    permissionsList={staff?.staffs || []}
                    label={'Staff'}
                    placeholder={'Select Saff'}
                    uiType={'modalCheckboxSelect'}
                />

                <div className="start-end-time-box" >
                    <DateInput
                        name={'startDate'}
                        label={'Start Date'}
                        handleChange={handleChange}
                        value={inputs.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : null}
                        max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}
                    />
                    <DateInput
                        name={'endDate'}
                        label={'End Date'}
                        handleChange={handleChange}
                        value={inputs.endDate ? moment.utc(inputs.endDate).format('MM/DD/YYYY') : null}
                        min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}
                    />
                </div>
            </div>

            <div>
                <CreateChancel
                    image={Images.downloadCloudWhite}
                    loader={load}
                    create={'Download CSV'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};