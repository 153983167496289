import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FLAGGED_ENUMS, PermissionList, RolePermission, SaveParams, useModal } from 'utils';
import { AddButton, CustomDateSelector, DownloadCsv, SelectTypeAutocomplete } from 'components';
import { adminActions, clientActions, fundingSourceActions } from 'store';
import { GenerateClaim } from './generateClaim';

export const ClaimFilters = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const clientList = useSelector((state) => state.client.clientList);
    const { open } = useModal();

    useEffect(() => {
        dispatch(clientActions.getClients());
        dispatch(fundingSourceActions.getFundingSource());
        dispatch(adminActions.getAdmins());
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...info });
    };

    return (
        <div className="table-invoice-filter-section">
            <div className="billing-invoices-filter-box">
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.payer ? '' : 'All'}
                    name={'payer'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.payer}
                    list={fundingSourceList?.funders ?
                        [{ id: 'All', name: 'All' }, ...fundingSourceList?.funders]
                        :
                        [{ id: 'All', name: 'All' }]
                    }
                    renderValue={(i) => i?.name}
                    uiType={'tableFilter'}
                    outLabel={'Funding Source'}
                    noError={true}
                />
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.client ? '' : 'All'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.client}
                    list={clientList?.clients ?
                        [{ id: 'All', firstName: 'All' }, ...clientList?.clients]
                        :
                        [{ id: 'All', firstName: 'All' }]
                    }
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    uiType={'tableFilter'}
                    outLabel={'Client'}
                    noError={true}
                />
                <CustomDateSelector outLabel={'DOS'} />
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.flag ? '' : 'All'}
                    name={'flag'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.flag}
                    list={FLAGGED_ENUMS}
                    renderValue={(i) => i?.label}
                    uiType={'tableFilter'}
                    outLabel={'Flagged'}
                    noError={true}
                />
            </div>

            <div className="billing-invoices-filter-box">
                <DownloadCsv
                    fromModal={true}
                    smallSize={true}
                    type={'pendingClaims'}
                />
                {RolePermission([PermissionList.CLAIMS_CREATE?.code]) && type === 'pending' &&
                    <div>
                        <AddButton
                            text={'Prepare Claims'}
                            handleClick={() => open(<GenerateClaim />)}
                        />
                    </div>
                }
            </div>
        </div>
    );
};