import { formatAMPMNeW, formatMiles, Images, makeCapitalize, PermissionList, RolePermission } from 'utils';
import moment from 'moment';
import { billStatuses, BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../constants';
import { ApptFlags, DateRowStartEnd, LinkRow, PriceRow, SimpleTooltip, TextRow } from '../../../components';
import { Checkbox } from '@material-ui/core';
import React from 'react';
import { ApptActionsWithModals } from './apptActionsWithModals';
import { apptDetailStatus, apptTableDetailStatus } from './modals/apptDetail/constants';

export function getBorderColorAndText(status, type) {
    if (type === 'SERVICE') {
        switch (status) {
            case 'PENDING':
                return { color: '#6FD231', background: '#F1FAEA', text: 'Not Rendered' };
            case 'COMPLETED':
                return { color: '#2A8E6D', background: '#EAF4F0', text: 'Rendered' };
            case 'CANCELLED':
                return { color: '#A3B2BD', background: '#EAEAEA', text: 'Cancelled' };
            default:
                return { color: '', text: '' };
        }
    } else {
        switch (status) {
            case 'NOTRENDERED':
                return { color: '#6FD231', background: '#F1FAEA', text: 'Not Rendered' };
            case 'RENDERED':
                return { color: '#2A8E6D', background: '#EAF4F0', text: 'Rendered' };
            case 'CANCELLED':
                return { color: '#A3B2BD', background: '#EAEAEA', text: 'Cancelled' };
            case 'PENDING':
                return { color: '#347AF080', background: '#EBF2FD', text: 'Pending' };
            case 'COMPLETED':
                return { color: '#347AF0', background: '#EBF2FD', text: 'Completed' };
            default:
                return { color: '', text: '' };
        }
    }
}

export function getCurrentText(type) {
    switch (type) {
        case DRIVE:
            return { cardText: 'Drive', detailText: 'Drive' };
        case PAID:
            return { cardText: 'Paid', detailText: 'Paid' };
        case BREAK:
            return { cardText: 'Break', detailText: 'Break' };
        case SERVICE:
            return { cardText: 'Service', detailText: 'Service' };
        case STAFF:
            return { cardText: 'Staff', detailText: 'Staff' };
        case UNPAID:
            return { cardText: 'Unpaid', detailText: 'Unpaid' };
        case SICK_TIME:
            return { cardText: 'Sick', detailText: 'Sick' };
        default:
            return { cardText: '', detailText: '' };
    }
}

export function getServiceAppmtDetails(serviceAppmt) {
    const {
        client,
        authorizedService,
        staff,
        staffPayCode,
        miles,
        placeService,
        creator,
        editor,
    } = serviceAppmt || {};

    const address = client?.addresses?.length ?
        client?.addresses?.find((i) => i.default === true)
        : null;

    const serviceAppmtDetails = [
        {
            detailText: 'Client:',
            detail: !!client && makeCapitalize(`${client?.firstName} ${client?.lastName} ${client?.code ? `( ${client?.code} )` : ''}`),
        },
        {
            detailText: 'Authorized Service:',
            detail: authorizedService?.authorization?.authId,
        },
        {
            detailText: 'CPT Code:',
            detail: authorizedService?.service?.cptCode,
        },
        {
            detailText: 'Staff Member:',
            detail: !!staff && makeCapitalize(`${staff?.firstName} ${staff?.lastName}`),
        },
        {
            detailText: 'Charge Rate:',
            detail: serviceAppmt?.chargeRateName,
        },
        {
            detailText: 'Staff Paycode:',
            detail: staffPayCode?.payCodeTypeId?.name ? makeCapitalize(staffPayCode?.payCodeTypeId?.name) : staffPayCode,
        },
        miles &&
        {
            detailText: 'Miles:',
            detail: formatMiles(miles),
        },
        {
            detailText: 'Client Address:',
            detail: client?.address?.formattedAddress,
        },
        {
            detailText: 'Place of Service:',
            detail: placeService?.name,
        },
        {
            detailText: 'Funding Source:',
            detail: serviceAppmt?.payer?.name,
        },
        {
            detailText: 'Creator:',
            detail: creator,
        },
        {
            detailText: 'Editor:',
            detail: editor,
        },
    ];

    return serviceAppmtDetails.filter((serviceAppmt) => serviceAppmt?.detail);
}


export const scheduleStatuses = [
    { id: 'All', name: 'All' },
    { id: 'RENDERED', name: 'Rendered' },
    { id: 'COMPLETED', name: 'Completed' },
    { id: 'NOT_RENDERED', name: 'Not Rendered' },
    { id: 'PENDING', name: 'Pending' },
    { id: 'CANCELLED', name: 'Cancelled' },
];

export const scheduleTypes = [
    { id: 'All', name: 'All' },
    { id: SERVICE, name: 'Service' },
    { id: STAFF, name: 'Staff' },
    { id: DRIVE, name: 'Drive' },
    { id: BREAK, name: 'Break' },
    { id: PAID, name: 'Paid' },
    { id: UNPAID, name: 'Unpaid' },
    { id: SICK_TIME, name: 'Sick' },
];

export const apptEnums = {
    PENDING: 'PENDING',
    RENDERED: 'RENDERED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
};

export const apptNotYet = {
    title: 'No appointments yet',
    subTitle: 'It looks like there are no appointments on your schedule. Start by adding new appointments.',
    image: Images.noAppt,
};


export const apptHead = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>,
            width: '50px',
        },
        { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'ID', custom: false, width: '180px' },
        {
            name: 'createdAt',
            title: 'Date/Time',
            searchKey: 'createdAt',
            iconType: 'date',
            icon: 'date',
            disabled: true,
            width: '120px',
        },
        { name: '', rowName: 'firstName', title: 'Staff', custom: false },
        { name: '', rowName: 'firstName', title: 'Client', custom: false },
        { name: 'type', title: 'Event', custom: false, width: '120px' },
        { name: '', title: 'Status', custom: false, disabled: true, width: '200px'},
        { name: 'client', title: 'Actions', custom: false, disabled: true, width: '150px' },
    ];
};

export const apptBody = (handleSelect, fullList, selected, openEdit) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{
                            padding: 0,
                            color: '#347AF0',
                        }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) =>
                <ApptFlags
                    fromAppt={true}
                    singleAppt={true}
                    id={item?.displayId}
                    incomplete={item?.incompleteUnitFlag}
                    removed={item?.removedFromClaimFlag}
                />,
        },
        {
            rowText: (item) => (
                <div>
                    <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
                    <p className="schedule-time">
                        {`${formatAMPMNeW(item?.startTime)}-${formatAMPMNeW(item?.endTime)}`}
                    </p>
                </div>
            ),
        },
        {
            rowText: (item) => <LinkRow
                name={item?.staff ? `${item?.staff?.firstName} ${item?.staff?.lastName ? item?.staff?.lastName : ''}` : 'N/A'}
                href={
                    RolePermission([PermissionList.STAFF_READ.code]) ?
                        `/staff/${item?.staff?.id || item?.staff?._id}` : ''
                }
                checkPermission={item?.staff ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                textWidth={8}
            />,
        },
        {
            rowText: (item) => item?.client ?
                <LinkRow
                    name={item?.client ?
                        `${item?.client?.code ? `(${item?.client?.code})` : ''} ${item?.client?.firstName} ${item?.client?.lastName ? item?.client?.lastName : ''} `
                        :
                        'N/A'
                    }
                    href={
                        RolePermission([PermissionList.CLIENT_READ?.code]) ?
                            `/client/${item?.client?.id || item?.client?._id}` : ''}
                    checkPermission={item?.client ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                    textWidth={8}
                />
                :
                'N/A',
        },

        { rowText: (item) => getCurrentText(item?.type)?.detailText },
        {
            rowText: (item) => (
                <div className="appt-detail-status-box">
                    {/*{apptTableDetailStatus(item?.status, item)}*/}
                    {apptDetailStatus(item?.status, item, true)}
                </div>
            ),
        },
        {
            rowText: (item) => (<ApptActionsWithModals item={item} handleEdit={openEdit} afterSuccess={() => {
            }} />), notClickable: true,
        },
    ];
};

export const checkEditPermission = (item) => {
    return item?.type === SERVICE ? RolePermission([PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) :
        item?.type === STAFF ? RolePermission([PermissionList.APPT_STAFF_CREATE?.code]) :
            item?.type === BREAK ? RolePermission([PermissionList.APPT_BREAK_CREATE?.code]) :
                item?.type === DRIVE ? RolePermission([PermissionList.APPT_DRIVE_CREATE?.code]) :
                    item?.type === PAID ? RolePermission([PermissionList.APPT_PTO_CREATE?.code]) :
                        item?.type === UNPAID ? RolePermission([PermissionList.APPT_UNPAID_CREATE?.code]) :
                            item?.type === SICK_TIME ? RolePermission([PermissionList.APPT_SICK_TIME_CREATE?.code]) :
                                false;
};
const renderStatus = (currentItem) => {
    const { color: borderColor, text } = getBorderColorAndText(currentItem?.status, currentItem?.type);
    return { color: borderColor, text };
};
