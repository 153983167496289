import React from 'react';
import moment from 'moment/moment';
import { Images } from 'utils';
import { DownloadLink,TextRow } from 'components';
export const SignatureDetails = ({ item }) => {
    return (
        <>
            <div className="signature-process">
                <p className="schedule-details-title">Signature Details</p>

                <div className="flex-align-center" style={{ gap: 8 }}>
                    <div className="signature-detail-box">
                        <div className="flex-align-center" style={{ gap: 4 }}>
                            <img src={Images.folder} alt="icon" />
                            <p className="signature-detail-box-title">File:</p>
                            <p className='signature-detail-name' ><TextRow name={item?.signature?.originalName} /></p>
                        </div>

                        <div className="flex-align-center" style={{ gap: 8 }}>
                            <a className="show-signature" href={item?.signature?.url} target={'_blank'}>
                                <p>View</p>
                                <img src={Images.showAyeBlue} alt="icon" />
                            </a>
                            <DownloadLink
                                linkClassName={'download-signature'}
                                linkInnerText={'Download'}
                                file={item?.signature}
                                small={true}
                            />
                        </div>
                    </div>

                    <div className="signature-detail-box">
                        <div className="flex-align-center" style={{ gap: 4 }}>
                            <img src={Images.time} alt="icon" />
                            <p className="signature-detail-box-title">Date/Time:</p>
                        </div>
                        <p className="signature-detail-box-value">{moment.utc(item?.signatureDate).format('YYYY-MM-DD h:mm a')}</p>
                    </div>

                    <div className="signature-detail-box">
                        <div className="flex-align-center" style={{ gap: 4 }}>
                            <img src={Images.locationGray} alt="icon" />
                            <p className="signature-detail-box-title">Location:</p>
                        </div>
                        <p className="signature-detail-box-value">
                            {item?.signatureLocation?.formattedAddress ? item?.signatureLocation?.formattedAddress : 'Not Set'}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};