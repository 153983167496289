import React from 'react';
import moment from 'moment/moment';
import { DateInput, ValidationInput } from 'components';
import { scheduleModalsStyle } from '../styles';
import { ErrorText } from '../../../../../utils';

export const RecurEventDates = ({ inputs, handleChange, error }) => {
    const classes = scheduleModalsStyle();

    return (
        <>
            <p className={classes.recurTitle} style={{ marginBottom: '16px' }}>Date Range</p>
            <div className={classes.dateInputs} style={{ margin: '8px 0 4px 0', gap: 16, width:'100%' }}>
                <DateInput
                    name={'startDate'}
                    handleChange={handleChange}
                    value={inputs?.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : inputs.startDate}
                    typeError={error === 'startDate' && `Start date ${ErrorText.isRequired}`}
                    max={inputs?.endDate ? moment.utc(inputs.endDate).format('MM/DD/YYYY') : null }
                />
                <DateInput
                    name={'endDate'}
                    handleChange={handleChange}
                    value={inputs?.endDate ? moment.utc(inputs.endDate).format('MM/DD/YYYY') : inputs.endDate}
                    typeError={error === 'startDate' && `End date ${ErrorText.isRequired}`}
                    min={inputs?.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : null }
                />
            </div>
        </>
    );
};
