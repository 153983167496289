import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ValidationInput,
    CreateChancel,
    ErrMessage,
    MinLoader,
    NoYet, SelectTypeAutocomplete,
} from 'components';
import { createClientStyle } from './styles';
import {
    Colors,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccess,
    hooksForErrors,
    isNotEmpty, useModal,
} from 'utils';
import {
    clientActions,
    fundingSourceActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
} from 'store';
import axios from 'axios';

const SERVICE_ACTION = 'GET_FUNDING_SOURCE_SERVICE_BY_ID';
export const AddAuthorizationService = ({ info, fundingId, authId, services }) => {
    const classes = createClientStyle();
    const dispatch = useDispatch();
    const ACTION_TYPE = !!info ? 'EDIT_CLIENT_AUTHORIZATION_SERV' : 'CREATE_CLIENT_AUTHORIZATION_SERV';
    const fSelect = useSelector((state) => state.fundingSource.fundingSourceServices);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState(info ? {
        ...info,
        total: info?.totalUnits,
        name: info?.service?.cptCode,
        modifiers: info?.service?._id,
    } : {});
    const [modCheck, setModCheck] = useState([]);
    const [modif, setModif] = useState([]);
    const loading = FindLoad(SERVICE_ACTION);
    const [checkModifiersLoader, setCheckModifiersLoader] = useState(false);
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const authServiceDefaultErrorText = hooksForErrors.getAuthServiceDefaultErrorText(error, backError);
    const { close } = useModal();
    const checkIfZero = info?.billedUnits === 0 && info?.completedUnits === 0 && info?.reservedUnits === 0;

    useEffect(() => {
        if (info) {
            axios.post(`/authService/authorizations/${authId}/fundingService/${info?.service?._id}/checkChargeRates`, null, { auth: true })
                .then((res) => {
                    setModif([...info?.chargeRates, ...res.data]);
                }).finally(() => {
                setCheckModifiersLoader(false);
            });

            setModCheck(info?.chargeRates);
        }
    }, [info]);

    useEffect(() => {
        // dispatch(fundingSourceActions.getFoundingSourceServiceById(fundingId, ));
        dispatch(fundingSourceActions.getFoundingSourceServiceById(fundingId, { active: true }));
    }, []);

    useEffect(() => () => {
        httpRequestsOnErrorsActions.removeError(ACTION_TYPE);
    }, []);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);


    function handleChange(e) {

        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (e.target.name === 'modifiers' && e.target.value) {
            if (e.target.value === '') return;
            setCheckModifiersLoader(true);
            setModCheck([]);
            const _fundingService = fSelect.find((item) => item.id === e.target.value);

            axios.post(`/authService/authorizations/${authId}/fundingService/${_fundingService?.id}/checkChargeRates`, null, { auth: true })
                .then((res) => {
                    setModif(res.data);
                }).finally(() => {
                setCheckModifiersLoader(false);
            });
        } else {
            setModCheck([]);
            setModif([]);
        }


        if (error === e.target.name || !!backError) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    }

    function handleChangeTotal(e) {
        if (e.target.value >= 0 && e.target.value.indexOf('.') === -1 && e.target.value <= 10000) {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
            if (error === e.target.name || !!backError) {
                setError('');
            }
            if (!!backError) {
                dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
            }
        }
    }

    function handleCreate() {
        let modifiersPost = modCheck?.map((i) => i?.id) || [];
        const modifiersAreValid = !!modifiersPost?.length;
        const authServiceDataIsValid = isNotEmpty(inputs.totalUnits) && modifiersAreValid;

        if (authServiceDataIsValid) {
            const createAuthServiceData = {
                totalUnits: +inputs.totalUnits,
                chargeRateIds: modifiersPost,
            };
            if (!!info) {
                const editAuthServiceData = {
                    ...createAuthServiceData,
                    authorizationId: authId,
                    serviceId: inputs?.service?._id,
                };
                dispatch(clientActions.editClientsAuthorizationsServ(editAuthServiceData, info.id, authId));
            } else {
                dispatch(clientActions.createClientsAuthorizationsServ(createAuthServiceData, authId, inputs.modifiers));
            }
        } else {

            setError(!inputs.modifiers ? 'modifiers' :
                !modifiersPost?.length ? 'modifiersPost' :
                    !isNotEmpty(inputs.totalUnits) ? 'totalUnits' : '');
        }
    }

    function onModifier(item) {
        error === 'modifiersPost' && setError('');
        let newList = [...modCheck];
        const ifHas = modCheck.find((i) => i?.id === item?.id);

        if (ifHas) {
            newList = newList.filter((i) => i?.id !== item?.id);
        } else {
            newList.push(item);
        }
        setModCheck(newList);
    }

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{info ? 'Edit Authorization Service' : 'Add Authorized Service'}</p>
            <div className="modal-body-wrapper">
                <SelectTypeAutocomplete
                    disabled={!!info}
                    loadType={SERVICE_ACTION}
                    title={'Service Code*'}
                    name={'modifiers'}
                    handleSelect={handleChange}
                    defaultValue={inputs.modifiers}
                    list={fSelect?.length ? fSelect : []}
                    error={error}
                    typeError={error === 'modifiers' ? `Service Code ${ErrorText.isRequired}` : ''}
                    renderValue={(i) => `${i?.serviceTypeId?.name} - ${i?.cptCode}`}
                />

                <div
                    className={`${classes.displayCodeBlock2} ${error === 'modifiersPost' || !!authServiceDefaultErrorText ? 'error' : ''}`}>
                    <p className={classes.displayCodeBlockText}>Charge Rates</p>
                    <div
                        className={`${classes.availableModfiers} ${!inputs.modifiers || checkModifiersLoader === true ? 'notApplicable' : ''}`}>
                        {loading?.length || checkModifiersLoader ? (
                            <div className={classes.loaderBoxStyle}>
                                <MinLoader margin={'0'} color={Colors.ThemeBlue} position={'relative'} />
                            </div>
                        ) : (
                            <div className={classes.serviceModifiersContainerStyle}>
                                <div>
                                    {!!modif?.length ? modif.map((item, index) => {
                                            const check = modCheck?.find((i) => i?.id === item?.id);
                                            const alreadyUsed = info?.chargeRates?.find((i) => i?.id === item?.id);
                                            const canEdit = info?.id ? alreadyUsed?.id ? !checkIfZero : false : false;
                                            const modifierBtnStyle = `${classes.availableModfier} ${check ? 'checked' : ''}`;

                                            // `${classes.availableModfier} ${check ? 'checked' : modifierExists ? 'chosen' : ''}`;

                                            if (!!item?.status) {
                                                return (
                                                    <button
                                                        key={index}
                                                        className={modifierBtnStyle}
                                                        onClick={() => onModifier(item)}
                                                        disabled={canEdit}
                                                    >
                                                        {item.name}
                                                    </button>
                                                );
                                            }
                                        })
                                        :

                                        <div className={`${classes.notApplicableStyle} ${!!info ? 'hidden' : ''}`}>
                                            <NoYet text="No charge rates available" />
                                        </div>
                                    }
                                </div>

                                {/*{!inputs.modifiers ? (*/}
                                {/*        <div className={`${classes.notApplicableStyle} ${!!info ? 'hidden' : ''}`}>*/}
                                {/*            <NoYet text="No charge rates available" />*/}
                                {/*        </div>*/}
                                {/*    )*/}
                                {/*    :*/}
                                {/*    (*/}
                                {/*        !activeModifiers?.length && (*/}
                                {/*            <NoYet text="No charge rates available" />*/}
                                {/*        )*/}
                                {/*    )*/}
                                {/*}*/}

                                {/*{*/}
                                {/*    <>*/}
                                {/*        <>*/}
                                {/*            {!!activeModifiers?.length ? activeModifiers.map((item, index) => {*/}

                                {/*                    const check = info?.chargeRates?.find((i) => i?.id === item?.id);*/}

                                {/*                    console.log(check, 'check');*/}
                                {/*                    const modifierButnStyle = `${classes.availableModfier}*/}
                                {/*                ${modCheck.includes(index) ? 'checked' : modifierExists ? 'chosen' : ''}`;*/}
                                {/*                    if (item?.status !== false) {*/}
                                {/*                        return (*/}
                                {/*                            <button*/}
                                {/*                                key={index}*/}
                                {/*                                className={modifierButnStyle}*/}
                                {/*                                onClick={() => onModifier(index)}*/}
                                {/*                                disabled={check*/}

                                {/*                                    // info?.chargeRates?.includes(item?.id)*/}
                                {/*                                    // modifierExists*/}
                                {/*                                }*/}
                                {/*                            >*/}
                                {/*                                {item.name}*/}
                                {/*                            </button>*/}
                                {/*                        );*/}
                                {/*                    }*/}
                                {/*                })*/}
                                {/*                : null}*/}
                                {/*        </>*/}
                                {/*    </>*/}
                                {/*}*/}
                            </div>
                        )}
                    </div>
                </div>
                <ErrMessage text={authServiceDefaultErrorText} />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChangeTotal}
                    value={inputs.totalUnits === 0 ? '0' : inputs.totalUnits}
                    label={'Total Available Units*'}
                    name="totalUnits"
                    typeError={error === 'totalUnits' ? `Available units ${ErrorText.isRequired}` : ''}
                />
                {error !== 'totalUnits' && <p className={classes.maxList}>Max 10,000</p>}
            </div>

            <CreateChancel
                loader={!!loader.length}
                create={!!info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={close}
                buttonWidth="100%"
            />

        </div>
    );
};
