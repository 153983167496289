import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuControlPanel } from './fragments';
import { SpeedDial } from '@material-ui/lab';
import { useWidth } from 'utils';
import { SectionBox } from './sectionBox';
import { helpCenterContent, helpCenterMenu } from './fragments/constants';
import { Svg } from 'assets';

export const HelpCenterPage = () => {
    const [open, setOpen] = useState(false);
    const width = useWidth();
    const contentRef = useRef(null);
    const history = useHistory();
    const info = history?.location?.state;


    useEffect(() => {
        contentRef.current?.scrollTo(0, 0);
    }, [info?.menu, info?.subMenu]);

    return (
        <div className='help-center-page-wrapper'>
            {width > 767 &&
                <MenuControlPanel
                    menu={helpCenterMenu}
                />
            }

            <div className='help-center-content' ref={contentRef}>
                {/*<CustomBreadcrumbs*/}
                {/*    className={'help-center-breadcrumbs'}*/}
                {/*    parent={'Help Center'}*/}
                {/*    child={'Control Panel'}*/}
                {/*    parentLink={'/helpCenter'}*/}
                {/*/>*/}

                <SectionBox content={helpCenterContent?.[info?.menu]?.[info?.subMenu]} />

                {width < 768 &&
                    <div className='content-header' onClick={() => {
                        history.push('/helpCenter');
                    }}>
                        <div className='icon-help-text'>
                            <Svg.BookHelpSvg className='MenuControlPanel-header-icon' />
                            <p className='text-help'>
                                Help
                            </p>
                        </div>

                        <p className='text-description'>
                            Articles for Control Panel
                        </p>

                        <SpeedDial
                            ariaLabel='SpeedDial basic example'
                            sx={{ position: 'absolute', bottom: 16, right: 16 }}
                            // icon={<MoreHorizIcon style={{ color: open ? "white" : "white", fontSize: "32px" }} />}
                            // openIcon={<MoreHorizIcon />}
                            onClose={(e) => {
                                e.stopPropagation();
                                setOpen(false);
                            }
                            }
                            onOpen={(e) => {
                                e.stopPropagation();
                                setOpen(true);
                            }}
                            open={open}
                        >
                            {open &&
                                <MenuControlPanel
                                    menu={helpCenterMenu}
                                />
                            }
                        </SpeedDial>
                    </div>
                }


                <div className='help-center-footer'>
                    <div>
                        <p className='give-feedback'>
                            GIVE FEEDBACK
                        </p>

                        <p className='was-this'>
                            Was this resource helpful?
                        </p>

                        <div className='feedback-svg-wrapper'>
                            <div className='feedback-svg-block'>
                                👍
                            </div>

                            <div className='feedback-svg-line' />


                            <div className='feedback-svg-block'>
                                👎
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

