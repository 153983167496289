import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { AddButton, AddCircleBlue, CreateChancel, CustomDelete, Loader, TextRow, UploadModal } from 'components';
import { FindLoad, FindSuccessItem, Images, useModal } from 'utils';
import { noteActions } from 'store';
import { noteStyles } from './styles';

const GET_ACTION_TYPE = 'GET_GLOBAL_NOTES';
export const MoteDrawerModal = ({ item, getNotes }) => {
    const userInfo = JSON.parse(localStorage.getItem('wellUserInfo'));
    const notes = useSelector((state) => state.note.notes);
    const classes = noteStyles();
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [addNote, setAddNote] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [inputs, setInputs] = useState({});
    const [selectedNote, setSelectedNote] = useState('');
    const inputRef = useRef();
    const textAreaRef = useRef();
    const ACTION_TYPE = inputs?.id ? 'EDIT_GLOBAL_NOTE' : 'CREATE_GLOBAL_NOTE';
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const loadList = FindLoad(GET_ACTION_TYPE);
    const { open, close } = useModal();
    const reversedNotes = notes?.reduce((acc, item) => [item, ...acc], []);

    useEffect(() => {
        if(notes?.length && !selectedNote) {
            setSelectedNote(notes[0]);
        }
    }, [notes]);

    useEffect(() => {
        if (success?.type === ACTION_TYPE && addNote) {
            setAddNote(false);
            getNotes('noLoad');
        }
        if (success?.type === ACTION_TYPE && selectedNote) {
            getNotes('noLoad');
        }
    }, [success]);

    const handleSubmit = () => {
        if (inputs?.subject && inputs?.text) {
            const noteData = {
                subject: inputs?.subject,
                text: inputs?.text,
                resource: item?.id,
                onModel: 'Appt',
            };
            if (inputs?.id) {
                const editParams = {
                    ...selectedNote,
                };
                editParams.subject = inputs?.subject;
                editParams.text = inputs?.text;
                setSelectedNote(editParams);

                dispatch(noteActions.editGlobalNote(item?.id, inputs?.id, noteData, 'Appt'));
            } else {
                dispatch(noteActions.createGlobalNote(noteData));
            }
        } else {
            if (!inputs?.subject) {
                inputRef.current.focus();
            }
            if (inputs?.subject && !inputs?.text) {
                textAreaRef.current.focus();
            }
        }
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleCancel = () => {
        setInputs({});
        setAddNote(false);
    };

    const handleAddNote = () => {
        setAddNote(true);
        setEditNote(false);
        setInputs({});
        setSelectedNote('');
    };

    const handleEditNote = () => {
        setEditNote(true);
        setAddNote(true);
        setInputs({
            ...selectedNote,
        });
    };

    const noteLeftSide = () => {
        if (addNote) {
            return (
                <div className={classes.noteLetSide}>
                    <input
                        ref={inputRef}
                        onChange={handleChange}
                        value={inputs?.subject ? inputs?.subject : ''}
                        name={'subject'}
                        type="text"
                        placeholder={'Untitled'}
                        className="note-title-input"
                        maxLength={100}
                    />

                    <div className={classes.noteSubjectBox}>
                        <div className={classes.characters}>
                            <p>{`${inputs?.subject?.length ? inputs?.subject?.length : 0}/100 characters`}</p>
                        </div>
                        <textarea
                            ref={textAreaRef}
                            onChange={handleChange}
                            value={inputs?.text ? inputs?.text : ''}
                            name={'text'}
                            placeholder={'Provide details for your note...'}
                            className={classes.noteSubjectArea}
                            maxLength={10000}
                        />
                    </div>
                    <CreateChancel
                        justifyContent={'flex-end'}
                        alignItems={'flex-end'}
                        loader={!!loader.length}
                        create={'Save'}
                        chancel={'Cancel'}
                        onCreate={handleSubmit}
                        onClose={handleCancel}
                        buttonWidth="93px"
                    />
                </div>
            );
        }

        if (notes?.length && !selectedNote && !addNote) {
            return (
                <div className={classes.noteLetSide}>
                    <img className={classes.noteImage} src={Images.notes} alt="icon" />
                    <p className={classes.noteInformationTitle} style={{ margin: '16px 0 8px' }}>Select a note to
                        view</p>
                    <p className={classes.noteInformationSubject}>Choose a note from the list on the left to view its
                        contents, <br /> or create a new note to add to your collection.</p>
                </div>
            );
        }

        if (!notes?.length && !addNote) {
            return (
                <div className={classes.noteLetSide} style={{ background: 'transparent', height: '200px' }}>
                    <img className={classes.noteImage} src={Images.notes} alt="icon" />
                    <p className={classes.noteInformationTitle}>{`No notes available for this Appointment.`}</p>
                    <AddButton
                        Icon={true}
                        customIcon={Images.addCircle}
                        text={'Add Note'}
                        handleClick={handleAddNote}
                    />
                </div>
            );
        }

        if (selectedNote) {
            return (
                <div className={classes.noteLetSide} style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div className="space-between-align-start">
                        <p className={classes.selectedNoteTitle}>{selectedNote?.subject}</p>
                        {selectedNote?.creatorId === userInfo?.id &&
                            <div className="flex-align-center" style={{ gap: 8 }}>
                                <button
                                    className="button-reset"
                                    onClick={() => open(
                                        <CustomDelete
                                            info="Are you sure you want to delete this note?"
                                            text="Once deleted, this note will be permanently lost."
                                            afterSuccess={() => {
                                                setSelectedNote('');
                                                getNotes('noLoad');
                                            }}
                                            handleDel={() => dispatch(noteActions.deleteGlobalNote(selectedNote?.id, 'Appt'))}
                                            handleClose={close}
                                            actionType={'DELETE_GLOBAL_NOTE'}
                                        />,
                                    )}
                                >
                                    <img src={Images.remove} alt="delete" />
                                </button>
                                <button className="button-reset" onClick={handleEditNote}>
                                    <img src={Images.edit} alt="edit" />
                                </button>
                            </div>
                        }
                    </div>
                    <div className={classes.noteInformationBox}>
                        <div className={classes.noteInformation}>
                            <img src={Images.noteEdit} alt="icon" />
                            <p>Created
                                By: <span>{selectedNote?.creatorFullName ? selectedNote?.creatorFullName : 'Not Set'}</span>
                            </p>
                        </div>
                        <div className={classes.noteInformation}>
                            <img src={Images.noteCalendar} alt="icon" />
                            <p>
                                Created Date: <span>{moment.utc(selectedNote?.createdAt).format('MM/DD/YYYY')}</span>
                            </p>
                        </div>
                        <div className={classes.noteInformation}>
                            <img src={Images.noteClock} alt="icon" />
                            <p>Created Time: <span>{moment.utc(selectedNote?.createdAt).format('LT')}</span></p>
                        </div>
                    </div>
                    <p className={classes.noteSubjectText}>
                        {selectedNote?.text}
                    </p>
                </div>
            );
        }

        if (!notes?.length && !addNote) {
            return (
                <div className={classes.noteLetSide}>
                    <img className={classes.noteImage} src={Images.notes} alt="icon" />
                    <p className={classes.noteInformationTitle}>{`No notes available for this Appointment.`}</p>
                    <AddButton
                        Icon={true}
                        customIcon={Images.addCircle}
                        text={'Add Note'}
                        handleClick={handleAddNote}
                    />
                </div>
            );
        }
    };

    const handleSelectNote = (item) => {
        setSelectedNote(item);
        setAddNote(false);
    };

    if (loadList?.length) {
        return <Loader height="300px" />;
    }

    return (
        <div style={{ fontFamily: 'Open Sans, sans-serif', wordWrap: 'break-word' }}>
            <div className={classes.noteModalBox} style={{ padding: 0 }}>
                <div className="flex-end">
                    {!!notes?.length &&
                        <AddButton
                            Icon={true}
                            customIcon={Images.addCircle}
                            text={'Add Note'}
                            handleClick={handleAddNote}
                        />
                    }
                </div>
                <div className={classes.nodeModalBody}>
                    {!!notes?.length &&
                        <div className={classes.userNotes}>
                            {addNote && !editNote &&
                                <div
                                    style={{ background: '#F8FAFB' }}
                                    className={classes.noteBox}
                                >
                                    <p className={classes.noteTitle}>
                                        {inputs?.subject}
                                    </p>
                                    <p className={classes.noteInfo}>
                                        {moment().format('LT')}
                                    </p>
                                </div>
                            }
                            {!!notes?.length &&
                                <div className={classes.reverse}>
                                    {reversedNotes?.map((i, j) => (
                                        <div
                                            style={
                                                selectedNote?.id === i?.id ?
                                                    {
                                                        background: 'rgba(235, 242, 253, 0.50)',
                                                        border: '1px solid rgba(208, 213, 221, 0.25)',
                                                    }
                                                    :
                                                    {
                                                        background: 'transparent',
                                                        border: '1px solid rgba(208, 213, 221, 0.50)',
                                                    }
                                            }
                                            className={classes.noteBox} key={j} onClick={() => handleSelectNote(i)}
                                        >
                                            <p
                                                style={{ color: selectedNote?.id === i?.id ? '#347AF0' : '#4B5C68' }}
                                                className={classes.noteTitle}>{i?.subject}</p>
                                            <p className={classes.noteSubject}>
                                                {i?.description}
                                            </p>

                                            <div className={classes.noteInfo}>
                                                <div style={{ width: '100%' }}>
                                                    <p>
                                                        <b>Date:&nbsp;</b>
                                                        {moment.utc(i?.created).format('MM/DD/YYYY')}
                                                    </p>
                                                    <p>
                                                        <b>Time:&nbsp;</b>
                                                        {moment.utc(i?.created).format('LT')}
                                                    </p>
                                                    <p style={{ width: '100%' }}>
                                                        <b>By:&nbsp;</b>
                                                        <TextRow name={i?.creatorFullName} textWidth={7} />
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }

                    <div style={{ width: '100%' }}>
                        {noteLeftSide()}
                    </div>
                </div>
            </div>
        </div>
    );
};