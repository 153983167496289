import React, { useContext, useEffect, useState } from 'react';
import { navBarStyles } from './style';
import clsx from 'clsx';
import { CustomBreadcrumbs, SearchInput } from 'components';
import { UserInfo } from './userInfo';
import { AppBar, Toolbar } from '@material-ui/core';
import { DrawerContext, filterFromTable, getMenuTitle, SaveParams } from 'utils';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

export const TopBar = () => {
    const classes = navBarStyles();
    const url = window.location.pathname;
    const menuTittle = getMenuTitle(url);
    const { open } = useContext(DrawerContext);
    const location = useLocation();
    const originalPath = location?.pathname?.split('/')[1];

    const { staffGeneral, fsData, client } = useSelector((state) => ({
        staffGeneral: state.admins.adminInfoById,
        fsData: state.fundingSource.fundingSourceItem,
        client: state.client.clientItemInfo,
    }));

    const renderLinks = () => {
        if (originalPath === 'fundingSource') {
            return { parent: 'Funding Source', child: fsData?.name, parentLink: '/fundingSource' };
        }
        if (originalPath === 'staff') {
            return {
                parent: 'Staff',
                child: staffGeneral?.firstName ? `${staffGeneral?.firstName} ${staffGeneral?.lastName}` : null,
                parentLink: '/staff',
            };
        }
        if (originalPath === 'client') {
            return {
                parent: 'Client',
                child: client?.firstName ? `${client?.firstName} ${client?.lastName}` : null,
                parentLink: '/client',
            };
        }
        if (originalPath === 'claim') {
            return { parent: 'Pending Claims', child: 'Claim Details', parentLink: '/pendingClaims' };
        }
        if (originalPath === 'billedClaim') {
            return { parent: 'Billed Claims', child: 'Claim Details', parentLink: '/billedClaims' };
        }
        if (originalPath === 'invoice') {
            return { parent: 'Invoices', child: 'Invoice Details', parentLink: '/invoices' };
        }
        if (originalPath === 'generateClaim') {
            return { parent: 'Pending Claims', child: 'Generate Claims', parentLink: '/pendingClaims' };
        }
        if (originalPath === 'generateInvoice') {
            return { parent: 'Invoices', child: 'Generate Invoice', parentLink: '/invoices' };
        }
        if (originalPath === 'timesheet') {
            return { parent: 'Active Payroll', child: 'Payroll Details', parentLink: '/activePayroll' };
        }
        if (originalPath === 'processed') {
            return { parent: 'Processed Payroll', child: 'Payroll Details', parentLink: '/processedPayroll' };
        }
    };

    const [backSearched, setBackSearched] = useState({ searchKey: '', searchValue: '' });
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (info?.searchKey) {
            setBackSearched({
                searchKey: info?.searchKey,
                searchValue: info?.searchValue,
            });
        } else {
            setBackSearched({
                searchKey: '',
                searchValue: '',
            });
        }
    }, [info]);

    const debounced = useDebouncedCallback((name, value, searchKey) => {
            const params = {
                ...info,
            };
            params.limit = 100;
            params.skip = 0;
            params.page = 1;
            if (value) {
                params.searchKey = searchKey;
                params.searchValue = value;
            } else {
                delete params.searchKey;
                delete params.searchValue;
            }
            SaveParams(history, params);
        },
        1000,
        { maxWait: 2000 },
    );

    const handleChange = (e, searchKey) => {
        // if(pendingClaims)
        if (searchKey) {
            setBackSearched({
                searchKey: searchKey,
                searchValue: e.target.value,
            });
            debounced(e.target.name, e.target.value, searchKey);
        }
    };

    const checkSearch = () => {
        return location?.pathname === '/' ||
        location?.pathname === '/schedule' ||
            location?.pathname === '/pendingClaims' ||
            location?.pathname === '/billedClaims' ||
            location?.pathname === '/invoices' ||
            location?.pathname === '/claimPayments';
    };

    const renderSearchPlaceholder = () => {
        if (location?.pathname === '/schedule' || location?.pathname === '/') {
            return 'Search by Appt ID';
        }
        if (location?.pathname === '/pendingClaims' || location?.pathname === '/claimPayments') {
            return 'Search by Claim, or Bill ID ';
        }
        if (location?.pathname === '/billedClaims') {
            return 'Search by Batch, Claim, or Bill ID ';
        }
        if (location?.pathname === '/invoices') {
            return 'Search by Invoice, or Bill ID';
        }
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
        >
            <Toolbar className={classes.headerContent}>
                <div className={classes.Toolbar}>
                    <div className={open === true ? classes.openToolbar : classes.closeToolbar}>
                        {menuTittle === 'detail' ?
                            renderLinks()?.child ?
                                <CustomBreadcrumbs
                                    parent={renderLinks()?.parent}
                                    child={renderLinks()?.child}
                                    parentLink={renderLinks()?.parentLink}
                                /> : null
                            :
                            <p className={classes.getMenuTitle}>{menuTittle}</p>
                        }
                    </div>

                    <div className={classes.userActionsBoxStyle}>
                        {checkSearch() &&
                            <SearchInput
                                onSearchInputChange={(e) => handleChange(e, 'DISPLAY_ID')}
                                searchInputValue={backSearched?.searchValue}
                                searchInputPlaceholder={renderSearchPlaceholder()}
                            />
                        }
                        <UserInfo />
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};
