import moment from 'moment/moment';
import { formatAMPMNeW, Images, PermissionList, RolePermission } from '../../../../../utils';
import React from 'react';
import { billStatuses, SERVICE } from '../../../constants';
import { SimpleTooltip } from '../../../../../components';
import toolInfoIcon from '../../../../../assets/images/icons/toolInfoIcon.svg';


export const apptDetailStatus = (status, item, fromAppt) => {
    switch (status) {
        case 'NOTRENDERED':
            return <div className="appt-status-card appt-not-rendered">
                <div />
                Not Rendered </div>;
        case 'RENDERED':
            return <div className="appt-status-card appt-rendered">
                <div />
                Rendered </div>;
        case 'CANCELLED':
            return <div className="flex-align-center" style={{ gap: '8px' }}>
                <div className="appt-status-card appt-cancelled">
                    <div />
                    Cancelled
                </div>
                {item?.cancelReason && !fromAppt &&
                    <SimpleTooltip title={<p>{item?.cancelReason}</p>} placement="right-center" arrow>
                        <div className="canceled-reason">
                            <p>see reason</p>
                            <img src={Images.toolInfoIcon} alt="icon" />
                        </div>
                    </SimpleTooltip>
                }
            </div>;
        case 'PENDING':
            return item?.type === SERVICE ?
                <div className="appt-status-card service-appt-pending">
                    <div />
                    Not Rendered
                </div>
                :
                <div className="appt-status-card appt-pending">
                    <div />
                    Pending </div>;
        case 'COMPLETED':
            return item?.type === SERVICE ?
                <div className="appt-status-card service-appt-completed">
                    <div />
                    {`Rendered ${item?.billStatus === billStatuses.NOT_BILLED ? `(Not Billed)` : item?.billStatus === billStatuses.BILLED ? `(Billed)` : ''}`}
                </div>
                :
                <div className="appt-status-card appt-completed">
                    <div />
                    Completed
                </div>;
        default:
            return { color: '', text: '' };
    }

    // switch (status) {
    //     case 'NOTRENDERED':
    //         return { color: '#6FD231', background: '#F1FAEA', text: 'Not Rendered' };
    //     case 'RENDERED':
    //         return { color: '#2A8E6D', background: '#EAF4F0', text: 'Rendered' };
    //     case 'CANCELLED':
    //         return { color: '#A3B2BD',  background: '#EAEAEA', text: 'Cancelled' };
    //     case 'PENDING':
    //         return { color: '#347AF080', background: '#EBF2FD',  text: 'Pending' };
    //     case 'COMPLETED':
    //         return { color: '#347AF0', background: '#EBF2FD', text: 'Completed' };
    //     default:
    //         return { color: '', text: '' };
    // }
};

export const apptTableDetailStatus = (status, item) => {
    switch (status) {
        case 'NOTRENDERED':
            return <div className="appt-status-card appt-not-rendered">
                <div />Not Rendered
            </div>;
        case 'RENDERED':
            return <div className="appt-status-card appt-rendered">
                <div />Rendered
            </div>;
        case 'CANCELLED':
            return <div className="flex-align-center" style={{ gap: '8px' }}>
                <div className="appt-status-card appt-cancelled">
                    <div />Cancelled
                </div>
            </div>;
        case 'PENDING':
            return item?.type === SERVICE ?
                <div className="appt-status-card service-appt-pending">
                    <div />Not Rendered
                </div>
                :
                <div className="appt-status-card appt-pending">
                    <div />Pending
                </div>;
        case 'COMPLETED':
            return item?.type === SERVICE ?
                <div className='appt-service-bill-wrapper'>
                <div className="appt-status-card service-appt-completed">
                    <div />Rendered
                </div>
                    <div className='service-appt-completed-bill'>
                        {item?.billStatus === billStatuses.NOT_BILLED ? 'Not Billed'
                            : item?.billStatus === billStatuses.BILLED ? `Billed` : ''
                        }

                    </div>
                </div>
                :
                <div className="appt-status-card appt-completed">
                    <div />
                    Completed
                </div>;
        default:
            return { color: '', text: '' };
    }

};

export const serviceApptDetailList = (item) => [
    { title: 'Appt ID:', value: item?.displayId },
    {
        title: 'Date/Time:', value: <div className="flex-align-center" style={{ gap: '8px' }}>
            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
            <p className="schedule-time">
                {`${formatAMPMNeW(item?.startTime)} - ${formatAMPMNeW(item?.endTime)}`}
            </p>
        </div>,
    },
    {
        title: 'Staff:', value: item?.staff ? `${item?.staff?.firstName} ${item?.staff?.lastName}` : null,
    },
    {
        title: 'Client:',
        value: item?.client ? `${item?.client?.code ? `(${item?.client.code})` : ''} ${item?.client?.firstName} ${item?.client?.lastName}` : null,
    },
    {
        title: 'Service:', value: item?.authorizedService?.service?.cptCode,
    },
];
export const apptDetailList = (item) => [
    { title: 'Appt ID:', value: item?.displayId },
    {
        title: 'Date/Time:', value: <div className="flex-align-center" style={{ gap: '8px' }}>
            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
            <p className="schedule-time">
                {`${formatAMPMNeW(item?.startTime)} - ${formatAMPMNeW(item?.endTime)}`}
            </p>
        </div>,
    },
];

export const apptDetailTabsLabels = [
    { label: 'Details' },
    { label: 'Links' },
    { label: 'Notes' },
    RolePermission([PermissionList.APPT_FILE_READ?.code, PermissionList.APPT_FILE_MANAGE?.code]) &&
    { label: 'Files' },
    RolePermission([PermissionList.APPT_HISTORY_READ?.code]) &&
    { label: 'History' },
];

export const linksNotYet = {
    title: 'No Links Yet',
    // subTitle: 'Upload files to store relevant documents here.',
    image: Images.notFile,
    height: '200px',
};