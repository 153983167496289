import { multiConverter } from './timeConvertot';
import moment from 'moment';

export { PermissionsList } from './permissionsList';
export { paginate, SavePage, SendPageSave, SaveParams } from './paginate';
export { filterByFirstName } from './filterFunctions';
export { activeInactive } from './activeInactive';
export { initAxiosInterceptors } from './axios';
export { ToastSuccess } from './toastSuccess';
export { ToastFail } from './toastFail';
export { FindSuccess, FindSuccessItem } from './findSuccess';
export { FindLoad } from './findLoad';
export { ActiveInactiveStatus } from './activeInactiveStatus';
export { ActiveInactiveStatusReverse } from './activeInactiveStatusReverse';
export { FindError, FindErrorItem } from './findError';
export { getPhoneErrorText } from './getPhoneErrorText';
export { isNotEmpty } from './isNotEmpty';
export { makeEnum } from './makeEnum';
export { transformPermission } from './transformPermissions';
export { getValTillTenDig } from './getValTillTenDig';
export { getLimitedVal } from './getLimitedVal';
export { manageStatus, manageType, manageStatusCircle } from './manageStatus';
export { getMenuTitle } from './getMenuTitle';
export { makeCapitalize, textResizer } from './makeCapitalize';
export { useWidth } from './useWidth';
export { hooksForTable } from './hooksForTable';
export { ImgUploader } from './uploadImages';
export { hooksForErrors } from './hooksForErrors';
export { addHiddenClass } from './addHiddenClass';
export { ssnActions } from './ssnActions';
export { createCodeFromName } from './createCodeFromName';
export { getSkipCount } from './getSkipCount';
export { useWeekInterval } from './useWeekInterval';
export { handleTooltip } from './handleTooltip';
export { getDisplayDependsOnWidth } from './getDisplayDependsOnWidth';
export { PascalCase } from './pascaleCase';
export { RolePermission, PermissionList } from './rolePermissions';
export { multiConverter } from './timeConvertot';
export { ScrollToTop } from './scrollToTop';
export { DownloadFile } from './downloadFile';
export { filterFromTable } from './filterFromTable';
export { InactivityHandler } from './inactivityHandler';
export { useScrollTop } from './useScrollTop';


export const convertHours = (unformattedHours) => {
    const minutes = unformattedHours * 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours.toString().padStart(2, '0')}h ${remainingMinutes.toString().padStart(2, '0')}m`;
};

export const convertToDateTime = (selectedTime) => {
    // Create a Moment.js object using the selected time
    const timeMoment = moment(selectedTime, 'HH:mm');
    // Set the timezone to UTC (Z)
    timeMoment.to('UTC');
    // Format the datetime string
    return timeMoment.format('YYYY-MM-DDTHH:mm:ssZ');
};

function tConvert(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        // time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join('');
}

export const formatAMPM = (originalTime) => {
    let hours = originalTime?.slice(11, 13);
    let minutes = originalTime?.slice(14, 16);
    let timeType = hours >= 12 ? 'PM' : 'AM';
    const convertedTime = tConvert(`${hours}:${minutes}`);
    return `${convertedTime} ${timeType}`;
};

export const formatAMPMNeW = (originalTime) => {
    let hours = originalTime?.slice(11, 13);
    let minutes = originalTime?.slice(14, 16);
    let timeType = hours >= 12 ? 'p' : 'a';
    const hours12 = hours % 12 || 12;
    const convertedTime = tConvert(`${hours12}:${minutes}`);
    return `${convertedTime}${timeType}`;
};



export function formatMiles(number) {
    const num = Number(number)
    return num ? num.toLocaleString('en-US') : 'Not Set';
}

export function formatDuration(duration) {
    // Split the duration into hours and minutes
    const [hours, minutes] = duration.split('h');

    // Trim any spaces from minutes in case there are any
    const trimmedMinutes = minutes.trim();

    // Return the formatted string with a space before 'h'
    return `${hours}h ${trimmedMinutes}`;
}

export const  TimeIsGreat = (startTime, endTime) => {
    if(startTime && endTime) {
        const start = new Date();
        const end = new Date();

        const [startHour, startMinute] = startTime.split(":");
        start.setHours(parseInt(startHour));
        start.setMinutes(parseInt(startMinute));

        // Set the hours and minutes for end time
        const [endHour, endMinute] = endTime.split(":");
        end.setHours(parseInt(endHour));
        end.setMinutes(parseInt(endMinute));

        if (end > start) {
            return true
        } else {
            return false
        }
    }else{
        return false
    }

}