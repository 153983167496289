import { call, put, takeLatest } from 'redux-saga/effects';
import { appointmentService } from './appointment.service';
import {
    APPEND_SIGNATURE_TO_APPMT, APPOINTMENT_FROM_TEMPLATE,
    APPOINTMENT_REPEAT,
    CANCEL_APPT,
    CHANGE_APPOINTMENT_LOCK_UNLOCK,
    CONNECT_RECONNECT_TEMPLATE,
    CREATE_APPOINTMENT,
    DELETE_ALL_APPT,
    DELETE_APPOINTMENT, DELETE_APPT_DOCUMENT,
    EDIT_APPOINTMENT, EDIT_APPT_DOCUMENT,
    GET_APPOINTMENT,
    GET_APPOINTMENT_BY_ID,
    GET_APPOINTMENT_BY_ID_SUCCESS, GET_APPOINTMENT_INFORMATION, GET_APPOINTMENT_INFORMATION_SUCCESS,
    GET_APPOINTMENT_SUCCESS, GET_APPT_DOCUMENT, GET_APPT_DOCUMENT_SUCCESS,
    GET_APPT_STAFFS, GET_APPT_STAFFS_SUCCESS,
    GET_CLIENT_STAFFS,
    GET_CLIENT_STAFFS_SUCCESS,
    MANAGE_NOTES,
    REMOVE_APPT_FROM_BILL,
    SET_APPOINTMENT_STATUS,
    UN_RENDER_APPT, UPLOAD_APPT_DOCUMENT,
} from './appointment.type';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

/** Create, Edit Appointment */
function* createAppointmentSaga(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        const res = yield call(appointmentService.createAppointmentService, action?.payload?.body);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: res.data?.id || res.data?._id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editAppointmentSaga(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.editAppointmentService, action?.payload?.body, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));

        if(action?.payload?.id){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
        // yield put({
        //     type: GET_APPOINTMENT_BY_ID,
        //     payload: { id: action.payload.id },
        // });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editApptLockUnlock(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.editApptLockUnlockService, action?.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));

        if(action?.payload?.apptId){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.apptId);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        // yield put(httpRequestsOnErrorsActions.appendError('APPOINTMENT_OVERLAPPING_TOASTER', err?.data?.message));
        // yield put(httpRequestsOnErrorsActions.appendError('APPOINTMENT_OVERLAPPING', err?.data?.message));
    }
}

/** end */

/** Get Appointment */
function* getAppointmentSaga(action) {
    if (action.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(appointmentService.getAppointmentService, action?.payload?.params);
        yield put({
            type: GET_APPOINTMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_APPOINTMENT_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getAppointmentByIdSaga(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_APPOINTMENT_BY_ID_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put({
            type: GET_APPOINTMENT_BY_ID_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}


function* getAppointmentInformation(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(appointmentService.getAppointmentInformationService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_APPOINTMENT_INFORMATION_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put({
            type: GET_APPOINTMENT_INFORMATION_SUCCESS,
            payload: null,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

/** end */

/** Delete Appointment */
function* deleteAppointmentSaga(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.deleteAppointmentService, action.payload.id);
        // yield put({
        //     type: DELETE_APPOINTMENT_SUCCESS,
        //     payload: action.payload.id,
        // });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action?.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        // yield put({ type: GET_APPOINTMENT, payload: { load: 'noLoad', params: action?.payload?.params } });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** end */

/** Appointment Status */
function* setAppointmentStatusSaga(action) {
    const loaderType =
        action?.payload?.statusName === 'complete' ? 'SET_APPOINTMENT_STATUS_COMPLETE' :
            action?.payload?.statusName === 'render' ? 'SET_APPOINTMENT_STATUS_COMPLETE' :
                'SET_APPOINTMENT_STATUS_CANCEL';
    yield put(httpRequestsOnLoadActions.appendLoading(loaderType));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.setAppointmentStatusService, action?.payload?.id, action?.payload?.statusName, action?.payload?.reason);
        yield put(httpRequestsOnLoadActions.removeLoading(loaderType));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        if(action?.payload?.id){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(loaderType));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* removeApptFromBill(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.removeApptFromBillService, action?.payload?.id, action?.payload?.reason );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        if(action?.payload?.id){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* unRenderAppt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.unRenderApptService, action?.payload?.id );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        if(action?.payload?.id){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** end */

/** Appointment Repeat */
function* appointmentRepeatSaga(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.appointmentRepeatService, action.payload.body,);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        if(action?.payload?.id){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* appointmentFromTemplateSaga(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.appointmentRepeatService, action.payload.body,);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** end */

function* appendSignatureToAppmt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(
            appointmentService.appendSignatureToAppmtService,
            action.payload.id,
            action.payload.signature,
        );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: action.payload.id, load: 'noLoad' },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getClientStaffs(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(appointmentService.getClientStaffsService, action?.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        // yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_STAFFS_SUCCESS,
            payload: res?.data,
        });
    } catch (err) {
        yield put({
            type: GET_CLIENT_STAFFS_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* manageNotes(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        const res = yield call(appointmentService.manageNotesService, action.payload.id, action.payload.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* connectReconnect(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.connectReconnectService, action?.payload?.id, action?.payload?.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));

        if(action?.payload?.id){
            const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put({
                type: GET_APPOINTMENT_BY_ID_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* cancelAppt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.cancelApptService, action?.payload?.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteAllAppt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.deleteAllApptService, action?.payload?.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getApptStaffs(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(appointmentService.getApptStaffsService);
        yield put({
            type: GET_APPT_STAFFS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}


/** Claim Documents */
function* uploadApptDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(appointmentService.uploadService, action.payload.file, action.payload.id);
        yield put({
            type: GET_APPT_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editApptDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(appointmentService.editService, action?.payload);
        yield put({
            type: GET_APPT_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getApptDocument(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(appointmentService.getFilesService, action.payload.id);
        yield put({
            type: GET_APPT_DOCUMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteApptDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(appointmentService.deleteFilesService, action.payload.apptId, action.payload.id);
        yield put({
            type: GET_APPT_DOCUMENT,
            payload: { id: action?.payload?.apptId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

export const watchAppointments = function* watchAppointmentsSaga() {
    /** Create, Edit Appointment */
    yield takeLatest(CREATE_APPOINTMENT, createAppointmentSaga);
    yield takeLatest(EDIT_APPOINTMENT, editAppointmentSaga);
    yield takeLatest(CHANGE_APPOINTMENT_LOCK_UNLOCK, editApptLockUnlock);
    /** end */

    /** Get Appointment */
    yield takeLatest(GET_APPOINTMENT, getAppointmentSaga);
    yield takeLatest(GET_APPOINTMENT_BY_ID, getAppointmentByIdSaga);
    yield takeLatest(GET_APPOINTMENT_INFORMATION, getAppointmentInformation);
    /** end */

    /** Delete Appointment */
    yield takeLatest(DELETE_APPOINTMENT, deleteAppointmentSaga);
    /** end */

    /** Appointment Status */
    yield takeLatest(SET_APPOINTMENT_STATUS, setAppointmentStatusSaga);
    yield takeLatest(REMOVE_APPT_FROM_BILL, removeApptFromBill);
    yield takeLatest(UN_RENDER_APPT, unRenderAppt);
    /** end */

    /** Appointment Repeat */
    yield takeLatest(APPOINTMENT_REPEAT, appointmentRepeatSaga);
    yield takeLatest(APPOINTMENT_FROM_TEMPLATE, appointmentFromTemplateSaga);
    /** end */

    yield takeLatest(APPEND_SIGNATURE_TO_APPMT, appendSignatureToAppmt);
    yield takeLatest(GET_CLIENT_STAFFS, getClientStaffs);
    yield takeLatest(MANAGE_NOTES, manageNotes);
    yield takeLatest(CONNECT_RECONNECT_TEMPLATE, connectReconnect);
    yield takeLatest(CANCEL_APPT, cancelAppt);
    yield takeLatest(DELETE_ALL_APPT, deleteAllAppt);

    yield takeLatest(GET_APPT_STAFFS, getApptStaffs);

    /** Appt Documents */
    yield takeLatest(UPLOAD_APPT_DOCUMENT, uploadApptDocument);
    yield takeLatest(EDIT_APPT_DOCUMENT, editApptDocument);
    yield takeLatest(GET_APPT_DOCUMENT, getApptDocument);
    yield takeLatest(DELETE_APPT_DOCUMENT, deleteApptDocument);
    /** End */

};
