import React from 'react';
import { useDispatch } from 'react-redux';
import { appointmentActions } from 'store';
import { CustomDelete, ToolInfo } from 'components';
import { Images, useModal, useSecondaryModal } from 'utils';
import { scheduleModalsStyle } from '../styles';

const CANCEL_ACTION_TYPE = 'CANCEL_APPT';
const DELETE_ACTION_TYPE = 'DELETE_ALL_APPT';
export const CancellingAppts = ({ apptDate, handleClose }) => {
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch();
    const { open, close } = useModal();
    const { closeSecondary } = useSecondaryModal();

    const closeAfterSuccess = () => {
        handleClose && handleClose();
        closeSecondary();
        close();
    };

    const handleCancel = () => {
        open(
            <CustomDelete
                info={'Confirm Cancel All Appointments'}
                text={'Cancelling all appointments associated with this template will remove the entire series. This action cannot be undone. Are you sure you want to proceed?'}
                innerText={'Yes, Cancel All'}
                handleDel={() => dispatch(appointmentActions.cancelAppt(apptDate?.template))}
                handleClose={close}
                actionType={CANCEL_ACTION_TYPE}
                afterSuccess={closeAfterSuccess}
            />,
        );
    };

    const handleDelete = () => {
        open(
            <CustomDelete
                info={'Confirm Delete All Appointments'}
                text={'Deleting all appointments associated with this template will remove the entire series. This action cannot be undone. Are you sure you want to proceed?'}
                innerText={'Yes, Delete All'}
                handleDel={() => dispatch(appointmentActions.deleteAllAppt(apptDate?.template))}
                handleClose={close}
                actionType={DELETE_ACTION_TYPE}
                afterSuccess={closeAfterSuccess}
            />,
        );
    };

    return (
        <div style={{ marginBottom: '24px' }}>
            <div className={classes.cancellingOptionBox}>
                <p className={classes.cancellingTitle}>Cancelling Option</p>
                <div className={classes.informationBox}>
                    <div className={classes.multipleServiceNote}>
                        <ToolInfo noTool={true} />
                        <p style={{ fontSize: 14 }}>
                            Triggering this action will cancel all pending appointments associated with this template.
                        </p>
                    </div>
                    <button onClick={handleCancel}>
                        <img src={Images.removeIcon} alt="icon" />
                        <p> Cancel All Appointments</p>
                    </button>
                </div>
            </div>

            <div className={classes.cancellingOptionBox}>
                <p className={classes.cancellingTitle}>Delete Option</p>
                <div className={classes.informationBox}>
                    <div className={classes.multipleServiceNote}>
                        <ToolInfo noTool={true} />
                        <p style={{ fontSize: 14 }}>
                            Triggering this action will delete all pending appointments associated with this template.
                        </p>
                    </div>
                    <button onClick={handleDelete}>
                        <img src={Images.removeIcon} alt="icon" />
                        <p> Delete All Appointments</p>
                    </button>
                </div>
            </div>
        </div>
    );
};