import React from "react";
import { ScheduleFragment } from "fragments";

export const Schedule = ({}) => {
   return (
      <div>
         <ScheduleFragment />
      </div>
   );
};
