import {
    createAppointment,
    editAppointment,
    getAppointment,
    getAppointmentById,
    deleteAppointment,
    setAppointmentStatus,
    appointmentRepeat,
    appendSignatureToAppmt,
    editAppointmentPosition,
    saveCurrentLocation,
    getStaffClients,
    removeStaffClients,
    changeApptLockUnlock,
    manageNotes,
    connectReconnect,
    cancelAppt,
    deleteAllAppt,
    removeApptFromBill,
    unRenderAppt,
    getApptStaffs,
    appointmentFromTemplate,
    uploadApptDocument,
    editApptDocument,
    getApptDocument,
    deleteApptDocument,
    getAppointmentInfo,
} from './appointment.action';
import moment from "moment";

export {appointmentReducer} from "./appointment.reducer";
export {watchAppointments} from "./appointment.saga";

export const appointmentActions = {
    /** Create, Edit Appointment */
    createAppointment,
    editAppointment,
    editAppointmentPosition,
    changeApptLockUnlock,
    /** end */

    /** Get Appointment */
    getAppointment,
    getAppointmentById,
    getAppointmentInfo,
    /** end */

    /** Delete Appointment */
    deleteAppointment,
    /** end */

    /** Appointment Status */
    setAppointmentStatus,
    removeApptFromBill,
    unRenderAppt,
    /** end */

    /** Appointment Repeat */
    appointmentRepeat,
    appointmentFromTemplate,
    /** end */

    appendSignatureToAppmt,
    saveCurrentLocation,
    getStaffClients,
    removeStaffClients,
    manageNotes,
    connectReconnect,
    cancelAppt,
    deleteAllAppt,

    getApptStaffs,
    /** Appt Documents */
    uploadApptDocument,
    editApptDocument,
    getApptDocument,
    deleteApptDocument,
    /** end */
};


export const filteredArray = (appointments) => {
    const arr = [];
    appointments.map((i) => i?.data?.forEach((k) => arr.push(k)));
    return arr;
}

export const handleReorderCategory = (body, id, fullList) => {
    const allList = [...fullList]
    let pos = fullList.map(val => val?.id).indexOf(id)
    allList[pos].startDate = moment.utc(body.startDate).format()
    allList[pos].startTime = moment.utc(body.startTime).format()
    allList[pos].endTime = moment.utc(body.endTime).format()
    return allList
};

export const deleteAppt = (list, id) => {
    return list?.filter((i) =>  (i?._id  || i?.id) !== id)
};
