import {
    DateRow,
    DisplayIdRow,
    DownloadLink,
    PriceRow,
    TextRow,
    UnitsRow,
    ApptFlags,
    SimpleTooltip,
} from 'components';
import React from 'react';
import { Images, PermissionList, RolePermission } from 'utils';
import { PENDING_CLAIM } from '../constants';

export const claimPaymentTableHead = [
    { name: 'id', title: 'ID', custom: false, noSearch: true, width: '100px' },
    { name: '', title: 'User name', custom: false, noSearch: true },
    { name: '', title: 'Created Date', custom: false, noSearch: true, width: '120px' },
    { name: '', title: 'Voided Date', custom: false, noSearch: true, width: '120px' },
    { name: '', title: 'Allowed AMT', custom: false, noSearch: true },
    { name: '', title: 'Deductible', custom: false, noSearch: true },
    { name: '', title: 'Coinsurance', custom: false, noSearch: true },
    { name: '', title: 'Copay', custom: false, noSearch: true },
    { name: '', title: 'Paid Amt', custom: false, noSearch: true },
    { name: '', title: 'Status', custom: false, noSearch: true },
];

export const claimPaymentTableBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId ? item?.displayId : item?.id} /> },
    {
        rowText: (item) => <TextRow
            name={item?.creator?.firstName ? `${item?.creator?.firstName} ${item?.creator?.lastName}` : 'N/A'} />,
    },
    { rowText: (item) => <DateRow date={item?.createdDate} /> },
    { rowText: (item) => <DateRow date={item?.voidedDate} /> },
    { rowText: (item) => <PriceRow info={item?.allowedAMT} /> },
    { rowText: (item) => <PriceRow info={item?.deductible} /> },
    { rowText: (item) => <PriceRow info={item?.coIns} /> },
    { rowText: (item) => <PriceRow info={item?.copay} /> },
    { rowText: (item) => <PriceRow info={item?.paidAMT} /> },
    {
        rowText: (item) => <p
            className={item?.status === 'ACTIVE' ? 'active-status' : 'inactive-status'}>{item?.status === 'ACTIVE' ? 'Active' : 'Voided'}</p>,
    },
];

export const billApptHead = (claimDetails, from) => [
    { name: '', title: 'ID', custom: false, noSearch: true },
    { name: '', title: 'DOS', custom: false, noSearch: true },
    { name: '', title: 'Units', custom: false, noSearch: true },
    { name: '', title: 'Signature', custom: false, noSearch: true },
    claimDetails?.status !== PENDING_CLAIM && RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) && from !== 'invoice' &&
    { name: '', title: ' ', custom: false, noSearch: true, width: '100px' },

];

export const billApptBody = (tableStyle, removeFromClaim, claimDetails, afterSuccess, from) => [
    {
        rowText: (item) =>
            <div className={'flex-align-center'} style={{ gap: 8 }}>
                {/*<ApptDetailRow name={item?.displayId ? item.displayId : 'N/A'} id={item?.id} afterSuccess={afterSuccess} />*/}
                <ApptFlags
                    incomplete={item?.incompleteUnitFlag}
                    removed={item?.removedFromClaimFlag}
                    updateFlag={item?.updateFlag}
                    singleAppt={true}
                    id={item?.displayId ? item.displayId : 'N/A'}
                />
            </div>,
    },
    { rowText: (item) => <DateRow date={item?.dos || item?.startTime} /> },
    { rowText: (item) => <UnitsRow info={item?.units} /> },
    {
        rowText: (item) => <div>
            {item?.signature?.id || item?.signature?._id ?
                <div className={tableStyle.linksWrapper}>
                    <div className={tableStyle.linksButtonWrapper}>
                        <a href={item?.signature?.url} target={'_blank'}>
                            <button>View</button>
                            <img src={Images.show} alt="icon" />
                        </a>
                    </div>
                    <div className={tableStyle.linksButtonWrapper}>
                        <DownloadLink
                            linkInnerText={'Download'}
                            file={item?.signature}
                            small={true}
                        />
                    </div>
                </div>
                : 'N/A'}
        </div>,
    },
    RolePermission([PermissionList.REMOVE_CLAIM_APPT?.code]) && from !== 'invoice' &&
    {
        button: (fn, item) => claimDetails?.status === 'PENDING' ? <div></div> : (
            <div>
                {item?.removedFromClaimFlag ?
                    <SimpleTooltip
                        title={<p>{'Appointment was removed from claim'}</p>}
                        placement="bottom"
                        arrow
                    >
                        <p style={{ color: '#E90715' }}>Removed</p>
                    </SimpleTooltip>
                    :
                    <button onClick={() => removeFromClaim(item)} className="remove-from-claim">Remove from
                        Claim</button>
                }
            </div>
        ),
    },
];


export const billInfoList = (billById) => {
    return [
        { title: 'DOS:', value: <DateRow date={billById?.dos} /> },
        { title: 'CPT Code:', value: billById?.cptCode },
        { title: 'Modifier:', value: billById?.chargeRateModifier },
        { title: 'Units:', value: <UnitsRow info={billById?.totalUnits} /> },
    ];
};

export const billDetailsList = (billById) => {
    return [
        {
            title: 'Diagnostic Pointers',
            value: billById?.diagnosticPointer?.length ?
                <TextRow name={billById?.diagnosticPointer?.map((i) => i).join(', ')} /> : 'N/A',
        },
        { title: 'Payor Total', value: <TextRow name={billById?.cptCode} /> },
        {
            title: 'Place of Service',
            value: <TextRow
                name={`${billById?.placeOfService} ${billById?.placeOfServiceCode ? `${billById?.placeOfServiceCode}:` : ''} ${billById?.posModifier ? `(${billById?.posModifier})` : 'N/A'}`} />,
        },
        { title: 'Payor Paid', value: <PriceRow info={billById?.payorPaid} /> },
        { title: 'Total Billed', value: <PriceRow info={billById?.totalBilled} /> },
        { title: 'Payor Balance', value: <PriceRow info={billById?.payorBalance} /> },
        { title: 'Allowed', value: <PriceRow info={billById?.allowedAMT} /> },
        { title: 'Client Total', value: <PriceRow info={billById?.clientTotal} /> },
        {
            title: 'Rendering Provider NPI Number',
            value: <TextRow name={billById?.renderingProvider ? billById?.renderingProvider : 'N/A'} />,
        },
        { title: 'Client Paid', value: <PriceRow info={billById?.clientPaid} /> },
        { title: '', value: ' ', background: 'white' },
        { title: 'Client Balance', value: <PriceRow info={billById?.clientBalance} /> },
    ];
};


