import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Colors,
    ErrorText,
    FindLoad,
    FindSuccess,
    FindSuccessItem,
    isNotEmpty,
    useGlobalTextStyles,
    useModal,
} from 'utils';
import { modalsStyle } from './styles';
import { MinLoader } from '../loader';
import { httpRequestsOnSuccessActions } from 'store';
import { UserTextArea } from '../inputs';


export const CustomDelByNote = ({
                                 text,
                                 info,
                                 handleDel,
                                 actionType,
                                 innerText = '',
                                 body,
                                 align,
                                 afterSuccess,
                                 color,
                                    inputLabel,
                                    areaStyles,
                                    required, successType
                             }) => {
    const classes = modalsStyle();
    const globalText = useGlobalTextStyles();
    const loader = FindLoad(actionType);
    const success = FindSuccessItem(successType ? successType : actionType);
    const dispatch = useDispatch();
    const { close } = useModal();
    const [comment, setComment] = React.useState('');
    const [error, setError] = React.useState('');

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
            setComment('');
            setError('');

            if (afterSuccess) {
                afterSuccess();
            }
        }
    }, [success]);

    const handleSubmit = () => {
        const checkRequired = required === false ? true : isNotEmpty(comment);

        if (checkRequired) {
            handleDel(comment);
        } else {
            setError('removeNote');
        }
    }

    return (
        <div className={classes.customDeleteModal}>
            <div style={align ? { textAlign: align } : {}}>
                <p className={globalText.modalTitle}>{info}</p>
                <p className={globalText.modalText}>{text}</p>
                {body && body}

                <div>
                    <UserTextArea
                        margin={'0'}
                        id={'comment'}
                        name={'comment'}
                        label={inputLabel}
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value)
                            error && setError('')
                        }}
                        max={100}
                        hasText={!!comment}
                        maxCharsLabel={`${comment.length}/100`}
                        typeError={error === 'removeNote' && `Comment ${ErrorText.isRequired}`}
                        areaStyles={areaStyles}
                    />
                </div>

                <div className={classes.buttons}>
                    <button className="close-btn-box" onClick={() => close()}>
                        Cancel
                    </button>
                    <button className={color === 'blue' ? 'confirm-btn' : "delete-btn-box"} onClick={handleSubmit}>
                        {loader?.length ? (
                            <MinLoader margin={'0'} color={Colors.TextWhite} />
                        ) : (
                            innerText || 'Delete'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};
