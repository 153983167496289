import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CreateChancel, DateInput, SelectTypeAutocomplete } from 'components';
import { useModal } from 'utils';
import { CsvService } from 'store';
import { FormControlLabel, Radio } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const GENERAL = 'GENERAL'
const DETAIL = 'DETAIL'
export const DownloadPendingClaims = ({ type }) => {
    const history = useHistory();
    const info = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const clientList = useSelector((state) => state.client.clientList);
    const adminsList = useSelector((state) => state.admins.adminsList);
    const [inputs, setInputs] = useState({});
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);
    const [generateType, setGenerateType] = useState(GENERAL);
    const { close } = useModal();

    useEffect(() => {
        if(info && Object.keys(info).length){
            const params = { }

            if (info.firstDate) {
                params.startDate = info?.firstDate;
            }
            if (info.lastDate) {
                params.endDate = info?.lastDate;
            }
            if (info.payer) {
                params.payer = info.payer;
            }
            if (info.client) {
                params.client = info.client;
            }
            if (info.staff) {
                params.staff = info.staff;
            }

            setInputs(params)
        }
    }, [info]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleExport = async () => {
        setLoad(true);
        let response = '';

        const params = {
            ...inputs
        };
        params.type = generateType;

        if(type === 'billedClaims'){
            params.status = 'OPEN';
        }else {
            params.status = 'PENDING';
        }

        try {
            response = await CsvService.pendingClaimCsv(params);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${generateType === GENERAL ? 'General' : 'Detail'}-${type}.csv`);
            document.body.appendChild(link);
            link.click();
            setLoad(false);
            close();
        } catch (e) {
            setLoad(false);
            close();
        }
    };

    const handleChangeType = (e) => {
        setGenerateType(e.target.value);
        setError(false);
    };


    return (
        <div className="download-payroll-csv-modal">
            <p className="download-modal-title">Download Billing CSV</p>
            <p className="download-modal-desc">
                Select filters for the billing data you wish to download. The fields will be pre-filled based on your
                current filters.
            </p>
            <div className="download-modal-body">

                <div className="download-1500form-modal-body flex-align-start" style={{gap: 16, width: '100%', margin:'32px 0'}}>
                    <div className={type === 'pdf' ? 'form-type-box-active' : 'form-type-box-passive'}
                         style={{ borderColor: error ? '#F07379' : '', width: '100%' }}
                    >
                        <FormControlLabel
                            onChange={handleChangeType}
                            name="generateType"
                            value={GENERAL}
                            label="General Report"
                            control={<Radio
                                style={{ color: generateType === GENERAL ? '#347AF0' : '#D0D5DD' }}
                                checked={generateType === GENERAL}
                            />}
                        />
                    </div>
                    <div className={type === 'tab' ? 'form-type-box-active' : 'form-type-box-passive'}
                         style={{ borderColor: error ? '#F07379' : '', width: '100%'  }}
                    >
                        <FormControlLabel
                            onChange={handleChangeType}
                            name="generateType"
                            value={DETAIL}
                            label="Detail Report"
                            control={<Radio
                                style={{ color: generateType === DETAIL ? '#347AF0' : '#D0D5DD' }}
                                checked={generateType === DETAIL}
                            />}
                        />
                    </div>
                </div>

                {type === 'billedClaims' ?
                    <SelectTypeAutocomplete
                        title={'Staff'}
                        name={'staff'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.staff}
                        list={adminsList?.staffs?.length ? adminsList?.staffs : []}
                        renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                        placeholder={'Select staff'}
                    />
                    :
                    <>
                        <SelectTypeAutocomplete
                            title={'Funding Source'}
                            name={'payer'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.payer}
                            list={fundingSourceList?.funders ? fundingSourceList?.funders : []}
                            renderValue={(i) => i?.name}
                            placeholder={'Select Funding Source'}
                        />
                        <SelectTypeAutocomplete
                            title={'Client'}
                            name={'client'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.client}
                            list={clientList?.clients ? clientList?.clients : []}
                            renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                            placeholder={'Select Client'}
                        />
                    </>
                }
                <div className="start-end-time-box">

                    <DateInput
                        name={'startDate'}
                        label={'Start Date'}
                        handleChange={handleChange}
                        value={inputs.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : null}
                        max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}
                    />
                    <DateInput
                        name={'endDate'}
                        label={'End Date'}
                        handleChange={handleChange}
                        value={inputs.endDate ? moment.utc(inputs.endDate).format('MM/DD/YYYY') : null}
                        min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}
                    />

                    {/*<ValidationInput*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    value={inputs?.startDate}*/}
                    {/*    type={'date'}*/}
                    {/*    label={'Start Date'}*/}
                    {/*    name="startDate"*/}
                    {/*    max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}*/}
                    {/*/>*/}
                    {/*<ValidationInput*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    value={inputs?.endDate}*/}
                    {/*    type={'date'}*/}
                    {/*    label={'End Date'}*/}
                    {/*    name="endDate"*/}
                    {/*    min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}*/}
                    {/*/>*/}
                </div>
            </div>

            <div>
                <CreateChancel
                    loader={load}
                    create={'Download CSV'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};