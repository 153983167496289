import { call, put, takeLatest } from "redux-saga/effects";
import { authService } from "./fundingSource.service";
import {
   CREATE_FUNDING_SOURCE,
   GET_FUNDING_SOURCE,
   GET_FUNDING_SOURCE_BY_ID,
   GET_FUNDING_SOURCE_BY_ID_SUCCESS,
   GET_FUNDING_SOURCE_SUCCESS,
   GET_FUNDING_SOURCE_SERVICE_BY_ID,
   GET_FUNDING_SOURCE_SERVICE_BY_ID_SUCCESS,
   CREATE_FUNDING_SOURCE_SERVICE_BY_ID,
   EDIT_FUNDING_SOURCE,
   EDIT_FUNDING_SOURCE_SERVICE,
   CREATE_FUNDING_MODIFIER,
   EDIT_FUNDING_MODIFIER,
   CHANGE_FUNDING_MODIFIER_STATUS,
   SET_STATUS,
   CHANGE_FUNDING_SOURCE_STATUS,
   EDIT_FS_DOCUMENT,
   UPLOAD_FS_DOCUMENT,
   GET_FS_DOCUMENT,
   DELETE_FS_DOCUMENT,
   GET_FS_DOCUMENT_SUCCESS,
   GET_FUNDING_MODIFIERS,
   GET_FUNDING_MODIFIERS_SUCCESS,
   CHANGE_FUNDING_SOURCE_SERVICE_STATUS,
   DELETE_FUNDING_SOURCE, GET_FUNDING_SOURCE_CLIENTS, GET_FUNDING_SOURCE_CLIENTS_SUCCESS,
} from './fundingSource.types';
import { httpRequestsOnErrorsActions } from "../http_requests_on_errors";
import { httpRequestsOnLoadActions } from "../http_requests_on_load";
import { httpRequestsOnSuccessActions } from "../http_requests_on_success";

/** Founding Source */

function* createFundingSource(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.createFundingSourceService, action.payload.body);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* editFundingSource(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.editFundingSourceService, action.payload.id, action.payload.body);
      const res = yield call(authService.getFoundingSourceByIdService, action?.payload?.id);
      yield put({
         type: GET_FUNDING_SOURCE_BY_ID_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

      // yield put({
      //    type: GET_FUNDING_SOURCE_BY_ID,
      //    payload: action.payload.id,
      // });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getFundingSource(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   if (action.payload.load !== "noLoad") {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   try {
      const res = yield call(authService.getFundingSourceService, action?.payload?.data);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_FUNDING_SOURCE_SUCCESS,
         payload: res.data,
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* getFundingSourceById(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(authService.getFoundingSourceByIdService, action.payload);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_FUNDING_SOURCE_BY_ID_SUCCESS,
         payload: res.data,
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

function* changeFundingSourceStatus(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {

      const body = action.payload.body ? action.payload.body : "";
      const res = yield call(
          authService.changeFundingSourceStatusService,
          action.payload.id,
          action.payload.path,
          action.payload.status,
          body
      );

      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put({
         type: action.payload.type,
         payload: res.data,
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}
function* deleteFundingSource(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.deleteFundingSourceService, action.payload);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}
function* getFundingSourceClients(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(authService.getFundingSourceClientsService, action.payload);
      yield put({
         type: GET_FUNDING_SOURCE_CLIENTS_SUCCESS,
         payload: res.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

/** Founding Source Service */

function* createFundingSourceServiceById(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(authService.createFSServiceByIdService, action.payload.id, action.payload.body);
      yield put({
         type: GET_FUNDING_SOURCE_SERVICE_BY_ID,
         payload: {id: res.data?.funderId, data: action?.payload?.reqParams},
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

   } catch (err) {
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}

function* editFundingSourceService(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(authService.editFSServiceService, action.payload.id, action.payload.body);

      yield put({
         type: GET_FUNDING_SOURCE_SERVICE_BY_ID,
         payload: {id: res.data?.funderId, data: action?.payload?.reqParams},
      });
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}

function* changeFundingSourceServiceStatus(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.changeFundingSourceServiceStatus, action.payload.id, action.payload.body);
      yield put({
         type: GET_FUNDING_SOURCE_SERVICE_BY_ID,
         payload: {id: action.payload.funderId, data: action?.payload?.reqParams},
      });
      yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   }
}

function* getFundingSourceServicesById(action) {
   if(action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(authService.getFSServiceByIdService, action.payload.id, action.payload?.data);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put({
         type: GET_FUNDING_SOURCE_SERVICE_BY_ID_SUCCESS,
         payload: res.data,
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      if (err?.data?.message === "Internal server error") {
         yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
         );
      }
   }
}

/** End */

/** Modifiers */

function* getServiceModifier(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   if(action?.payload?.load !== 'noLoad'){
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   try {
      const res = yield call(authService.getServiceModifierService, action.payload.serviceId);
      yield put({
         type: GET_FUNDING_MODIFIERS_SUCCESS,
         payload: res?.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* createFundingModifier(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.createModifierService, action.payload.body);
      yield put({
         type: GET_FUNDING_MODIFIERS,
         payload: { serviceId: action?.payload?.body?.serviceId,
            // load:'noLoad'
         },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* editFundingModifier(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.editModifierService, action.payload?.chargeRateId, action.payload?.body );
      yield put({
         type: GET_FUNDING_MODIFIERS,
         payload: { serviceId: action?.payload?.serviceId,
            // load:'noLoad'
         },
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* changeFundingModifierStatus(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.modifierStatusService, action.payload?.chargeRateId,);
      yield put({
         type: GET_FUNDING_MODIFIERS,
         payload: { serviceId: action?.payload?.serviceId, load:'noLoad'},
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

function* setStatus(action) {
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(authService.setStatusService, action.payload.id, action.payload.status);
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put({
         type: GET_FUNDING_SOURCE_BY_ID_SUCCESS,
         payload: res.data,
      });
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}


/** Fs Documents */
function* uploadFsDocument(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.uploadService, action?.payload?.files, action?.payload?.id);
      yield put({
         type: GET_FS_DOCUMENT,
         payload: {id: action?.payload?.id, load: "noLoad"},
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* editFsDocument(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      yield call(authService.editService, action?.payload);
      yield put({
         type: GET_FS_DOCUMENT,
         payload: {id: action?.payload?.id, load: "noLoad"},
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* getFsDocument(action) {
   if (action?.payload?.load !== 'noLoad') {
      yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   }
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   try {
      const res = yield call(authService.getFilesService, action.payload.id);
      yield put({
         type: GET_FS_DOCUMENT_SUCCESS,
         payload: res?.data,
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
   } catch (err) {
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

function* deleteFsDocument(action) {
   yield put(httpRequestsOnLoadActions.appendLoading(action.type));
   yield put(httpRequestsOnErrorsActions.removeError(action.type));
   yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
   try {
      const res = yield call(authService.deleteFilesService, action.payload.fsId, action.payload.id);
      yield put({
         type: GET_FS_DOCUMENT,
         payload: {id: action?.payload?.fsId, load: "noLoad"},
      });
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
      yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
   } catch (err) {
      yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
      yield put(httpRequestsOnLoadActions.removeLoading(action.type));
      yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
   }
}

/** End */

export const watchFundingSource = function* watchFundingSourceSaga() {
   /** Founding Source */
   yield takeLatest(CREATE_FUNDING_SOURCE, createFundingSource);
   yield takeLatest(EDIT_FUNDING_SOURCE, editFundingSource);
   yield takeLatest(GET_FUNDING_SOURCE, getFundingSource);
   yield takeLatest(GET_FUNDING_SOURCE_BY_ID, getFundingSourceById);
   yield takeLatest(SET_STATUS, changeFundingSourceStatus);
   yield takeLatest(DELETE_FUNDING_SOURCE, deleteFundingSource);
   yield takeLatest(GET_FUNDING_SOURCE_CLIENTS, getFundingSourceClients);
   /** End */

   /** Founding Source Service */
   yield takeLatest(GET_FUNDING_SOURCE_SERVICE_BY_ID, getFundingSourceServicesById);
   yield takeLatest(CREATE_FUNDING_SOURCE_SERVICE_BY_ID, createFundingSourceServiceById);
   yield takeLatest(EDIT_FUNDING_SOURCE_SERVICE, editFundingSourceService);
   yield takeLatest(CHANGE_FUNDING_SOURCE_SERVICE_STATUS, changeFundingSourceServiceStatus);
   /** End */

   /** Modifiers */
   yield takeLatest(GET_FUNDING_MODIFIERS, getServiceModifier);
   yield takeLatest(CREATE_FUNDING_MODIFIER, createFundingModifier);
   yield takeLatest(EDIT_FUNDING_MODIFIER, editFundingModifier);
   yield takeLatest(CHANGE_FUNDING_MODIFIER_STATUS, changeFundingModifierStatus);
   /** End */
   
   yield takeLatest(CHANGE_FUNDING_SOURCE_STATUS, setStatus);

   /** Staff Documents */
   yield takeLatest(UPLOAD_FS_DOCUMENT, uploadFsDocument);
   yield takeLatest(EDIT_FS_DOCUMENT, editFsDocument);
   yield takeLatest(GET_FS_DOCUMENT, getFsDocument);
   yield takeLatest(DELETE_FS_DOCUMENT, deleteFsDocument);
   /** End */
};
