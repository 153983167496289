import React from 'react';
import { SERVICE } from '../../../constants';
import { Images } from 'utils';
import { apptDetailStatus } from './constants';
import { ApptActionsWithModals } from '../../apptActionsWithModals';

export const ApptActions = ({ item, setModalIsOpen, setActiveTab, afterSuccess }) => {

    return (
        <>
            {item?.type === SERVICE &&
                <div className="appt-detail-status-box">
                    {apptDetailStatus(item?.status, item)}
                </div>
            }
            <div className="space-between" style={{ margin: item?.type === SERVICE ? '24px 0' : '8px 0' }}>
                <div>
                    {item?.type === SERVICE ?
                        <div className="flex-align-center" style={{ gap: '12px' }}>
                            <button className="new-action-btn-box" onClick={() => setActiveTab('Notes')}>
                                <img src={Images.manageNote} alt="icon" />
                                <p>Manage Notes</p>
                            </button>

                            <button className="new-action-btn-box" onClick={setModalIsOpen}>
                                <img src={Images.uploadSignature} alt="icon" />
                                <p>Upload Signature</p>
                            </button>
                        </div>
                        :
                        <div className="appt-detail-status-box">
                            {apptDetailStatus(item?.status, item)}
                        </div>
                    }
                </div>

                <ApptActionsWithModals item={item} afterSuccess={afterSuccess} />

            </div>
        </>
    );
};