import React, { createContext, useState, Fragment, useContext, useEffect } from 'react';
import { CustomModal } from '../../components/modal/modalBase';

export const ModalContext = createContext();


export const ModalProvider = ({ children }) => {
    const [activeModal, setActiveModal] = useState('');
    const [params, setParams] = useState({});

    return (
        <Fragment>
            <ModalContext.Provider
                value={{
                    activeModal,
                    setActiveModal,
                    params,
                    setParams,
                }}>

                {children}
            </ModalContext.Provider>
        </Fragment>
    );
};

export const useModal = () => {
    const { setActiveModal, params, setParams } = useContext(ModalContext);

    return {
        open: (params = {}) => {
            setActiveModal(true);
            setParams(params);
        },
        close: () => {
            setActiveModal('');
            setParams({});
        },
        params,
    };
};


export const AllModals = () => {
    const { activeModal } = useContext(ModalContext);
    const { close, params } = useModal();

    useEffect(() => {
        if (activeModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [activeModal]);

    return (
        <CustomModal
            handleOpenClose={() => close('')}
            openDefault={activeModal ? activeModal : false}
            content={
                <div style={{ outline: 'none' }} className="modal-wrapper-style open-sans">
                    {/*<div className='modal-header-style'>*/}
                    {/*<CloseButton handleCLic={close} styles={{marginRight:'10px'}}/>*/}
                    {/*</div>*/}
                    <div className="modal-body-style">
                        {params}
                    </div>
                </div>
            }
        />
    );
};