import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { claimDetailsCoreStyle } from './styles';
import { claimActions } from 'store';
import { CustomDelByNote, DetailItem, ModalFullTable, SimpleModal } from 'components';
import {
    billApptBody,
    billApptHead, billDetailsList, billInfoList,
    claimPaymentTableBody,
    claimPaymentTableHead,

} from './constants';

const activeButtonStyle = {
    fontWeight: 700,
    borderBottom: '4px solid #FFCA33',
};

const REMOVE_ACTION_TYPE = 'REMOVE_APPT_FROM_CLAIM';
export const ReceivableBillTable = ({ currentId, defaultBill, from }) => {
    const { billClaimPmt, claimDetails } = useSelector((state) => ({
        billClaimPmt: state.claim.billClaimPmt,
        claimDetails: state.claim.claimById,
    }));
    const classes = claimDetailsCoreStyle();
    const [activeButton, setActiveButton] = useState('detail');
    const [removeAppt, setRemoveAppt] = useState(null);
    const dispatch = useDispatch();
    const billById = defaultBill ? defaultBill :  claimDetails?.bills?.find((i) => i?.id === currentId);

    useEffect(() => {
        if(billById) {
            dispatch(claimActions.bilClaimPmtTr(billById?.id));
            dispatch(claimActions.bilInvoicePmt([billById?.id]));
        }
    }, [billById]);

    const afterSuccess = () => {
        dispatch(claimActions.getClaimById(claimDetails?.id, 'noLoad'));
    };

    const handleChange = (type) => {
        setActiveButton(type);
    };

    const removeFromClaim = (item) => {
        setRemoveAppt(item);
    };

    return (
        <div style={{ width: '1200px', height: '80vh' }}>
            <div className={classes.recBillContainerStyle}>
                <div className={classes.recBillTitleStyle}>
                    <p>Bill ID:</p>
                    <p style={{ fontWeight: 400, marginLeft: 4 }}>{billById?.displayId}</p>
                </div>

                <div className={classes.billInfo}>
                    {billInfoList(billById)?.map((item, index) => item?.value && (
                        <div key={index} className={classes.billInfoItem}>
                            <p>{item.title}</p>
                            <p style={{fontWeight: 400, width: '100%'}}>{item.value}</p>
                        </div>
                    ))}
                </div>


                <div>
                    <div className={classes.buttonsTabWrapper}>
                        <button onClick={() => handleChange('detail')}
                                style={activeButton === 'detail' ? { ...activeButtonStyle } : {}}>
                            Details
                        </button>
                        <button onClick={() => handleChange('claim')}
                                style={activeButton === 'claim' ? { ...activeButtonStyle } : {}}>
                            Claim Payment Transaction
                        </button>
                        <button onClick={() => handleChange('appointments')}
                                style={activeButton === 'appointments' ? { ...activeButtonStyle } : {}}>
                            Appointments
                        </button>
                    </div>

                    {activeButton === 'detail' &&
                        <div className={classes.billDetailsBox}>
                            {billDetailsList(billById)?.map((item, index) => item?.value && (
                             <Fragment key={index}>
                                <DetailItem title={item?.title} value={item.value} background={item?.background}/>
                             </Fragment>
                            ))}
                        </div>
                    }

                    {activeButton === 'claim' &&
                        <ModalFullTable
                            head={claimPaymentTableHead}
                            body={claimPaymentTableBody}
                            loadingType={'GET_BILL_CLAIM_PAYMENT_TRANSACTIONS'}
                            list={billClaimPmt?.txns}
                            noText={'claim payment transactions'}
                            activeRowId={''}
                            height={'auto'}
                        />
                    }
                    {activeButton === 'appointments' &&
                        <ModalFullTable
                            head={billApptHead(claimDetails, from)}
                            body={billApptBody(classes, removeFromClaim, claimDetails, afterSuccess, from)}
                            list={billById?.appts}
                            noText={'appointments'}
                            activeRowId={''}
                            height={'auto'}
                        />
                    }
                </div>
            </div>

            <SimpleModal
                openDefault={removeAppt?.id}
                handleOpenClose={() => setRemoveAppt(null)}
                content={

                    <div style={{ padding: 24, background: 'white' }}>
                        <CustomDelByNote
                            info="Remove Appointment from Claim"
                            text="Are you sure you want to remove this appointment from the claim? Please provide a reason for removing this appointment."
                            handleDel={(comment) => {
                                dispatch(claimActions.removeFromClaim(removeAppt?.id, comment, claimDetails?.id));
                            }}
                            innerText={'Remove'}
                            inputLabel={'Reason for removing*'}
                            actionType={REMOVE_ACTION_TYPE}
                            body={
                                <div>
                                    <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                                        <p style={{ fontWeight: 600 }}>Claim ID:</p>
                                        <p style={{ fontWeight: 400 }}>{claimDetails?.displayId}</p>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                }
                secondary={true}
            />
        </div>
    );
};
