import { Images, Videos } from 'assets';


export const helpCenterMenu = [
    {
        key: 'USER_ACCOUNT',
        title: 'User Account',
        subMenu: [
            {
                key: 'CHANGING_PROFILE',
                title: 'Changing Profile Picture',
            },
            {
                key: 'CHANGING_PASSWORD',
                title: 'Changing Password',
            },
        ],
    },
    {
        key: 'ROLE_MANAGEMENT',
        title: 'Role Management',
        subMenu: [
            {
                key: 'CREATING_ROLES_PERMISSIONS',
                title: 'Creating Roles and Permissions for Different Users',
            },

            {
                key: 'ADDING_DELETING_ROLE',
                title: 'Adding/Deleting Permissions to a Role',
            },

            {
                key: 'DELETING_ROLE',
                title: 'Deleting a Role',
            },

            {
                key: 'RENAMING_ROLES',
                title: 'Renaming Roles',
            },
        ],
    },

    {
        key: 'FUNDING_SOURCE',
        title: 'Funding Source',
        subMenu: [
            {
                key: 'CREATE_FUNDING',
                title: 'Creating a Funding Source',
            },

            {
                key: 'ADDING_SERVICE_FUNDING',
                title: 'Adding Services to a Funding Source',
            },

            {
                key: 'ADDING_CHARGE_RATES',
                title: 'Adding Charge Rates to a Funding Source',
            },

            {
                key: 'ACTIVATING_INACTIVATING_FUNDING',
                title: 'Activating or Inactivating a Funding Source',
            },

            {
                key: 'MERGING_APPOINTMENTS',
                title: 'Merging Appointments',
            },
            {
                key: 'DOWNLOADING_CSV_FUNDING',
                title: 'Downloading a CSV of the Funding Sources',
            },

            {
                key: 'EDITING_FUNDING',
                title: 'Editing Funding Source',
            },

            {
                key: 'INACTIVATING_ACTIVE_FUNDING',
                title: 'Inactivating an Active Funding Source Service',
            },

            {
                key: 'ACTIVATING_INACTIVE_FUNDING',
                title: 'Activating an Inactive Funding Source Service',
            },

            {
                key: 'EDITING_FUNDING_SERVICE',
                title: 'Editing a Funding Source Service',
            },

            {
                key: 'ADDING_NOTES_FUNDING',
                title: 'Adding Notes to a Funding Source',
            },

            {
                key: 'EDITING_FUNDING_NOTES',
                title: 'Editing Funding Source Notes',
            },

            {
                key: 'DELETING_FUNDING_NOTES',
                title: 'Deleting Funding Source Notes',
            },

            {
                key: 'VIEWING_FUNDING_CHANGES_HISTORY',
                title: 'Viewing Funding Source Changes History',
            },

            {
                key: 'UPLOADING_FUNDING_FILES',
                title: 'Uploading Funding Source Files',
            },

            {
                key: 'EDITING_FUNDING_FILES',
                title: 'Editing Funding Source Files',
            },

            {
                key: 'DELETING_FUNDING_FILES',
                title: 'Deleting Funding Source Files',
            },

            {
                key: 'DOWNLOADING_FUNDING_FILES',
                title: 'Downloading Funding Source Files',
            },

            {
                key: 'VIEWING_FUNDING_FILES',
                title: 'Viewing the Funding Source Files',
            },
        ],
    },
    {
        title: 'Staff',
        path: '',
        subMenu: [
            {
                title: 'Downloading a CSV of the Staff',
            },

            {
                title: 'Adding Staff Members',
            },

            {
                title: 'Adding Staff Paycodes',
            },

            {
                title: 'Adding Staff Timesheets (from Paycode)',
            },
            {
                title: 'Adding Staff Timesheets (Fixed Pay)',
            },

            {
                title: 'Adding Staff Credentials',
            },

            {
                title: 'Adding Staff Access',
            },

            {
                title: 'Adding Staff Services',
            },

            {
                title: 'Adding Staff Notes',
            },

            {
                title: 'Viewing Staff Profile Change History',
            },

            {
                title: 'Adding Staff Files',
            },
            {
                title: 'Adding/Editing Staff General Information',
            },

            {
                title: 'Adding Staff Files',
            },

            {
                title: 'Adding Staff Member’s Current and Past Employment',
            },

            {
                title: 'Viewing Staff Member’s Current Employment',
            },

            {
                title: 'Viewing Staff Member’s Past Employment',
            },

            {
                title: 'Inactivating Staff Paycode',
            },

            {
                title: 'Editing Staff Timesheets (for Fixed and Paycode-Based Timesheets)',
            },

            {
                title: 'Editing Staff Credentials',
            },

            {
                title: 'Viewing Staff Credentials',
            },

            {
                title: 'Deleting Staff Credentials',
            },

            {
                title: 'Deleting Staff Access',
            },

            {
                title: 'Deleting Staff Services',
            },

            {
                title: 'Editing Staff Notes',
            },

            {
                title: 'Deleting Staff Notes',
            },

            {
                title: 'Deleting Staff Files',
            },

            {
                title: 'Editing Staff Member’s Past Employment',
            },

            {
                title: 'Deleting Staff Member’s Past Employment',
            },

            {
                title: 'Activating/Inactivating a Staff Member',
            },
        ],
    },
    {
        title: 'Client',
        path: '',
        subMenu: [
            {
                title: 'Adding Clients',
            },

            {
                title: 'Adding a Contact to a Client',
            },

            {
                title: 'Adding Enrollments to Clients',
            },

            {
                title: 'Adding Notes to a Client',
            },

            {
                title: 'Viewing Client Profile’s Change History',
            },

            {
                title: 'Adding Files to a Client',
            },

            {
                title: 'Assigning Staff to a Client',
            },

            {
                title: 'Deleting Assigned Staff from a Client',
            },

            {
                title: 'Viewing Assigned Staff for a Client',
            },

            {
                title: 'Making a Client Active/Inactive',
            },

            {
                title: 'Viewing Client Files',
            },

            {
                title: 'Editing Client Files',
            },

            {
                title: 'Downloading Client Files',
            },

            {
                title: 'Deleting Client Files',
            },

            {
                title: 'Viewing Client Notes',
            },

            {
                title: 'Editing Client Notes',
            },

            {
                title: 'Deleting Client Notes',
            },

            {
                title: 'Adding Authorization to a Client',
            },

            {
                title: 'Adding Diagnostic Codes to a Client Authorization',
            },

            {
                title: 'Adding an Authorized Service to a Client\'s Authorization',
            },

            {
                title: 'Editing a Client Authorization',
            },

            {
                title: 'Editing an Authorized Service',
            },

            {
                title: 'Viewing Past Authorizations',
            },

            {
                title: 'Managing the Appt Visible Toggle for Past Authorizations',
            },

            {
                title: 'Editing Primary or Secondary Enrollment',
            },

            {
                title: 'Terminating a Primary or Secondary Enrollment',
            },

            {
                title: 'Setting a Secondary Enrollment as Primary',
            },

            {
                title: 'Editing Client Contacts',
            },

            {
                title: 'Deleting Client Contacts',
            },

            {
                title: 'Adding an Address for a Client',
            },

            {
                title: 'Editing General Info for a Client',
            },

            {
                title: 'Editing Other Info for a Client',
            },

            {
                title: 'Deleting an Address for a Client',
            },

            {
                title: 'Editing an Address for a Client',
            },

            {
                title: 'Setting an Address as the Default Address',
            },
            {
                title: 'Downloading Client List CSV',
            },
        ],
    },

    {
        title: 'Schedule',
        path: '',
        subMenu: [],
    },

    {
        title: 'Billing',
        path: '',
        subMenu: [],
    },
    {
        title: 'Payroll',
        path: '',
        subMenu: [
            {
                title: 'Create QR Code',
                hash: 'createQRCode',
            },
            {
                title: 'QR Design Customization',
                hash: 'qrDesignCustomization',
            },
            {
                title: 'QR Design Preview',
                hash: 'qrDesignPreview',
            },
            {
                title: 'Set as Default',
                hash: 'setAsDefault',
            },
            {
                title: 'Download QR',
                hash: 'downloadQR',
            },
        ],
    },
    {
        key: 'SYSTEM',
        title: 'System',
        subMenu: [
            {
                key: 'CREATING_COMPANY',
                title: 'Creating a Company Profile',
            },

            {
                key: 'EDITING_COMPANY',
                title: 'Editing a Company Profile',
            },
            {
                key: 'CREATING_SERVICE_TYPES',
                title: 'Creating Service Types',
            },
            {
                key: 'EDITING_SERVICE_TYPES',
                title: 'Editing Service Types',
            },

            {
                key: 'CREATING_CREDENTIALS',
                title: 'Creating Credentials',
            },

            {
                key: 'ADDING_COVERED_CREDENTIALS',
                title: 'Adding Covered Credentials',
            },

            {
                key: 'EDITING_CREDENTIALS',
                title: 'Editing Credentials',
            },

            {
                key: 'DELETING_COVERED_CREDENTIALS',
                title: 'Deleting Covered Credentials',
            },

            {
                key: 'ADDING_DEPARTMENTS',
                title: 'Adding Departments',
            },

            {
                key: 'EDITING_DEPARTMENTS',
                title: 'Editing Departments',
            },

            {
                key: 'ADDING_JOB_TITLES',
                title: 'Adding Job Titles',
            },

            {
                key: 'EDITING_JOB_TITLES',
                title: 'Editing Job Titles',
            },

            {
                key: 'ADDING_PAYCODE_TYPES',
                title: 'Adding Paycode Types',
            },

            {
                key: 'EDITING_PAYCODE_TYPES',
                title: 'Editing Paycode Types',
            },

            {
                key: 'ADDING_MILEAGE',
                title: 'Adding Mileage Compensation',
            },

            {
                key: 'EDITING_MILEAGE',
                title: 'Editing Mileage Compensation',
            },

            {
                key: 'INACTIVATING_MILEAGE',
                title: 'Inactivating Mileage Compensation',
            },

            {
                key: 'ADDING_PLACE',
                title: 'Adding a Place of Service',
            },

            {
                key: 'EDITING_PLACE',
                title: 'Editing a Place of Service',
            },
        ],
    },
];


export const helpCenterContent = {
    'ROLE_MANAGEMENT': {
        'CREATING_ROLES_PERMISSIONS': {
            pageTitle: 'Role Management',
            content: [
                {
                    subTitle: 'Creating Roles and Permissions for Different Users',
                    decimal: true,
                    list: [
                        {
                            title: 'Navigate to Role Management:',

                            list: [
                                {
                                    title: 'Go to the left pane and click Role Management > Add Role.',
                                    boldTexts: ['Role Management > Add Role'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            title: 'Enter Role Details:',
                            list: [
                                {
                                    title: 'Set Role Name: Enter the name of the role (e.g., "HR Manager" or "Billing Specialist").',
                                    boldTexts: ['Set Role Name:'],
                                    circle: true,
                                },

                                {
                                    title: 'Set Permissions: Choose the permissions you want to assign to this role from a list.',
                                    boldTexts: ['Set Permissions:'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            title: 'Add the Role:',
                            list: [
                                {
                                    title: 'Click Add to create the new role.',
                                    boldTexts: ['Add'],
                                    circle: true,
                                },

                                {
                                    title: 'The new role will appear in the left table labeled Role.',
                                    boldTexts: ['Role'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            title: 'View Assigned Permissions:',
                            list: [
                                {
                                    title: 'Click on the role in the left table to see the permissions assigned to it, in the right table.',
                                    circle: true,
                                },
                            ],


                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.createRoleDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ADDING_DELETING_ROLE': {
            pageTitle: 'Adding/Deleting Permissions to a Role',
            content: [
                {
                    subTitle: '1. Navigate to Role Management',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the left pane and click >Role Management.',
                                    boldTexts: ['Role Management > Add Role'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Role to Edit',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the left table labeled >Role, find the role you want to add permissions to.',
                                    boldTexts: ['Role'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Add Permissions',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the + Add Permissions button in the Role Permissions table on the right.',
                                    boldTexts: ['Role Permissions'],
                                    circle: true,
                                },

                                {
                                    title: 'Click into the Select Permissions field.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Select the permission you would like to assign to your selected role.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click Add Permissions. You will see the permission added in the right table.',
                                    boldTexts: ['Add Permissions'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addPermDesktop,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Deleting Permissions',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Select the role from which you want to delete permissions.',
                                    boldTexts: ['Role Permissions'],
                                    circle: true,
                                },

                                {
                                    title: 'Navigate to the right table where the permissions assigned to that role are listed.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the red X button next to the permission you want to delete.',
                                    boldTexts: ['X button'],
                                    circle: true,
                                },

                                {
                                    title: 'Click Remove once the confirmation modal pops up.',
                                    boldTexts: ['Remove'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.deletingPermDesktop,
                                },
                            ],
                        },
                    ],
                },


            ],

        },
        'DELETING_ROLE': {
            pageTitle: 'Deleting a Role',
            content: [
                {
                    subTitle: '1. Navigate to Role Management',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the left pane and click >Role Management.',
                                    boldTexts: ['Role Management'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Find the Role to Delete',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the left table labeled >Role, locate the role you want to delete.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Delete the Role',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the red X button next to the role.',
                                    boldTexts: ['X button'],
                                    circle: true,
                                },

                                {
                                    title: 'Click Delete on the confirmation modal to confirm the deletion.',
                                    boldTexts: ['Delete'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.deletingRoleDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'RENAMING_ROLES': {
            pageTitle: 'Renaming Roles',
            content: [
                {
                    subTitle: '1. Navigate to Role Management',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the left pane and click >Role Management.',
                                    boldTexts: ['Role Management'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Role to Edit',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the left table labeled Role, find the role you want to rename.',
                                    boldTexts: ['Role'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.renamingRolesSelect,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Edit Role Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the pencil icon next to the role.',
                                    boldTexts: ['pencil icon'],
                                    circle: true,
                                },

                                {
                                    title: 'Make the necessary changes to the role name.',
                                    boldTexts: ['Delete'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.renamingRolesEdit,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: 'Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save to apply the changes.',
                                    boldTexts: ['Save'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
    },
    'SYSTEM': {
        'CREATING_COMPANY': {
            pageTitle: 'Creating a Company Profile',
            content: [
                {
                    subTitle: '1. Navigate to System',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the left pane and click >System.',
                                    boldTexts: ['System'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Company Profile Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the >Company Profile tab to open the profile settings.',
                                    boldTexts: ['Company Profile'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Enter Company Information',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Company Name: Type in the company\'s name.',
                                    boldTexts: ['Company Name'],
                                    circle: true,
                                },

                                {
                                    title: 'Company Logo: Drag and drop or click to choose a company logo file.',
                                    boldTexts: ['Company Logo'],
                                    circle: true,
                                },

                                {
                                    title: 'Company Address: Enter the company\'s physical address.',
                                    boldTexts: ['Company Address'],
                                    circle: true,
                                },

                                {
                                    title: 'Phone Number: Enter the company\'s primary contact number.',
                                    boldTexts: ['Phone Number'],
                                    circle: true,
                                },

                                {
                                    title: 'NPI Number: Enter the National Provider Identifier (NPI) number.',
                                    boldTexts: ['NPI Number'],
                                    circle: true,
                                },

                                {
                                    title: 'Tax ID: Enter the company\'s tax identification number.',
                                    boldTexts: ['Tax ID'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Save the Company Profile',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Scroll down and click Save to create the company profile.',
                                    boldTexts: ['Save'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.saveCompanyProfDesktop,
                                },
                            ],
                        },
                    ],

                },
            ],

        },
        'EDITING_COMPANY': {
            pageTitle: 'Editing a Company Profile',
            content: [
                {
                    subTitle: '1. Access the Company Profile Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the ->System section in the left pane and click on the >Company Profile tab.',
                                    boldTexts: ['Company Profile'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Edit Company Profile',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the blue pencil icon located to the right of the >Company Profile title, above the Company Name field.',
                                    boldTexts: ['Company Profile'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingCompPropDesktop,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Update Company Information',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Make any necessary changes to the fields such as the company name, address, phone number, NPI number, Tax ID, or upload a new logo file.',
                                    boldTexts: ['X button'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'After making the desired changes, scroll down and click Save to apply the updates to the company profile.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.saveChangesDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'CREATING_SERVICE_TYPES': {
            pageTitle: 'Creating Service Types',
            content: [
                {
                    subTitle: '1. Access the Service Types Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the ->System section in the left pane and click on the >Service Types tab.',
                                    boldTexts: ['Service Types'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Service Type',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click into the Service Name field and type the service name (e.g., "Parent Training," "Direct Service," "Functional Behavioral Analysis," etc.).',
                                    boldTexts: ['Service Name'],
                                    circle: true,
                                },

                                {
                                    title: 'Click into the Display Code field and type a code for the service (e.g., "PT," "DS," "FBA," etc.).',
                                    boldTexts: ['Display Code'],
                                    circle: true,
                                },

                                {
                                    title: 'Click into the Category field and type the category for the service (e.g., "ABA," "FBA," etc.).',
                                    boldTexts: ['Category'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Save the New Service Type',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the blue + Add Service Type button.',
                                    boldTexts: ['Add Service Type button'],
                                    circle: true,
                                },

                                {
                                    title: 'The newly added service type will appear in the table at the bottom.',
                                    boldTexts: ['Delete'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.saveServiceTypeDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_SERVICE_TYPES': {
            pageTitle: 'Managing Service Types',
            content: [
                {
                    subTitle: '1. Edit a Service Type',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Locate the service type you want to edit in the bottom table.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the pencil icon next to the service type.',
                                    boldTexts: ['pencil icon'],
                                    circle: true,
                                },

                                {
                                    title: 'Make the necessary changes to the Service Name, Display Code, or Category fields.',
                                    boldTexts: ['Service Name', ' Display Code', 'Category fields'],
                                    circle: true,
                                },

                                {
                                    title: 'Click Save to apply the changes',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingServiceDesktop1,
                                },

                                {
                                    type: 'img',
                                    desktop: Images.editingServiceDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'CREATING_CREDENTIALS': {
            pageTitle: 'Creating Credentials',
            content: [
                {
                    subTitle: '1. Access the Credentials Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the -> System section in the left pane and click on the > Credentials tab.',
                                    boldTexts: ['System', 'Credentials'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Credential',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the + Add Credentials button on the left table.',
                                    boldTexts: ['Add Credentials'],
                                    circle: true,
                                },

                                {
                                    title: 'A modal will appear where you need to fill in the following:',
                                    boldTexts: [''],
                                    circle: true,
                                    list: [
                                        {
                                            title: 'Credential Name: Enter the name of the credential (e.g., "Registered Behavior Technician," "Licensed Behavior Analyst").',
                                            boldTexts: ['Credential Name'],
                                        },

                                        {
                                            title: 'Credential Type: Select the type of credential from the dropdown menu:',
                                            boldTexts: ['Credential Type'],
                                            list: [
                                                {
                                                    title: 'Degree: Examples include Bachelor\'s (BA/BS) or Master\'s Degree (MA/MS).',
                                                    boldTexts: ['Degree'],
                                                },

                                                {
                                                    title: 'Clearance: An example is Registered Behavior Technician (RBT)',
                                                    boldTexts: ['Clearance'],
                                                },

                                                {
                                                    title: 'License: Examples include Licensed Behavior Analyst (LBA) or Licensed Clinical Social Worker (LCSW).',
                                                    boldTexts: ['License'],
                                                },
                                            ],
                                        },
                                    ],
                                },

                                {
                                    title: 'Click Add on the modal.',
                                    boldTexts: ['Add'],
                                    circle: true,
                                },

                                {
                                    title: 'Once added, the credential will appear in the left table.',
                                    boldTexts: ['Add'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.creatingCredDesktop1,
                                },

                                {
                                    type: 'img',
                                    desktop: Images.creatingCredDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ADDING_COVERED_CREDENTIALS': {
            pageTitle: 'Adding Covered Credentials',
            content: [
                {
                    subTitle: '1. Access the Credentials Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the > System section in the left pane and click on the > Credentials tab.',
                                    boldTexts: ['System', 'Credentials'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a Covered Credential:',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Find an existing credential in the left table (e.g., "Master\'s Degree").',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the credential to select it.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'On the right table, click the + Add Covered Credential button.',
                                    boldTexts: ['Add Covered Credential'],
                                    circle: true,
                                },

                                {
                                    title: 'Click into the Select Covered Credential field.',
                                    boldTexts: ['Select Covered Credential'],
                                    circle: true,
                                },

                                {
                                    title: 'Select the existing credential that this selected credential covers (e.g., "Bachelor\'s Degree," "High School Diploma," etc.).',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'The selected covered credentials will appear in the right table.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addingCovCredDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_CREDENTIALS': {
            pageTitle: 'Editing Credentials',
            content: [
                {
                    subTitle: '1. Access the Credentials Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the > System section in the left pane and click on the > Credentials tab.',
                                    boldTexts: ['System', 'Credentials'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Edit a Credential’s Name or Type',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Find the credential you want to edit in the left table.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the blue pencil icon next to it.',
                                    boldTexts: ['blue pencil'],
                                    circle: true,
                                },

                                {
                                    title: 'Edit the Credential Name field or select a new Credential Type.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click Save to apply changes.',
                                    boldTexts: ['Save'],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editCredNameDesktop1,
                                },

                                {
                                    type: 'img',
                                    desktop: Images.editCredNameDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'DELETING_COVERED_CREDENTIALS': {
            pageTitle: 'Deleting Covered Credentials',
            content: [
                {
                    subTitle: '1. Access the Credentials Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the > System section in the left pane and click on the > Credentials tab.',
                                    boldTexts: ['System', 'Credentials'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Delete a Covered Credential',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Select the credential for which you want to delete a covered credential.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Go to the right table and find the covered credential you want to delete.',
                                    boldTexts: ['blue pencil'],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the red X icon next to the covered credential.',
                                    boldTexts: ['red X icon'],
                                    circle: true,
                                },

                                {
                                    title: 'Confirm deletion by clicking Remove on the confirmation modal.',
                                    boldTexts: ['Remove'],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.deleteCovCredDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ADDING_DEPARTMENTS': {
            pageTitle: 'Adding Departments',
            content: [
                {
                    subTitle: 'Creating Credentials',
                },

                {
                    subTitle: '1. Access the Departments Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the > System section in the left pane and click on the > Departments tab.',
                                    boldTexts: ['System', 'Departments'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Department',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Enter the department name in the Department Name field.',
                                    boldTexts: ['Department Name'],
                                    circle: true,
                                },

                                {
                                    title: 'Click the + Add Department button.',
                                    boldTexts: ['Add Department'],
                                    circle: true,
                                },

                                {
                                    title: 'The newly added department will appear in the list under Departments.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addingNewDepDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_DEPARTMENTS': {
            pageTitle: 'Editing Departments',
            content: [
                {
                    subTitle: '1. Access the Departments Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the > System section in the left pane and click on the > Departments tab.',
                                    boldTexts: ['System', 'Departments'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingDepDesktop1,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Access the Departments Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Find the department you want to edit in the list under Departments.',
                                    boldTexts: ['Departments'],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the blue pencil icon next to the department name.',
                                    boldTexts: ['blue pencil'],
                                    circle: true,
                                },

                                {
                                    title: 'Edit the Department Name field with the new name.',
                                    boldTexts: ['Department Name'],
                                    circle: true,
                                },

                                {
                                    title: 'Click Save to apply the changes.',
                                    boldTexts: ['Save'],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingDepDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ADDING_JOB_TITLES': {
            pageTitle: 'Adding Job Titles',
            content: [
                {
                    subTitle: 'Creating Credentials',
                },
                {
                    subTitle: '1. Access the Job Titles Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the  > System section in the left pane and click on the > Job Titles tab.',
                                    boldTexts: ['System', 'Job Titles'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Job Title',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Enter the job title in the Job Title field.',
                                    boldTexts: ['Job Title'],
                                    circle: true,
                                },

                                {
                                    title: 'Click the + Add Job Title button.',
                                    boldTexts: ['Add Job Title'],
                                    circle: true,
                                },

                                {
                                    title: 'The newly added job title will appear in the list under Job Titles.',
                                    boldTexts: ['Job Titles'],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addingNewJobDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_JOB_TITLES': {
            pageTitle: 'Editing Job Titles',
            content: [
                {
                    subTitle: '1. Access the Job Titles Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the > System section in the left pane and click on the > Job Titles tab.',
                                    boldTexts: ['System', 'Job Titles'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingJobTitleDesktop1,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Edit an Existing Job Title',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Find the job title you want to edit in the list under Job Titles.',
                                    boldTexts: ['Job Titles'],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the blue pencil icon next to the job title.',
                                    boldTexts: ['blue pencil icon'],
                                    circle: true,
                                },

                                {
                                    title: 'Edit the Job Title field with the new name.',
                                    boldTexts: ['Job Title'],
                                    circle: true,
                                },

                                {
                                    title: 'Click Save to apply the changes',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingJobTitleDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ADDING_PAYCODE_TYPES': {
            pageTitle: 'Adding Paycode Types',
            content: [
                {
                    subTitle: '1. Access the Payroll Setup Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >System section in the left pane and click on the Payroll Setup tab.',
                                    boldTexts: ['System', 'Payroll Setup'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Paycode Type',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Ensure the Paycode Types toggle is selected on the left side of the table.',
                                    boldTexts: ['Paycode Types'],
                                    circle: true,
                                },

                                {
                                    title: 'Under Add New Paycode Type, fill in the following fields:',
                                    boldTexts: ['Add New Paycode Type'],
                                    circle: true,

                                    list: [
                                        {
                                            title: 'Name: Enter the name for the paycode (e.g., "BCBA Hourly," "Drive Time," "Meal Break," etc.).',
                                            boldTexts: ['Name'],
                                        },

                                        {
                                            title: 'Code: Enter a code for the paycode (e.g., "BCBA H," "DT," "MB," etc.).',
                                            boldTexts: ['Code'],
                                        },

                                        {
                                            title: 'Type: Select between Hourly or Salary depending on the usage of this paycode.',
                                            boldTexts: ['Type'],
                                        },

                                        {
                                            title: 'Apply Overtime?: Choose Yes or No.',
                                            boldTexts: ['Apply Overtime'],
                                        },

                                        {
                                            title: 'Apply PTO?: Choose Yes or No.',
                                            boldTexts: ['Apply PTO', 'No'],
                                        },

                                    ],
                                },

                                {
                                    title: 'Click the blue Add Paycode Type button. The new paycode type will appear in the table on the right.',
                                    boldTexts: ['Add Paycode Type'],
                                    circle: true,
                                },

                                {
                                    title: 'Once added, the credential will appear in the left table.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addingPayTypesDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_PAYCODE_TYPES': {
            pageTitle: 'Editing Paycode Types',
            content: [
                {
                    subTitle: '1. Access the Payroll Setup Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >System section in the left pane and click on the Payroll Setup tab.',
                                    boldTexts: ['System', 'Payroll Setup'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select Paycode Types',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Ensure the Paycode Types toggle is selected on the left side of the table.',
                                    boldTexts: ['Paycode Types'],
                                    circle: true,
                                },

                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Edit a Paycode Type',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Navigate to the right table and find the paycode type you want to edit.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the blue pencil icon next to the desired paycode type.',
                                    boldTexts: ['blue pencil icon'],
                                    circle: true,
                                },

                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editPayTypeDesktop1,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '4. Modify Paycode Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Edit any of the following fields as needed:',
                                    boldTexts: [''],
                                    circle: true,

                                    list: [
                                        {
                                            title: 'Name: Change the name of the paycode.',
                                            boldTexts: ['Name'],
                                        },
                                        {
                                            title: 'Code: Update the code associated with the paycode.',
                                            boldTexts: ['Code'],
                                        },

                                        {
                                            title: 'Type: Switch between Hourly or Salary.',
                                            boldTexts: ['Type'],
                                        },

                                        {
                                            title: 'Apply Overtime?: Choose Yes or No.',
                                            boldTexts: ['Apply Overtime', 'Yes', 'No'],
                                        },

                                        {
                                            title: 'Apply PTO?: Choose Yes or No.',
                                            boldTexts: ['Apply PTO', 'Yes', 'No'],
                                        },

                                    ],
                                },

                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editPayTypeDesktop2,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save to apply the changes.',
                                    boldTexts: ['Save'],
                                    circle: true,
                                },
                            ],
                        },
                        {
                            notes: [
                                {
                                    text: 'Note: Changes made to a paycode will only affect future payroll calculations and paycodes. Past, already processed paycodes will remain unchanged.',
                                    boldTexts: ['Note'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        'ADDING_MILEAGE': {
            pageTitle: 'Adding Mileage Compensation',
            content: [
                {
                    subTitle: '1. Access the Payroll Setup Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >System section in the left pane and click on the Payroll Setup tab.',
                                    boldTexts: ['System', 'Payroll Setup'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Mileage Compensation',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Ensure the Mileage Compensation toggle is selected on the left side of the table.',
                                    boldTexts: ['Mileage Compensation'],
                                    circle: true,
                                },

                                {
                                    title: 'Under Add New Mileage Compensation, fill in the following fields:',
                                    boldTexts: ['Add New Paycode Type'],
                                    circle: true,

                                    list: [
                                        {
                                            title: '$ Mileage Compensation: Enter the amount to be compensated per mile of driving.',
                                            boldTexts: ['Mileage Compensation'],
                                        },

                                        {
                                            title: 'Start Date: Enter the start date for this compensation (this date is for record-keeping purposes only, as only one active mileage compensation is allowed at a time).',
                                            boldTexts: ['Start Date'],
                                        },

                                    ],
                                },

                                {
                                    title: 'Click the blue Add Mileage Compensation button. The new mileage compensation will appear in the table on the right.',
                                    boldTexts: ['Add Mileage Compensation'],
                                    circle: true,
                                },

                                {
                                    title: 'Note: If there is already an active mileage compensation, you must deactivate it before adding a new one.',
                                    boldTexts: ['Note'],
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addingMileageDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_MILEAGE': {
            pageTitle: 'Editing Mileage Compensation',
            content: [
                {
                    subTitle: '1. Access the Payroll Setup Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the System section in the left pane and click on the Payroll Setup tab.',
                                    boldTexts: ['System', 'Payroll Setup'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select Mileage Compensation',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Ensure the Mileage Compensation toggle is selected on the left side of the table.',
                                    boldTexts: ['Mileage Compensation'],
                                    circle: true,
                                },

                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Edit the Active Mileage Compensation',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Navigate to the right table and click the blue pencil icon next to the active mileage compensation.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingMilDesktop1,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Modify Mileage Compensation Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Edit the Mileage Compensation field (e.g., the amount per mile).',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Update the Start Date field as needed.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save to apply your changes. The updated details will reflect on the active mileage compensation line.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingMilDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'INACTIVATING_MILEAGE': {
            pageTitle: 'Inactivating Mileage Compensation',
            content: [
                {
                    subTitle: '1. Access the Payroll Setup Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the System section in the left pane and click on the Payroll Setup tab.',
                                    boldTexts: ['System', 'Payroll Setup'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select Mileage Compensation',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Ensure the Mileage Compensation toggle is selected on the left side of the table.',
                                    boldTexts: ['Mileage Compensation'],
                                    circle: true,
                                },

                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Inactivate the Active Mileage Compensation',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Navigate to the right table and click the red Inactive button next to the active mileage compensation.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                    ],
                },

                {
                    subTitle: '4. Confirm Inactivation',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Inactivate on the confirmation modal.',
                                    boldTexts: ['Inactivate'],
                                    circle: true,
                                },

                                {
                                    title: 'You will see a date appear in place of the recently inactivated mileage compensation, indicating the date it was inactivated',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.inactivatingMilDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ADDING_PLACE': {
            pageTitle: 'Adding a Place of Service',
            content: [
                {
                    subTitle: '1. Access the Place of Service Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the System section in the left pane and click on the Place of Service tab.',
                                    boldTexts: ['System', 'Place of Service'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Fill in the Place of Service Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Enter the required details in the three fields at the top of the table:',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Under Add New Paycode Type, fill in the following fields:',
                                    boldTexts: ['Add New Paycode Type'],
                                    circle: true,

                                    list: [
                                        {
                                            title: 'Name: Enter the name of the place (e.g., "School," "Telehealth," "In Home," etc.).',
                                            boldTexts: ['Name'],
                                        },

                                        {
                                            title: 'Code: Enter the code that will appear on the 1500 form (e.g., "01," "02," "11," etc.).',
                                            boldTexts: ['Code'],
                                        },

                                        {
                                            title: 'Modifier: Enter the modifier for the Place of Service that will go on the 1500 form (e.g., "25," "95," "GT," etc.).',
                                            boldTexts: ['Modifier'],
                                        },

                                    ],
                                },

                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Reference Common Places of Service',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Use the table of examples below for Places of Service, Codes, and Modifiers.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Keep in mind this table is an example check with rules and regulations regarding your industry to insure compliance.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.referenceCommonDesktop,
                                },
                            ],
                        },

                    ],
                },

            ],

        },
        'EDITING_PLACE': {
            pageTitle: 'Editing a Place of Service',
            content: [
                {
                    subTitle: '1. Access the Place of Service Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >System section in the left pane and click on the Place of Service tab.',
                                    boldTexts: ['System', 'Place of Service'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Place of Service to Edit',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'ENavigate to the table where the previously added places of service are listed.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingPlaceDesktop1,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Edit the Place of Service Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the blue pencil icon next to the place of service you want to edit.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Modify Paycode Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Edit any of the following fields as needed:',
                                    boldTexts: [''],
                                    circle: true,

                                    list: [
                                        {
                                            title: 'Name: Update the name of the place (e.g., "School," "Telehealth," "In Home," etc.).',
                                            boldTexts: ['Name'],
                                        },

                                        {
                                            title: 'Code: Change the code that appears on the 1500 form (e.g., "01," "02," "11," etc.).',
                                            boldTexts: ['Code'],
                                        },

                                        {
                                            title: 'Modifier: Edit the modifier associated with the Place of Service for the 1500 form (e.g., "25," "95," "GT," etc.).',
                                            boldTexts: ['Modifier'],
                                        },

                                    ],
                                },

                            ],
                        },
                        {
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingPlaceDesktop2,
                                },
                            ],
                        },

                    ],
                },

            ],

        },
    },
    'FUNDING_SOURCE': {
        'CREATE_FUNDING': {
            pageTitle: 'Creating a Funding Source',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left panel.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the blue + Add Funding Source button at the top right of the page.',
                                    boldTexts: ['Add Funding Source'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.createFundingDesktop1,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Fill Out the Funding Source Details',
                    list: [
                        {
                            list: [
                                {
                                    title: 'Funding Source Name: Enter the name of the insurance company (e.g., "Blue Shield," "Molina," etc.).',
                                    boldTexts: ['Funding Source Name'],
                                },

                                {
                                    title: 'Email Address: Provide the email address for your contact person at the funding source or their general email address.',
                                    boldTexts: ['Email Address'],
                                },

                                {
                                    title: 'Phone Number: Enter the contact phone number.',
                                    boldTexts: ['Phone Number'],
                                },

                                {
                                    title: 'Type: Choose from the options:',
                                    boldTexts: ['Type'],
                                    list: [
                                        {
                                            title: 'Private Insurance',
                                            boldTexts: ['Private Insurance'],
                                        },

                                        {
                                            title: 'Public Insurance',
                                            boldTexts: ['Public Insurance'],
                                        },

                                        {
                                            title: 'School',
                                            boldTexts: ['School'],
                                        },
                                    ],
                                },

                                {
                                    title: 'Contact Person: Enter the name of the contact person at the funding source.',
                                    boldTexts: ['Contact Person'],
                                },

                                {
                                    title: 'Payor ID: Enter the Payor ID provided by the insurance company.',
                                    boldTexts: ['Payor ID'],
                                },

                                {
                                    title: 'Website: Enter the website of the funding source.',
                                    boldTexts: ['Website'],
                                },

                                {
                                    title: 'Street Address: Provide the address of the insurance company.',
                                    boldTexts: ['Street Address'],
                                },
                                {
                                    title: 'Unit/Suite #: Enter the suite or unit number if applicable.',
                                    boldTexts: ['Unit', '/', 'Suite', '#'],
                                },
                            ],
                        },

                        {
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.createFundingDesktop2,
                                },
                            ],
                        },
                    ],


                },

                {
                    subTitle: '4. Save the Funding Source',
                    circle: true,

                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the Add button at the bottom of the form.',
                                    boldTexts: ['Add', 'button'],
                                    circle: true,
                                },

                                {
                                    title: 'The new funding source will appear in the table.',
                                    boldTexts: ['funding'],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },
            ],

        },
        'ADDING_SERVICE_FUNDING': {
            pageTitle: 'Adding Services to a Funding Source',
            content: [
                {
                    subTitle: '1. Access the Services Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Select the funding source to which you want to add a service.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add a New Service',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Select the Services tab at the top of the page.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Click the blue + Add Service button at the top right of the page.',
                                    boldTexts: ['Add Service'],
                                    circle: true,
                                },

                                {
                                    title: 'Fill in and select the appropriate fields in the Add New Service modal:',
                                    boldTexts: ['Add New Service'],
                                    circle: true,

                                    list: [
                                        {
                                            title: 'Service: Choose the type of service',
                                            boldTexts: ['Service'],
                                        },

                                        {
                                            title: 'CPT Coder: Enter the CPT code',
                                            boldTexts: ['CPT Coder'],
                                        },

                                        {
                                            title: 'Type: Choose the service type.',
                                            boldTexts: ['Type'],
                                        },

                                        {
                                            title: 'Unit Size (Minutes): Enter how many minutes are in 1 unit for this service type (e.g., if 1 unit = 15 minutes, type “15”).',
                                            boldTexts: ['Unit Size', 'Minutes'],
                                        },

                                        {
                                            title: 'Min Unit and Max Unit: Enter the minimum and maximum number of units allowed in one appointment (e.g., Min = 1, Max = 4 with a unit size of 15 minutes means an appointment must be between 15 to 60 minutes).',
                                            boldTexts: ['Min Unit and Max Unit'],
                                        },

                                        {
                                            title: 'Require Signature: Toggle to enable if every appointment with this service type will require a signature to be rendered.',
                                            boldTexts: ['Require Signature'],
                                        },

                                        {
                                            title: 'Require Notes: Toggle to enable if every appointment with this service type will require a note to be rendered.',
                                            boldTexts: ['Require Notes'],
                                        },
                                    ],
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Save the Service',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the Add button at the bottom of the modal.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'The new service type will appear in the table.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                            ],
                        },


                    ],
                },

                {
                    subTitle: '4. Add Charge Rates to the Service',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Once the service is added, you will need to add charge rates to it.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addServFundDesktop,
                                },
                            ],
                        },

                    ],
                },
            ],

        },
        'ADDING_CHARGE_RATES': {
            pageTitle: 'Adding Charge Rates to a Funding Source',
            content: [
                {
                    subTitle: '1. Access the Services Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },

                                {
                                    title: 'Select the funding source to which you want to add a service.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Add Charge Rates to the Service',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Select the Services tab at the top of the page.',
                                    boldTexts: ['Services'],
                                    circle: true,
                                },

                                {
                                    title: 'Find the service for which you want to add charge rates in the table and click the blue Charge Rates button next to it..',
                                    boldTexts: ['blue Charge Rates'],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Fill Out the Charge Rates',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the [Service Name] Charge Rates modal, click on the blue + Add Charge Rate button at the top right.',
                                    boldTexts: ['Service Name', 'Add Charge Rate'],
                                    circle: true,
                                },

                                {
                                    title: 'Fill in and select the following fields:',
                                    boldTexts: [''],
                                    circle: true,
                                    list: [
                                        {
                                            title: 'Title: Enter the title of the charge rate (e.g., "BCBA," "BI," "CM," etc.).',
                                            boldTexts: ['Title'],
                                        },

                                        {
                                            title: 'Modifier Name: Enter the modifier name (e.g., "HM," "HO," "HT," etc.).',
                                            boldTexts: ['Modifier Name'],
                                        },

                                        {
                                            title: 'Billed Rate ($): Enter the rate you would like to bill the insurance.',
                                            boldTexts: ['Billed Rate', '($)'],
                                        },

                                        {
                                            title: 'Allowed Rate ($): Enter the rate you expect to be paid.',
                                            boldTexts: ['Allowed Rate'],
                                        },

                                        {
                                            title: 'Region: Specify the region (e.g., "Los Angeles").',
                                            boldTexts: ['Region'],
                                        },

                                        {
                                            title: 'Credentials: Choose the corresponding credentials from the list of credentials added under System > Credentials.',
                                            boldTexts: ['Credentials', 'System', 'Credentials'],
                                        },

                                    ],
                                },

                            ],
                        },


                    ],
                },

                {
                    subTitle: '4. Save the Charge Rate',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the Add button to save the charge rate. It will appear in the list for that service.',
                                    boldTexts: ['Add'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            videos: [
                                {
                                    desktop: Videos.addChargeRateDesktop,
                                },
                            ],
                        },

                    ],
                },
            ],

        },
        'ACTIVATING_INACTIVATING_FUNDING': {
            pageTitle: 'Activating or Inactivating a Funding Source',
            content: [
                {
                    subTitle: '1. Navigate to Role Management',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the left pane and click >Role Management',
                                    boldTexts: ['Role Management'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source you want to activate or inactivate from the list.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Activate or Inactivate the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'On the top right of the page, you will see a button labeled Active or Inactive, depending on the current state of the funding source.',
                                    boldTexts: ['Funding Source Name'],
                                },

                                {
                                    title: 'Click on this button and select the desired option to change the status of the funding source.',
                                    boldTexts: ['Email Address'],
                                },

                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.activeInactiveFundDesktop,
                                },
                            ],
                        },
                    ],


                },
            ],

        },
        'MERGING_APPOINTMENTS': {
            pageTitle: 'Merging Appointments',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source for which you would like to enable merging appointments.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Toggle Merge Appointments On/Off',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Navigate to the top right of the page and toggle the Merge Appts. option On or Off.',
                                    boldTexts: ['Merge Appts', 'On', 'Off'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Effect of Toggling On Merge Appointments',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Toggling On Merge Appts. will merge appointments on the claims for this funding source.',
                                    boldTexts: [''],
                                },
                            ],
                        },

                        {
                            circle: true,
                            notes: [
                                {
                                    text: 'Note: This will only affect appointments going forward and not the appointments already submitted in claims.',
                                    boldTexts: ['Note'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.mergingAppointmentsDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'DOWNLOADING_CSV_FUNDING': {
            pageTitle: 'Downloading a CSV of the Funding Sources',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Locate the Download CSV Button',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Once in the Funding Source section, navigate to the top right of the page.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Download the CSV',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Download CSV button.',
                                    boldTexts: [''],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Save the CSV File',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'The CSV file containing the funding source details will automatically download to your device\'s default download location.',
                                    boldTexts: [''],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.downloadingCsvDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_FUNDING': {
            pageTitle: 'Editing Funding Source',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Navigate to the Services Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Services tab at the top of the page.',
                                    boldTexts: ['Services'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Open the Edit Service Modal',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the blue pencil icon next to the service you want to edit.',
                                    boldTexts: ['blue pencil'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingFundingDesktop1,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Edit Service Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the Edit Service modal, make the necessary changes to the service fields. You can also toggle the Service Status on or off if needed.',
                                    boldTexts: ['Edit Service', 'Service Status'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save at the bottom of the modal to apply the changes.',
                                    boldTexts: ['Save'],
                                },
                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingFundingDesktop2,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        'INACTIVATING_ACTIVE_FUNDING': {
            pageTitle: 'Inactivating an Active Funding Source Service',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Navigate to the Services Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Services tab at the top of the page.',
                                    boldTexts: ['Services'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. View Active Services',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Ensure the Active tab is selected to view active funding source services.',
                                    boldTexts: ['Active'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Open the Edit Service Modal',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the blue pencil icon next to the active service you want to inactivate.',
                                    boldTexts: ['blue pencil'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Toggle Service Status Off',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the Edit Service modal, toggle the Service Status to Off at the bottom. This action will move the funding source service to the Inactive tab.',
                                    boldTexts: ['Edit', 'Inactive'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save at the bottom of the modal to apply the changes.',
                                    boldTexts: ['Save'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            videos: [
                                {
                                    desktop: Videos.inactiveActiveFundDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'ACTIVATING_INACTIVE_FUNDING': {
            pageTitle: 'Activating an Inactive Funding Source Service',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Desired Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the funding source for which you want to activate a service.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Ensure the Inactive Toggle is Selected',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Make sure the Inactive tab is selected to view the currently inactive services for the funding source.',
                                    boldTexts: ['Inactive'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Edit the Service',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Find the inactive service you want to activate, click the blue pencil icon next to it to open the Edit Service modal.',
                                    boldTexts: ['blue pencil icon'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Toggle Service Status On',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the Edit Service modal, toggle the Service Status to On at the bottom.',
                                    boldTexts: ['Service Status', 'On'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save at the bottom of the modal to apply the changes.',
                                    boldTexts: ['Save'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            videos: [
                                {
                                    desktop: Videos.activeInactiveFundingDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],

        },
        'EDITING_FUNDING_SERVICE': {
            pageTitle: 'Editing a Funding Source Service',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Navigate to the Services Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Services tab at the top of the page.',
                                    boldTexts: ['Services'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingFundServiceDesktop1,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '3. Open the Edit Service Modal',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the blue pencil icon next to the service you want to edit.',
                                    boldTexts: ['blue pencil icon'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Edit Service Details',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the Edit Service modal, make the necessary changes to the service fields. You can also toggle the Service Status on or off if needed.',
                                    boldTexts: ['Edit Service'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingFundServiceDesktop2,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save at the bottom of the modal to apply the changes.',
                                    boldTexts: ['Save'],
                                },
                            ],
                        },
                    ],
                },

            ],
        },
        'ADDING_NOTES_FUNDING': {
            pageTitle: 'Adding Notes to a Funding Source',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source to which you want to add a note.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Notes Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Notes tab at the top of the page.',
                                    boldTexts: ['Notes'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Add a New Note',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the + Add Note button on the top right of the page.',
                                    boldTexts: ['Add Note'],
                                    circle: true,
                                },

                                {
                                    title: 'In the Add Note modal, fill in the following fields:',
                                    boldTexts: ['Add Note'],
                                    circle: true,

                                    list: [
                                        {
                                            title: 'Subject: Enter the subject of the note.',
                                            boldTexts: ['Subject'],
                                        },

                                        {
                                            title: 'Body: Enter the body content of the note.',
                                            boldTexts: ['Body'],
                                        },
                                    ],
                                },
                            ],
                        },


                    ],
                },

                {
                    subTitle: '5. Save the Note',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save on the bottom right of the modal.',
                                    boldTexts: ['Save'],
                                },

                                {
                                    title: 'The new note will appear in the notes table.',
                                    boldTexts: [''],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.addNotFundDesktop,
                                },
                            ],
                        },
                    ],
                },

            ],
        },
        'EDITING_FUNDING_NOTES': {
            pageTitle: 'Editing Funding Source Notes',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source whose notes you want to edit.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Notes Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Notes tab at the top of the page.',
                                    boldTexts: ['Notes'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Edit the Note',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Find the note you want to edit in the notes table and click on the blue pencil icon to the right of it.',
                                    boldTexts: ['blue pencil icon'],
                                    circle: true,
                                },
                            ],
                        },


                    ],
                },

                {
                    subTitle: '5. Update the Fields',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Edit the Subject and/or Body fields as needed.',
                                    boldTexts: ['Subject', 'Body'],
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Save to apply the changes. The updated note will reflect in the notes table.',
                                    boldTexts: ['Save'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editingFundNotesDesktop,
                                },
                            ],
                        },
                    ],
                },

            ],
        },
        'DELETING_FUNDING_NOTES': {
            pageTitle: 'Deleting Funding Source Notes',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source whose note you want to delete.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Notes Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Notes tab at the top of the page.',
                                    boldTexts: ['Notes'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Delete the Note',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Locate the note you want to delete in the notes table.',
                                    boldTexts: ['blue pencil icon'],
                                    circle: true,
                                },

                                {
                                    title: 'Click on the X button next to the note.',
                                    boldTexts: ['X button'],
                                    circle: true,
                                },
                            ],
                        },


                    ],
                },

                {
                    subTitle: '5. Confirm Deletion',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click Delete on the confirmation modal. The note will be removed from the notes table.',
                                    boldTexts: ['Delete'],
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.deleteFundNotesDesktop,
                                },
                            ],
                        },
                    ],
                },

            ],
        },
        'VIEWING_FUNDING_CHANGES_HISTORY': {
            pageTitle: 'Viewing Funding Source Changes History',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source for which you would like to see the change history',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the History Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the History tab at the top of the page.',
                                    boldTexts: ['History'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. View Change History',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Review the history of changes made to the funding source, which may include edits to information, status changes, and other relevant modifications.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.viewFundChangeHistoryDesktop,
                                },
                            ],
                        },

                    ],
                },
            ],
        },
        'UPLOADING_FUNDING_FILES': {
            pageTitle: 'Uploading Funding Source Files',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source for which you would like to upload files.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Files Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Files tab at the top of the page.',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Upload Files',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click the Upload button to add relevant documents or files associated with the funding source.',
                                    boldTexts: ['Upload'],
                                    circle: true,
                                },

                                {
                                    title: 'Once the button is clicked, the Upload Document modal will appear.',
                                    boldTexts: ['Upload Document'],
                                    circle: true,
                                },
                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.uploadFundFilesDesktop1,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '5. Fill in the Fields and Upload Files',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Fill in the required fields in the Upload Document modal.',
                                    boldTexts: ['Upload Document'],
                                    circle: true,
                                },

                                {
                                    title: 'Click to upload or drag and drop the file into the drag and drop box.',
                                    boldTexts: ['drag and drop'],
                                    circle: true,
                                },
                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.uploadFundFilesDesktop2,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '6. Review Uploaded Files in the Modal',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Once the file is dragged or selected, it will appear at the bottom of the modal.',
                                    boldTexts: [''],
                                    circle: true,
                                },

                                {
                                    title: 'Repeat this process for each additional file you would like to upload.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '7. Save the Uploaded Files',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'After adding all the files, click the Add button on the bottom right of the modal.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '8. Save the Uploaded Files',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'You will see the uploaded files listed in the table under the Files tab.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        'EDITING_FUNDING_FILES': {
            pageTitle: 'Editing Funding Source Files',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source for which you want to edit a file.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Files Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Files tab at the top of the page to view all files associated with the funding source.',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Locate the File to Edit',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the files table, find the file you wish to edit.',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Edit the File',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the blue pencil icon on the right side of the file\'s line in the table',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. Make Necessary Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'The Edit File modal will appear. Make the necessary changes to the fields or replace the file as needed.',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '7. Save Changes',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'After making the desired edits, click Save at the bottom of the modal.',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '8. View Updated File Information',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'The updated details of the file will be reflected in the files table.',
                                    boldTexts: ['Upload'],
                                    circle: true,
                                },
                            ],
                        },
                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.editFundFilesDesktop,
                                },
                            ],
                        },

                    ],
                },
            ],
        },
        'DELETING_FUNDING_FILES': {
            pageTitle: 'Deleting Funding Source Files',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source from which you want to delete files.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Files Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Files tab at the top of the page to view all files associated with the funding source.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Locate the File to Edit',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the files table, find the file you wish to delete.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Delete the File',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the trashcan icon on the right side of the file\'s line in the table.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. Confirm Deletion',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'A confirmation modal will appear. Click Delete to confirm the removal of the file.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '7. View Updated File List',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'The file will be removed from the table, and the updated list of files will be displayed.',
                                    boldTexts: ['Files'],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.deletingFundFilesDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        'DOWNLOADING_FUNDING_FILES': {
            pageTitle: 'Downloading Funding Source Files',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source from which you want to download a file.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Files Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Files tab at the top of the page to view all files associated with the funding source.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Locate the File to Download',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the files table, find the file you wish to download.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. Download the File',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the cloud icon with a small arrow pointing down on the right side of the file\'s line in the table.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. Save the File',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'The file will be downloaded to your device. Choose the location where you want to save the file.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.downFundFilesDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        'VIEWING_FUNDING_FILES': {
            pageTitle: 'Viewing the Funding Source Files',
            content: [
                {
                    subTitle: '1. Access the Funding Source Section',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Go to the >Funding Source section in the left pane.',
                                    boldTexts: ['Funding Source'],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '2. Select the Funding Source',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Choose the funding source for which you want to view a file.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                    ],
                },

                {
                    subTitle: '3. Navigate to the Files Tab',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the Files tab at the top of the page to see all files associated with the funding source.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '4. Locate the File to View',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'In the files table, find the file you wish to view',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '5. View the File',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'Click on the eye icon on the right side of the file\'s line in the table',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },
                    ],
                },

                {
                    subTitle: '6. File Preview',
                    circle: true,
                    list: [
                        {
                            list: [
                                {
                                    title: 'A preview of the file will open, allowing you to view its contents without downloading it.',
                                    boldTexts: [''],
                                    circle: true,
                                },
                            ],
                        },

                        {
                            circle: true,
                            images: [
                                {
                                    type: 'img',
                                    desktop: Images.viewFundFilesDesktop,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
};










