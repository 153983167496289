import {
   APPEND_SIGNATURE_TO_APPMT, APPOINTMENT_FROM_TEMPLATE,
   APPOINTMENT_REPEAT, CANCEL_APPT, CHANGE_APPOINTMENT_LOCK_UNLOCK, CONNECT_RECONNECT_TEMPLATE,
   CREATE_APPOINTMENT, DELETE_ALL_APPT,
   DELETE_APPOINTMENT, DELETE_APPT_DOCUMENT,
   EDIT_APPOINTMENT, EDIT_APPOINTMENT_POSITION, EDIT_APPT_DOCUMENT,
   GET_APPOINTMENT,
   GET_APPOINTMENT_BY_ID, GET_APPOINTMENT_INFORMATION, GET_APPT_DOCUMENT, GET_APPT_STAFFS,
   GET_CLIENT_STAFFS, MANAGE_NOTES, REMOVE_APPT_FROM_BILL, REMOVE_CLIENT_STAFFS, SAVE_CURRENT_LOCATION,
   SET_APPOINTMENT_STATUS, UN_RENDER_APPT, UPLOAD_APPT_DOCUMENT,
} from './appointment.type';

/** Create, Edit Appointment */

export const createAppointment = (body) => {
   return {
      type: CREATE_APPOINTMENT,
      payload: { body },
   };
};

export const editAppointment = (body, id) => {
   return {
      type: EDIT_APPOINTMENT,
      payload: { body, id },
   };
};

export const editAppointmentPosition = (body, id) => {
   return {
      type: EDIT_APPOINTMENT_POSITION,
      payload: { body, id },
   };
};

export const changeApptLockUnlock = (list, type, params, apptId) => {
   return {
      type: CHANGE_APPOINTMENT_LOCK_UNLOCK,
      payload: { list, type, params, apptId },
   };
};

/** end */

/** Get Appointment */

export const getAppointment = (params, load) => {
   return {
      type: GET_APPOINTMENT,
      payload: {params, load},
   };
};

export const getAppointmentById = (id) => {
   return {
      type: GET_APPOINTMENT_BY_ID,
      payload: { id },
   };
};
export const getAppointmentInfo = (id) => {
   return {
      type: GET_APPOINTMENT_INFORMATION,
      payload: { id },
   };
};

/** end */

/** Delete Appointment */

export const deleteAppointment = (id) => {
   return {
      type: DELETE_APPOINTMENT,
      payload: { id },
   };
};

/** end */


/** Appointment Status */

export const setAppointmentStatus = (id, statusName, reason) => {
   return {
      type: SET_APPOINTMENT_STATUS,
      payload: { id, statusName, reason },
   };
};

export const removeApptFromBill = (id, reason) => {
   return {
      type: REMOVE_APPT_FROM_BILL,
      payload: { id, reason },
   };
};

export const unRenderAppt = (id) => {
   return {
      type: UN_RENDER_APPT,
      payload: { id },
   };
};

/** end */

/** Appointment Repeat */

export const appointmentRepeat = (body) => {
   return {
      type: APPOINTMENT_REPEAT,
      payload: { body },
   };
};
export const appointmentFromTemplate = (body) => {
   return {
      type: APPOINTMENT_FROM_TEMPLATE,
      payload: { body },
   };
};
/** end */

export const appendSignatureToAppmt = (id, signature) => {
   return {
      type: APPEND_SIGNATURE_TO_APPMT,
      payload: { id, signature },
   };
};


export const saveCurrentLocation = ( pos ) => {
   return {
      type: SAVE_CURRENT_LOCATION,
      payload: pos,
   };
};

export const getStaffClients = ( clientId, serviceId, authServiceId ) => {
   return {
      type: GET_CLIENT_STAFFS,
      payload: {clientId, serviceId, authServiceId },
   };
};

export const removeStaffClients = ( c) => {
   return {
      type: REMOVE_CLIENT_STAFFS,
   };
};

export const manageNotes = (id, params ) => {
   return{
      type:MANAGE_NOTES,
      payload: {id, params },
   }
}

export const connectReconnect = (id, params ) => {
   return{
      type:CONNECT_RECONNECT_TEMPLATE,
      payload: {id, params },
   }
}
export const cancelAppt = (id ) => {
   return{
      type:CANCEL_APPT,
      payload: {id },
   }
}
export const deleteAllAppt = (id ) => {
   return{
      type:DELETE_ALL_APPT,
      payload: {id },
   }
}


export const getApptStaffs = ( ) => {
   return{
      type:GET_APPT_STAFFS,
   }
}

/** Appt Documents */

export const uploadApptDocument = (file, id) => {
   return {
      type: UPLOAD_APPT_DOCUMENT,
      payload: {file, id}
   }
}

export const editApptDocument = (id, dockId, file) => {
   return {
      type: EDIT_APPT_DOCUMENT,
      payload: {id, dockId, file}
   }
}

export const getApptDocument = (id) => {
   return {
      type: GET_APPT_DOCUMENT,
      payload: {id}
   }
}

export const deleteApptDocument = (apptId, id) => {
   return {
      type: DELETE_APPT_DOCUMENT,
      payload: {apptId, id}
   }
}

/** End */
