import { BREAK, DRIVE, PAID, scheduleStatuses, SERVICE, SICK_TIME, STAFF, UNPAID } from '../../constants';
import { Images, formatAMPMNeW } from 'utils';
import React from 'react';
import { scheduleModalsStyle } from './styles';
import { TextRow } from 'components';

export function getDynamicContent(content, info, type) {
    const staticText = content === 'TITLE' ? '' :
        content === 'SUBTITLE' ? ', please fulfill the below fields.' : '';

    const dynamicText =
        content === 'TITLE' ? info ? 'Edit ' : 'Add' :
            content === 'SUBTITLE' ? info ? 'To edit the' : 'To add a' : '';

    switch (type) {
        case PAID:
            return `${dynamicText} Paid Time Off${staticText}`;
        case STAFF:
            return `${dynamicText} Staff Appointment${staticText}`;
        case BREAK:
            return `${dynamicText} Break${staticText}`;
        case DRIVE:
            return `${dynamicText} Drive${staticText}`;
        case SERVICE:
            return `${dynamicText} Service Appointment${staticText}`;
        case UNPAID:
            return `${dynamicText} Unpaid Time Off${staticText}`;
        case SICK_TIME:
            return `${dynamicText} Sick Time ${staticText}`;
        default:
            return '';
    }
}

export function getDynamicTitle(content, info, type) {

    const dynamicText =
        content === 'TITLE' ? info ? 'Edit ' : 'Add' :
            content === 'SUBTITLE' ? info ? 'To edit the' : 'To add a' : '';

    switch (type) {
        case PAID:
            return `${dynamicText} Paid Time Off Appointment`;
        case STAFF:
            return `${dynamicText} Staff Appointment`;
        case BREAK:
            return `${dynamicText} Break Appointment`;
        case DRIVE:
            return `${dynamicText} Drive Appointment`;
        case SERVICE:
            return `${dynamicText} Service Appointment`;
        case UNPAID:
            return `${dynamicText} Unpaid Time Off Appointment`;
        case SICK_TIME:
            return `${dynamicText} Sick Time Appointment`;
        default:
            return '';
    }
}


export const renderTimes = (item) => {
    return item?.slice(11, 16);
};

export function timeToMinutes(time) {
    const [hours, minutes] = time.trim().split(':').map(Number);
    return hours * 60 + minutes;
}

export const renderUnitWarnings = (currentService, inputs) => {
    // if (inputs?.type === SERVICE) {
    const unitSize = currentService?.service?.unitSize;

    const startMinutes = inputs?.startTime && timeToMinutes(inputs?.startTime);
    const endMinutes = inputs?.endTime && timeToMinutes(inputs?.endTime);
    const maxUnit = currentService?.service?.max;
    const minUnit = currentService?.service?.min;
    const currentApptUnites = (endMinutes - startMinutes) / unitSize;
    return {
        uniteSizeCorrect: inputs?.startTime && inputs?.endTime && inputs?.authService ? (endMinutes - startMinutes) % unitSize === 0 : true,
        unitSizeCountIsCorrect:
            inputs?.startTime && inputs?.endTime && inputs?.authService ?
                currentApptUnites <= maxUnit && currentApptUnites >= minUnit
                : true,
        currentApptUnites: currentApptUnites,
        minUnit: minUnit,
        maxUnit: maxUnit,
        unitSize: unitSize,
    };
    // }
};


export const RenderWarnings = ({
                                   uniteSizeCorrect,
                                   unitSizeCountIsCorrect,
                                   unitSize,
                                   minUnit,
                                   maxUnit,
                                   currentApptUnites,
                                   modalDate,
                                   warnAuthorization,
                                   checkAvailableUnits,
                                   availableUnits,
                               }) => {
    const classes = scheduleModalsStyle();

    return (
        <div className={classes.warningBox} >
            {!uniteSizeCorrect &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <div>
                        <p>Duration results in partial units since</p>
                        <p>{`1 unit = ${unitSize} minutes.`}</p>
                    </div>
                </div>
            }

            {!unitSizeCountIsCorrect &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <div>
                        <p>
                            Selected
                            <span style={{
                                color: '#DE350B',
                                fontWeight: 600,
                                margin: '0 4px',
                            }}>{currentApptUnites.toFixed(2)}</span>units
                            <span>{`
                           is outside the allowed range of  ${minUnit} - ${maxUnit} units, (15-60 mins) for this funding source. 1 unit = ${unitSize} mins.
                            `}</span>
                        </p>
                    </div>
                </div>
            }

            {checkAvailableUnits < 0 && (
                <div className={classes.warning}>
                    <img src={Images.warning} alt="warning" />
                    <p>
                        Authorized units exceeded, available units are
                        <span style={{ fontWeight: 600, marginLeft: 4 }}>{availableUnits}</span>
                    </p>
                </div>
            )}

            {modalDate?.status === scheduleStatuses?.COMPLETED &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <p>
                        Warning! Since this appointment is already rendered,
                        claims and processed timesheets will not be updated
                    </p>
                </div>
            }

            {warnAuthorization?.status === 'INACTIVE' &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <p>Authorization is inactive.</p>
                </div>
            }

        </div>
    );
};

export const isDateGreater = (date1, date2, createMultiple) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    if (date1 && date2 && !createMultiple) {
        return d1.getTime() > d2.getTime();
    } else {
        return true;
    }
};

export const apptOverlap = 'appointment overlapping';
export const staffOverlap = 'Staff overlap on appointment';


export const moreApptHead = [
    { name: '', title: 'Start/End Time', noSearch: true, custom: false },
    { name: '', title: 'Staff Member', width: '120px', noSearch: true, custom: false },
    { name: '', title: 'Client', icon: 'date', noSearch: true, custom: false },
    { name: '', title: 'Authorized Serivce', noSearch: true, custom: false },
];

export const moreApptBody = [
    { rowText: (item) => `${formatAMPMNeW(item?.originalStart)} - ${formatAMPMNeW(item?.originalEnd)}` },
    { rowText: (item) => <TextRow name={item?.staffName ? `${item?.staffName?.firstName} ${item?.staffName?.lastName ? item?.staffName?.lastName : ''}` : 'N/A'} />, },
    { rowText: (item) => <TextRow name={item?.client ?
            `${item?.client?.code ? `(${item?.client?.code})` : ''} ${item?.client?.firstName} ${item?.client?.lastName ? item?.client?.lastName : ''} `
            :
            'N/A'}/> },
    { rowText: (item) => <TextRow name={item?.serviceCptCode ? item?.serviceCptCode : 'N/A'} /> },

];