// top-bar-content
export const navLinks = [
   // { name: "About Us", path: "/about-us" },
   // { name: "Contact Us", path: "/contact-us" },
   // { name: "Help Center", path: "/help-center" },

   { name: "Home", path: "/" },
   { name: "Features", path: "/features" },
   // { name: "Solutions", path: "/solutions" },
   // { name: "Recources", path: "/recources" },
   { name: "About Us", path:  "/about-us"},
   { name: "Help Center", path: "/help-center" },

   { name: "Sign In", path: "/login" },
];
