import React, { useEffect, useState } from 'react';
import { Calendar, DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { Images, SaveParams } from 'utils';
import { Popper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';
import { ErrMessage } from '../messages';

export const DateCalendar = ({ outLabel, handleGetDates, values, error, type, noError, name, max }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let history = useHistory();
    const info = values ? values : history?.location?.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [state, setState] = useState();
    const valueFromRoute = info?.[name]

    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    useEffect(() => {
        if (valueFromRoute) {
            setState(moment(valueFromRoute).format());
        }
    }, [info]);
    const handleDateChange = (item) => {
        setState(moment(item).format());

        if (handleGetDates) {
            handleGetDates({target: { value: moment(item).format('YYYY-MM-DD'), name: name }});
        } else {
            const params = {
                ...info,
            };
            params[name] = moment(item).format('YYYY-MM-DD');
            SaveParams(history, params);
        }
        setAnchorEl(null);
    };

    const handleClear = () => {
        setState(null);

        if (handleGetDates) {
            handleGetDates({target: { value: '', name: name }});
        } else {
            const params = {
                ...info,
            };
            delete params[name];
            SaveParams(history, params);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div style={{ width: '100%' }}>
            {outLabel &&
                <p
                    style={type === 'modalInput' ? { color: error ? '#FE7070' : valueFromRoute ? '#347AF0' : '#51566D' } : {}}
                    className={type === 'modalInput' ? 'modal-date-selector-label' : 'date-selector-label'}>
                    {outLabel}
                </p>
            }
            <button
                style={type === 'modalInput' ? {} : {width:'170px', justifyContent:'space-between'}}
                className={type === 'modalInput' ? 'modal-date-range-selector' : 'date-range-selector'}
                aria-describedby={id}
                type="button"
                onClick={handleClick}
            >
                <p style={{ color: state ? '#51566D' : 'rgba(75, 92, 104, 0.50)' }}>
                    {state ? moment(state).format('MM/DD/YYYY') : 'MM/DD/YYYY'}
                </p>
                <div className="flex-align-center">
                    {state &&
                        <button className="remove-calendar-day" type={'button'} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClear();
                        }}>
                            <img src={Images.removeBlack} alt="remove" />
                        </button>
                    }
                    <img src={Images.calendarToday} alt="icon" />
                </div>
            </button>
            {!noError &&
                <ErrMessage text={error?.message} />
            }
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'modal-custom-date-selector-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 1400 }}
            >
                <Calendar
                    date={state}
                    onChange={handleDateChange}
                    locale={customLocale}
                    maxDate={max ? new Date(max) : null}
                />
                <button type={'button'} className="clear-bnt" onClick={handleClear}>Clear</button>
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};
