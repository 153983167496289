/** Founding Source */

export const CREATE_FUNDING_SOURCE = "CREATE_FUNDING_SOURCE";

export const EDIT_FUNDING_SOURCE = "EDIT_FUNDING_SOURCE";

export const GET_FUNDING_SOURCE = "GET_FUNDING_SOURCE";
export const GET_FUNDING_SOURCE_SUCCESS = "GET_FUNDING_SOURCE_SUCCESS";

export const GET_FUNDING_SOURCE_BY_ID = "GET_FUNDING_SOURCE_BY_ID";
export const GET_FUNDING_SOURCE_BY_ID_SUCCESS = "GET_FUNDING_SOURCE_BY_ID_SUCCESS";

export const CHANGE_FUNDING_SOURCE_STATUS = "CHANGE_FUNDING_SOURCE_STATUS";
export const DELETE_FUNDING_SOURCE = "DELETE_FUNDING_SOURCE";

export const GET_FUNDING_SOURCE_CLIENTS = "GET_FUNDING_SOURCE_CLIENTS";
export const GET_FUNDING_SOURCE_CLIENTS_SUCCESS = "GET_FUNDING_SOURCE_CLIENTS_SUCCESS";

/** End */

/** Founding Source Service */

export const GET_FUNDING_SOURCE_SERVICE_BY_ID = "GET_FUNDING_SOURCE_SERVICE_BY_ID";
export const GET_FUNDING_SOURCE_SERVICE_BY_ID_SUCCESS = "GET_FUNDING_SOURCE_SERVICE_BY_ID_SUCCESS";

export const CREATE_FUNDING_SOURCE_SERVICE_BY_ID = "CREATE_FUNDING_SOURCE_SERVICE_BY_ID";

export const EDIT_FUNDING_SOURCE_SERVICE = "EDIT_FUNDING_SOURCE_SERVICE";

export const CHANGE_FUNDING_SOURCE_SERVICE_STATUS = "CHANGE_FUNDING_SOURCE_SERVICE_STATUS";

/** End */

/** Modifiers */

export const GET_FUNDING_MODIFIERS = "GET_FUNDING_MODIFIERS";
export const GET_FUNDING_MODIFIERS_SUCCESS = "GET_FUNDING_MODIFIERS_SUCCESS";

export const CREATE_FUNDING_MODIFIER = "CREATE_FUNDING_MODIFIER";

export const EDIT_FUNDING_MODIFIER = "EDIT_FUNDING_MODIFIER";

export const CHANGE_FUNDING_MODIFIER_STATUS = "CHANGE_FUNDING_MODIFIER_STATUS";

/** End */

/** Set Status */

export const SET_STATUS = "SET_STATUS";

/** Fs Documents */
export const UPLOAD_FS_DOCUMENT = "UPLOAD_FS_DOCUMENT";
export const EDIT_FS_DOCUMENT = "EDIT_FS_DOCUMENT";
export const GET_FS_DOCUMENT = "GET_FS_DOCUMENT";
export const GET_FS_DOCUMENT_SUCCESS = "GET_FS_DOCUMENT_SUCCESS";
export const DELETE_FS_DOCUMENT = "DELETE_FS_DOCUMENT";

