import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CreateChancel, DateInput, SelectTypeAutocomplete } from 'components';
import { useModal } from 'utils';
import { CsvService } from 'store';
import { useHistory } from 'react-router-dom';

export const DownloadBillingInvoice = ({}) => {
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState({});
    const [load, setLoad] = useState(false);
    const { close } = useModal();
    const clientList = useSelector((state) => state.client.clientList);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if(info){
            const params = {};
            info?.client ? params.client = info.client : delete params.client;
            info?.status ? params.status = info.status : delete params.status;
            info?.firstDate ? params.startDate = info.firstDate :  delete params.startDate;
            info?.lastDate ? params.endDate = info.lastDate : delete params.endDate;
            setInputs(params)
        }

    }, [info]);
    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleExport = async () => {
        setLoad(true);
        let response = '';

        const params = {};
        if (inputs?.startDate) {
            params.startDate = moment.utc(inputs.startDate).format('YYYY-MM-DD');
        }
        if (inputs?.endDate) {
            params.endDate = moment.utc(inputs.endDate).format('YYYY-MM-DD');
        }
        if (inputs?.client) {
            params.client = inputs.client;
        }
        if (inputs?.status) {
            params.status = inputs.status;
        }

        try {
            response = await CsvService.getInvoicePaymentCsv(params);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice.csv`);
            document.body.appendChild(link);
            link.click();
            setLoad(false);
            close();
        } catch (e) {
            setLoad(false);
            close();
        }
    };

    return (
        <div className="download-payroll-csv-modal">
            <p className="download-modal-title">Download Invoice Data</p>
            <p className="download-modal-desc">
                Select filters for the invoice data you wish to download. The fields will be pre-filled based on your
                current filters.
            </p>
            <div className="download-modal-body">
                <SelectTypeAutocomplete
                    title={'Client'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.client}
                    list={clientList?.clients ? clientList?.clients : []}
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    placeholder={'Select Client'}
                />
                <SelectTypeAutocomplete
                    title={'Status'}
                    name={'status'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.status}
                    list={[
                        { name: 'Open', id: 'OPEN' },
                        { name: 'Paid', id: 'PAID' },
                        { name: 'Closed', id: 'CLOSED' },
                    ]}
                    renderValue={(i) => i?.name}
                    placeholder={'Select Client'}
                />
                <div className="start-end-time-box">
                    <DateInput
                        name={'startDate'}
                        label={'Start Date'}
                        handleChange={handleChange}
                        value={inputs.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : null}
                        max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}
                    />
                    <DateInput
                        name={'endDate'}
                        label={'End Date'}
                        handleChange={handleChange}
                        value={inputs.endDate ? moment.utc(inputs.endDate).format('MM/DD/YYYY') : null}
                        min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}
                    />

                    {/*<ValidationInput*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    value={inputs?.startDate}*/}
                    {/*    type={'date'}*/}
                    {/*    label={'Start Date*'}*/}
                    {/*    name="startDate"*/}
                    {/*    typeError={*/}
                    {/*        error === 'startDate' ? ErrorText.field : ''*/}
                    {/*    }*/}
                    {/*    max={inputs.endDate ? moment.utc(inputs.endDate).format('YYYY-MM-DD') : ''}*/}
                    {/*/>*/}
                    {/*<ValidationInput*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    value={inputs?.endDate}*/}
                    {/*    type={'date'}*/}
                    {/*    label={'End Date*'}*/}
                    {/*    name="endDate"*/}
                    {/*    typeError={*/}
                    {/*        error === 'endDate' ? ErrorText.field : ''*/}
                    {/*    }*/}
                    {/*    min={inputs.startDate ? moment.utc(inputs.startDate).format('YYYY-MM-DD') : ''}*/}
                    {/*/>*/}
                </div>
            </div>

            <div>
                <CreateChancel
                    loader={load}
                    create={'Download CSV'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};