import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorText, PermissionList, RolePermission, SaveParams, useModal } from 'utils';
import {
    AddButton,
    CustomDateSelector,
    DateCalendar,
    DateInput,
    DownloadCsv,
    SelectTypeAutocomplete,
} from 'components';
import { clientActions } from 'store';
import { GenerateInvoice } from './generateInvoice';
import moment from 'moment/moment';

export const InvoiceFilters = ({}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pushInfo = history?.location?.state;
    const clientList = useSelector((state) => state.client.clientList);
    const { open } = useModal();

    useEffect(() => {
        dispatch(clientActions.getClients());
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...info });
    };

    return (
        <div className="table-invoice-filter-section">
            <div className="billing-invoices-filter-box">
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.client ? '' : 'All'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.client}
                    list={clientList?.clients ?
                        [{ id: 'All', firstName: 'All', lastName: '' }, ...clientList?.clients]
                        :
                        [{ id: 'All', firstName: 'All', lastName: '' }]
                    }
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    uiType={'tableFilter'}
                    outLabel={'Client'}
                    noError={true}
                />
                <CustomDateSelector outLabel={'DOS'} />
                <div>
                    <DateCalendar
                        name={'invoiceDate'}
                        noError={true}
                        outLabel={'Invoice Date'}
                    />
                </div>
            </div>

            <div className="billing-invoices-filter-box">
                <DownloadCsv type={'billingInvoice'} fromModal={true} />
                {/*<DownloadCsv type={'posting'} />*/}

                {RolePermission([PermissionList.INVOICE_CREATE?.code]) &&
                    <AddButton
                        text={'Generate Invoice'}
                        handleClick={() => open(<GenerateInvoice />)}
                    />
                }
            </div>
        </div>
    );
};