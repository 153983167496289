import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
    FundingSource,
    FundingSourceSingle,
    Management,
    Staff,
    SingleStaff,
    Client,
    ClientSingle,
    System,
    Schedule,
    PendingClaims,
    ClaimDetails,
    Invoices,
    InvoiceDetails,
    ClaimPayments,
    Report,
    PassivePayroll,
    ActivePayroll,
    SubmittedClaims,
    TimeSheetDetailPage,
} from 'pages';
import { PermissionList, RolePermission } from '../utils';

export const Router = () => {
    return (
        <Switch>
            <Route path="/" exact component={Schedule} />

            {RolePermission([PermissionList.ROLES_READ.code]) &&
                <Route path="/management" component={Management} />
            }
            {RolePermission([PermissionList.FS_LIST_READ?.code]) &&
                <Route path="/fundingSource" exact component={FundingSource} />
            }
            {RolePermission([PermissionList.FS_READ?.code]) &&
                <Route path="/fundingSource/:id" exact component={FundingSourceSingle} />
            }
            {RolePermission([PermissionList.STAFF_LIST_READ.code]) &&
                <Route path="/staff" exact component={Staff} />
            }
            {RolePermission([PermissionList.STAFF_READ.code]) &&
                <Route path="/staff/:id" exact component={SingleStaff} />
            }
            {RolePermission([PermissionList.CLIENT_LIST_READ?.code, PermissionList.CONNECTED_CLIENT_READ?.code]) &&
                <Route path="/client" exact component={Client} />
            }
            {RolePermission([PermissionList.CLIENT_READ?.code]) &&
                <Route path="/client/:id" exact component={ClientSingle} />
            }
            {RolePermission([
                    PermissionList.SYSTEM_SERVICE_TYPES?.code,
                    PermissionList.SYSTEM_PAYCODE_TYPES?.code,
                    PermissionList.SYSTEM_CREDENTIALS?.code,
                    PermissionList.SYSTEM_DEPARTMENTS?.code,
                    PermissionList.SYSTEM_JOB_TITLES?.code,
                    PermissionList.SYSTEM_PLACE_OF_SERVICE?.code,
                    PermissionList.SYSTEM_MILEAGE?.code,
                    PermissionList.SYSTEM_OVERTIME?.code,
                ]) &&
                <Route path="/system" component={System} />
            }

            <Route path="/schedule" exact component={Schedule} />

            {RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                <Route path="/pendingClaims" exact component={PendingClaims} />
            }
            {RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                <Route path="/billedClaims" exact component={SubmittedClaims} />
            }
            {RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                <Route path="/claim/:id" exact component={ClaimDetails} />
            }
            {RolePermission([PermissionList.CLAIMS_READ?.code]) &&
                <Route path="/billedClaim/:id" exact component={ClaimDetails} />
            }
            {/*{RolePermission([PermissionList.CLAIMS_CREATE?.code, PermissionList.CLAIMS_UPDATE?.code]) &&*/}
            {/*    <Route path="/generateClaim" exact component={GenerateClaim} />*/}
            {/*}*/}
            {RolePermission([PermissionList.INVOICE_READ?.code]) &&
                <Route path="/invoices" exact component={Invoices} />
            }
            {RolePermission([PermissionList.INVOICE_READ?.code]) &&
                <Route path="/invoice/:id" exact component={InvoiceDetails} />
            }

            {/*{RolePermission([PermissionList.INVOICE_CREATE?.code,]) &&*/}
            {/*    <Route path="/generateInvoice" exact component={GenerateInvoice} />*/}
            {/*}*/}

            {RolePermission([PermissionList.CLAIM_PMT_READ?.code]) &&
                <Route path="/claimPayments" exact component={ClaimPayments} />
            }

            {/*{RolePermission([PermissionList.INVOICE_PMT_READ?.code]) &&*/}
            {/*    <Route path="/invoicePayments" exact component={InvoicePayments} />*/}
            {/*}*/}

            {RolePermission([PermissionList.REPORT_MANAGE?.code]) &&
                <Route path="/report" exact component={Report} />
            }

            {RolePermission([PermissionList.TIMESHEET_READ.code]) &&
                <Route path={'/processedPayroll'} exact component={PassivePayroll} />
            }
            {RolePermission([PermissionList.TIMESHEET_READ.code]) &&
                <Route path={'/activePayroll'} exact component={ActivePayroll} />
            }

            {RolePermission([PermissionList.TIMESHEET_READ.code]) &&
                <Route path={'/timesheet/:id'} exact component={TimeSheetDetailPage} />
            }
            {RolePermission([PermissionList.TIMESHEET_READ.code]) &&
                <Route path={'/processed/:id'} exact component={TimeSheetDetailPage} />
            }

            <Redirect to={'/'} />
        </Switch>
    );
};