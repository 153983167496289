import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Address, CreateChancel, Input, MaskInputCustom } from 'components';
import { EmailValidator, FindSuccess, FindLoad, ErrorText, FindErrorItem, OnlyNumber, useModal } from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const CreateStaff = ({ afterSuccess, staffGeneral }) => {
    const [enteredAddress, setEnteredAddress] = useState();
    const [errorAddress, setErrorAddress] = useState(false);
    const ACTION_TYPE = !!staffGeneral ? 'EDIT_ADMIN_BY_ID' : 'CREATE_ADMIN';
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const { close } = useModal();
    const { handleSubmit, control, clearErrors, setError, reset, } = useForm();

    useEffect(() => {
        if (staffGeneral) {
            const result = {
                ...staffGeneral,
            };
            reset(result);
        }
    }, [staffGeneral]);

    useEffect(() => {
        if (!!success.length) {
            close();
            afterSuccess && afterSuccess();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        if (backError?.error?.length) {
            if (backError?.error === 'User already exists in another organization. Please use a different email address.' ) {
                setError('email', {
                    message: 'User already exists in another organization. Please use a different email address.',
                });
            }
            if (backError?.error === 'User already exists. Please use a different email address.') {
                setError('email', {
                    message: 'User already exists. Please use a different email address.',
                });
            }
            if (backError?.error?.[0] === 'phone must be a valid phone number') {
                setError('phone', {
                    message: 'Phone number must be a valid phone number',
                });
            }
            if (backError?.error?.[0] === 'secondaryPhone must be a valid phone number') {
                setError('secondaryPhone', {
                    message: 'Phone number must be a valid phone number',
                });
            }
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const handleAddressChange = (selectedAddress) => {
        setEnteredAddress(selectedAddress);
        setErrorAddress(false);
    };

    function handleCreate(data) {

        const sanedData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data?.phone,
            address: enteredAddress?.address ? enteredAddress : null,
        };

        if (data?.middleName) sanedData.middleName = data.middleName;
        if (data?.secondaryPhone) sanedData.secondaryPhone = data.secondaryPhone;
        if (data?.secondaryEmail) sanedData.secondaryEmail = data.secondaryEmail;

        if (enteredAddress?.address) {
            if (!!staffGeneral) {
                dispatch(adminActions.editAdminById(sanedData, staffGeneral.id));
            } else {
                dispatch(adminActions.createAdmin(sanedData));
            }
        } else {
            setErrorAddress(true);
        }
    }

    return (
        <form onSubmit={handleSubmit(handleCreate)} style={{ width: 470 }}>
            <p className="modal-header-title"> {staffGeneral ? 'Edit General Info' : 'Add General Info'}</p>
            <div className="modal-body-wrapper">
                <Input
                    name="firstName"
                    label="First Name*"
                    variant="outlined"
                    control={control}
                    maxLength={20}
                    rules={{
                        maxLength: {
                            value: 20,
                            message: 'Max length is 20 digits.',
                        },
                        required: 'First name is required.',
                    }}
                />
                <Input
                    name="middleName"
                    label="Middle Name"
                    variant="outlined"
                    control={control}
                    maxLength={20}
                    rules={{
                        maxLength: {
                            value: 20,
                            message: 'Max length is 20 digits.',
                        },
                    }}
                />
                <Input
                    name="lastName"
                    label="Last Name*"
                    variant="outlined"
                    control={control}
                    maxLength={20}
                    rules={{
                        maxLength: {
                            value: 20,
                            message: 'Max length is 20 digits.',
                        },
                        required: 'Last name is required.',
                    }}
                />
                <Input
                    name="email"
                    type="email"
                    label="Primary Email*"
                    variant="outlined"
                    control={control}
                    rules={{
                        required: 'Primary email address is required.',
                        pattern: {
                            value: EmailValidator,
                            message: 'Valid email address is required (e.g., name@example.com).',
                        },
                    }}
                />
                <Input
                    name="secondaryEmail"
                    type="email"
                    label="Secondary Email"
                    variant="outlined"
                    control={control}
                    rules={{
                        pattern: {
                            value: EmailValidator,
                            message: 'Valid email address is required (e.g., name@example.com).',
                        },
                    }}
                />
                {/*<ValidationInput*/}
                {/*    validator={EmailValidator}*/}
                {/*    variant={'outlined'}*/}
                {/*    name={'secondaryEmail'}*/}
                {/*    type={'email'}*/}
                {/*    label={'Secondary Email'}*/}
                {/*    typeError={error === 'secondaryEmail' ? ErrorText.emailValid : ''}*/}
                {/*    value={inputs?.secondaryEmail}*/}
                {/*    onChange={handleChange}*/}
                {/*/>*/}
                <MaskInputCustom
                    name="phone"
                    label="Primary Phone Number*"
                    variant="outlined"
                    control={control}
                    rules={{
                        required: 'Primary Phone Number is required.',
                        pattern: {
                            value: OnlyNumber,
                            message: 'Please enter a valid 10 digit number.',
                        },
                        minLength: {
                            value: 10,
                            message: 'Please enter a valid 10 digit number.',
                        },
                    }}
                />
                <MaskInputCustom
                    name="secondaryPhone"
                    label="Secondary Phone Number"
                    variant="outlined"
                    control={control}
                    rules={{
                        pattern: {
                            value: OnlyNumber,
                            message: 'Please enter a valid 10 digit number.',
                        },
                        minLength: {
                            value: 10,
                            message: 'Please enter a valid 10 digit number.',
                        },
                    }}
                />
                <Address
                    oneInput={true}
                    placeholder={'Street Address*'}
                    setAddress={handleAddressChange}
                    errMessage={errorAddress ? ErrorText.requiredAddress : ''}
                    defaultValue={staffGeneral?.address}
                />
            </div>
            <CreateChancel
                type="submit"
                loader={!!loader.length}
                buttonWidth="100%"
                create={!!staffGeneral ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onClose={close}
            />
        </form>
    );
};
